define("lh-public-olo/services/analytics", ["exports", "@ember/service", "lh-public-olo/utils/currency-converter", "lh-public-olo/config/environment"], function (_exports, _service, _currencyConverter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    ticket: (0, _service.inject)(),
    featureFlag: (0, _service.inject)(),
    metrics: (0, _service.inject)(),
    initAnalytics: function initAnalytics(metaPixelId) {
      if (metaPixelId && this.featureFlag.isEnabled('sto-meta-pixel')) {
        /**
         * The activateAdapters() method replaces all the existing adapters internally, 
         * which disables the adapters that were originally configured in ENV.metricsAdapters. 
         * To ensure that existing adapters remain active, we need to pass those adapters 
         * again while adding a new adapter dynamically.
         */
        try {
          var adapters = _environment.default.metricsAdapters;
          adapters.push({
            name: 'FacebookPixel',
            environments: ['all'],
            config: {
              id: metaPixelId
            }
          });
          this.metrics.activateAdapters(adapters);
        } catch (e) {
          console.error('Failed to initialize metrics: ', e);
        }
      }
    },
    trackItem: function trackItem(cartItem) {
      if (this.featureFlag.isEnabled('sto-meta-pixel')) {
        try {
          this.metrics.trackEvent('FacebookPixel', {
            event: 'AddToCart',
            // eslint-disable-next-line camelcase
            content_name: cartItem.item.get('name'),
            value: (0, _currencyConverter.formatCentsToDollars)(cartItem.subtotal)
          });
        } catch (e) {
          console.error('Failed to track addToCart event: ', e);
        }
      }
    },
    trackPurchase: function trackPurchase() {
      if (this.featureFlag.isEnabled('sto-meta-pixel')) {
        try {
          var cartItemList = this.get('ticket.items').map(function (item) {
            return {
              id: item.get('id'),
              quantity: item.quantity,
              // eslint-disable-next-line camelcase
              item_name: item.item.get('name'),
              // eslint-disable-next-line camelcase
              item_price: (0, _currencyConverter.formatCentsToDollars)(item.item.get('price'))
            };
          });
          this.metrics.trackEvent('FacebookPixel', {
            event: 'Purchase',
            value: (0, _currencyConverter.formatCentsToDollars)(this.get('ticket.totalWithTip')),
            currency: 'USD',
            contents: cartItemList,
            // eslint-disable-next-line camelcase
            content_type: 'product',
            // eslint-disable-next-line camelcase
            content_name: 'SkyTab Online'
          });
        } catch (e) {
          console.error('Failed to track purchase event: ', e);
        }
      }
    }
  });

  _exports.default = _default;
});