define("lh-public-olo/components/ui/custom-ui-checkbox", ["exports", "semantic-ui-ember/components/ui-checkbox"], function (_exports, _uiCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _uiCheckbox.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var inputElement = this.element.querySelector('input[type="checkbox"]');

      if (inputElement && this.get('aria-label')) {
        inputElement.setAttribute('aria-label', this.get('aria-label'));
      }
    }
  });

  _exports.default = _default;
});