define("lh-public-olo/serializers/loyalty/program", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    primaryKey: 'guid',
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'program') {
        return this._super('loyalty/program');
      }

      return this._super(payloadKey);
    }
  });

  _exports.default = _default;
});