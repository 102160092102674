define("lh-public-olo/templates/components/account/entry-view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/yIrwQe4",
    "block": "[[[6,[39,0],null,[[\"close\"],[[28,[37,1],[[30,0],\"close\"],null]]],[[\"default\"],[[[[41,[28,[37,3],[[33,4],\"RESET-PASSWORD\"],null],[[[1,\"\\t\\t\"],[1,[28,[35,5],null,[[\"tab\",\"close\"],[[33,4],[28,[37,1],[[30,0],\"close\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\"],[10,0],[14,0,\"ui pointing secondary menu\"],[12],[1,\"\\n\\t\\t\\t\"],[10,0],[15,0,[29,[\"item \",[52,[28,[37,3],[[33,4],\"SIGN-IN\"],null],\"active\",\"\"]]]],[15,\"onClick\",[28,[37,1],[[30,0],[28,[37,6],[[33,4]],null],\"SIGN-IN\"],null]],[14,\"data-tab\",\"tab-sign-in\"],[12],[1,\"Sign In\"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[15,0,[29,[\"item \",[52,[28,[37,3],[[33,4],\"SIGN-UP\"],null],\"active\",\"\"]]]],[15,\"onClick\",[28,[37,1],[[30,0],[28,[37,6],[[33,4]],null],\"SIGN-UP\"],null]],[14,\"data-tab\",\"tab-sign-up\"],[12],[1,\"Sign Up\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\"],[41,[28,[37,3],[[33,4],\"SIGN-IN\"],null],[[[1,\"\\t\\t\\t\"],[10,0],[15,0,[29,[\"ui tab \",[52,[28,[37,3],[[33,4],\"SIGN-IN\"],null],\"active\",\"\"]]]],[14,\"data-tab\",\"tab-sign-in\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[1,[28,[35,7],null,[[\"tab\",\"route\",\"nextRoute\"],[[33,4],[33,8],[33,9]]]]],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,3],[[33,4],\"SIGN-UP\"],null],[[[1,\"\\t\\t\\t\"],[10,0],[15,0,[29,[\"ui tab \",[52,[28,[37,3],[[33,4],\"SIGN-UP\"],null],\"active\",\"\"]]]],[14,\"data-tab\",\"tab-sign-up\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[1,[28,[35,10],null,[[\"tab\",\"nextRoute\"],[[33,4],[33,9]]]]],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\"]],[]],null]],[]]]],[]]]]]],[],false,[\"account/account-modal\",\"action\",\"if\",\"eq\",\"tab\",\"account/reset-password\",\"mut\",\"account/sign-in-view\",\"route\",\"nextRoute\",\"account/sign-up-view\"]]",
    "moduleName": "lh-public-olo/templates/components/account/entry-view.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});