define("lh-public-olo/adapters/item", ["exports", "@ember/service", "lh-public-olo/adapters/menu"], function (_exports, _service, _menu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _menu.default.extend({
    featureFlag: (0, _service.inject)(),
    urlForFindRecord: function urlForFindRecord(id, modelName) {
      var baseUrl = this.buildURL();
      var location = this.get('ticket.locationRef');
      var url = this.featureFlag.isEnabled('skytab-online-ooc-get-menu') ? "".concat(baseUrl, "/").concat(location, "/").concat(this.pathForType(modelName), "/").concat(id) : "".concat(baseUrl, "/").concat(location, "/menu/").concat(modelName, "/").concat(id);
      return this.get('ticket.dineIn') ? "".concat(url, "?dineIn=true") : url;
    },
    urlForQuery: function urlForQuery() {
      var url = this._super.apply(this, arguments);

      return this.get('ticket.dineIn') ? "".concat(url, "?dineIn=true") : url;
    }
  });

  _exports.default = _default;
});