define("lh-public-olo/routes/location/menu/account/entry", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          nextRoute: undefined
        });
      }
    }
  });

  _exports.default = _default;
});