define("lh-public-olo/services/customer", ["exports", "@ember/service", "@ember/polyfills", "rsvp", "libphonenumber"], function (_exports, _service, _polyfills, _rsvp, _libphonenumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var phoneUtil = _libphonenumber.default.PhoneNumberUtil.getInstance();

  var PNF = _libphonenumber.default.PhoneNumberFormat;

  var _default = _service.default.extend({
    ticket: (0, _service.inject)(),
    store: (0, _service.inject)(),
    session: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    selectedDeliveryAddress: undefined,
    username: undefined,
    selectedPaymentType: undefined,
    countryCode: 'US',
    getCustomer: function getCustomer() {
      var _this = this;

      var reload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (this.store.peekAll('customer').get('length') && !reload) {
        return this.store.peekAll('customer').get('firstObject');
      }

      if (this.get('ticket.isRememberCustomerAccountInfoFeatureEnabled') && this.get('session.isAuthenticated')) {
        return this.store.queryRecord('customer', {}).then(function (customer) {
          _this.updateUsername(customer);

          return customer;
        }).catch(function () {
          return (0, _rsvp.resolve)(_this.store.createRecord('customer'));
        });
      }

      return this.store.createRecord('customer');
    },
    getCustomerInfoForOrder: function getCustomerInfoForOrder() {
      var customer = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.getCustomer();
      var orderCustomer = {
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: null,
        zip: null
      };
      var phoneNumber = phoneUtil.parse(customer.phone, this.get('customerService.countryCode'));
      (0, _polyfills.assign)(orderCustomer, customer.getProperties('firstName', 'lastName', 'email'), {
        phone: phoneUtil.format(phoneNumber, PNF.E164)
      });

      if (this.get('ticket.orderType.requiresAddress')) {
        return (0, _polyfills.assign)(orderCustomer, this.selectedDeliveryAddress.getProperties('addressLine1', 'addressLine2', 'city', 'state', 'zip'));
      }

      return orderCustomer;
    },
    updateUsername: function updateUsername(customer) {
      if (!customer) {
        return;
      }

      var firstName = customer.firstName,
          lastName = customer.lastName,
          email = customer.email;
      this.set('username', "".concat(firstName, " ").concat(lastName).trim() || email);
    }
  });

  _exports.default = _default;
});