define("lh-public-olo/components/checkout-button", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    ticket: (0, _service.inject)(),
    session: (0, _service.inject)(),
    hasNoOffHoursRestrictions: (0, _object.computed)(function () {
      return !this.ticket.dineIn || !this.ticket.menuSchedule.duringOffHours || this.ticket.dineInAllowDuringOffHours;
    })
  });

  _exports.default = _default;
});