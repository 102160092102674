define("lh-public-olo/utils/clear-local-storage", ["exports", "lh-public-olo/utils/has-local-storage"], function (_exports, _hasLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = clearLocalStorage;

  /**
   * Clear data in local storage
   *
   * @param {string} locationRef - ref of location
   */
  function clearLocalStorage(locationRef) {
    if ((0, _hasLocalStorage.default)()) {
      // removeItem is used to clear only OLO localStorage data
      // and leave 3rd party localStorage entries intact
      localStorage.removeItem(locationRef);
    }
  }
});