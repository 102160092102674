define("lh-public-olo/templates/location/menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "O2Kr7o7/",
    "block": "[[[8,[39,0],null,[[\"@named\",\"@send\"],[\"navigation\",[50,\"category-tabs\",0,null,[[\"location\",\"menuSchedules\",\"categories\",\"showDropdown\",\"setMenuSchedule\"],[[33,2,[\"location\"]],[33,2,[\"menuSchedules\"]],[33,2,[\"categories\"]],[28,[37,3],[\"isMobile\"],null],[28,[37,4],[[30,0],\"setMenuSchedule\"],null]]]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@categories\",\"@location\",\"@isMobile\"],[[33,2,[\"categories\"]],[33,2,[\"location\"]],[28,[37,3],[\"isMobile\"],null]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@named\",\"@send\"],[\"order-details\",[28,[37,6],null,[[\"checkoutButton\",\"orderTypeDetails\"],[true,[28,[37,7],[[33,8,[\"dineIn\"]]],null]]]]]],null],[1,\"\\n\\n\"],[46,[28,[37,9],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"to-elsewhere\",\"component\",\"model\",\"media\",\"action\",\"menu-column\",\"hash\",\"not\",\"ticket\",\"-outlet\"]]",
    "moduleName": "lh-public-olo/templates/location/menu.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});