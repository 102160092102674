define("lh-public-olo/templates/location/menu/account/update-password", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PruMkuJS",
    "block": "[[[8,[39,0],null,[[\"@named\",\"@send\"],[\"item-modal\",[50,\"account/update-password\",0,null,[[\"close\",\"redirectToSignIn\",\"token\"],[[28,[37,2],[[30,0],\"close\"],null],[28,[37,2],[[30,0],\"redirectToSignIn\"],null],[33,3]]]]]],null],[1,\"\\n\"]],[],false,[\"to-elsewhere\",\"component\",\"action\",\"model\"]]",
    "moduleName": "lh-public-olo/templates/location/menu/account/update-password.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});