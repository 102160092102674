define("lh-public-olo/models/cart-item-modifier", ["exports", "@ember-data/model", "@ember/object"], function (_exports, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    cartItem: (0, _model.belongsTo)('cart-item'),
    modifierSetRef: (0, _model.attr)('string'),
    modifierRef: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    modifiers: (0, _model.hasMany)('cart-item-modifier', {
      inverse: 'parentModifier',
      async: false
    }),
    parentModifier: (0, _model.belongsTo)('cart-item-modifier', {
      inverse: 'modifiers',
      async: false
    }),
    isFlatAmount: (0, _object.computed)('modifier.type', function () {
      return this.get('modifier.type') !== 'percent';
    }),
    unitPrice: (0, _object.computed)('isFlatAmount', 'cartItem.item.price', 'modifier.percent', 'modifier.price', function () {
      this.cartItem;

      if (this.isFlatAmount) {
        return this.get('modifier.price');
      }

      return this.get('cartItem.item.price') * this.get('modifier.percent');
    }),
    subtotal: (0, _object.computed)('unitPrice', 'quantity', function () {
      return this.unitPrice * this.quantity;
    }),
    // using these computed properties instead of relations because the relations load weirdly
    modifierSet: (0, _object.computed)('modifierSetRef', 'cartItem.item.modifierSets.[]', 'parentModifier.modifierSets.[]', function () {
      return this.store.peekRecord('modifier-set', this.modifierSetRef);
    }),
    modifier: (0, _object.computed)('modifierRef', 'modifierSet.modifiers.[]', function () {
      return this.store.peekRecord('modifier', this.modifierRef);
    })
  });

  _exports.default = _default;
});