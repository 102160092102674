define("lh-public-olo/adapters/application", ["exports", "@ember-data/adapter/rest", "@ember/service", "@ember/object", "@ember/string", "ember-inflector", "lh-public-olo/config/environment", "uuid"], function (_exports, _rest, _service, _object, _string, _emberInflector, _environment, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    namespace: 'api/v1/public',
    host: _environment.default.host,
    session: (0, _service.inject)(),
    headers: (0, _object.computed)('session.isAuthenticated', 'session.data.authenticated.accessToken', function () {
      if (this.get('session.isAuthenticated')) {
        return {
          Authorization: "Bearer ".concat(this.get('session.data.authenticated.accessToken'))
        };
      }

      return {};
    }),
    pathForType: function pathForType(type) {
      return (0, _emberInflector.pluralize)((0, _string.dasherize)(type));
    },
    generateIdForRecord: function generateIdForRecord() {
      return (0, _uuid.v4)();
    },
    // this is private, whoops
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      if (payload && Array.isArray(payload)) {
        return payload;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});