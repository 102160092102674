define("lh-public-olo/serializers/location", ["exports", "@ember-data/serializer/rest", "lh-public-olo/serializers/application"], function (_exports, _rest, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'locationRef',
    normalize: function normalize(model, hash) {
      hash.locationId = hash.id;
      delete hash.id;
      return this._super.apply(this, arguments);
    },
    attrs: {
      orderTypes: {
        embedded: 'always'
      },
      menuSchedules: {
        embedded: 'always'
      },
      tenders: {
        embedded: 'always'
      },
      dropOffAddresses: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});