define("lh-public-olo/components/sold-out-warning-view", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _component.default.extend({
    classNames: ['sold-out-warning-modal'],
    ticket: (0, _service.inject)(),
    itemsOutOfStock: undefined,
    itemsToBeRemoved: undefined,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var soldOutItems = [];
      var itemsOutOfStock = [];
      this.ticket.itemsOutOfStock.forEach(function (item) {
        var soldOutCartItems = _this.ticket.items.filter(function (cartItem) {
          return cartItem.get('item.id') === item;
        });

        soldOutCartItems[0].set('item.stockStatus', 'OUT_OF_STOCK');
        itemsOutOfStock.push(soldOutCartItems[0].get('item'));
        soldOutItems.push.apply(soldOutItems, _toConsumableArray(soldOutCartItems));
      });
      this.set('itemsOutOfStock', itemsOutOfStock);
      this.set('itemsToBeRemoved', soldOutItems);
    },
    didInsertElement: function didInsertElement() {
      this.$('.modal').modal('show');
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.modal').modal('setting', 'onHide', function () {}).modal('hide');
    },
    removeItems: function removeItems() {
      var _this2 = this;

      this.itemsToBeRemoved.forEach(function (item) {
        _this2.ticket.items.removeObject(item);

        item.destroyRecord();
      });
    },
    actions: {
      continue: function _continue() {
        this.removeItems();
        this.set('ticket.itemsOutOfStock', undefined);
        this.ticket.calculate();
        this.sendAction('close');
      }
    }
  });

  _exports.default = _default;
});