define("lh-public-olo/components/customer-info", ["exports", "@ember/component", "@ember/service", "libphonenumber", "lh-public-olo/utils/country-regions", "@datadog/browser-logs"], function (_exports, _component, _service, _libphonenumber, _countryRegions, _browserLogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var phoneUtil = _libphonenumber.default.PhoneNumberUtil.getInstance();

  var PNF = _libphonenumber.default.PhoneNumberFormat;

  var _default = _component.default.extend({
    classNames: ['ui', 'form', 'checkout'],
    ticket: (0, _service.inject)(),
    store: (0, _service.inject)(),
    session: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    lastPhone: undefined,
    addressType: 'initial',
    actions: {
      onPhoneBlur: function onPhoneBlur() {
        var phone = this.get('customer.phone');

        if (!phone) {
          this.set('lastPhone', undefined);
          return;
        }

        try {
          var phoneNumber = phoneUtil.parse(phone, this.get('customerService.countryCode')); // Format phone number as 2 chars are stripped, 3 chars are considered valid,
          // some chars can be converted to numbers and user has no way of seeing this before submitting.
          // Also, we want to send formatted number to backend.
          // @see https://jira.shift4payments.com/browse/LH-11284
          // @see https://github.com/google/libphonenumber/blob/master/FAQ.md

          if (phoneUtil.isValidNumber(phoneNumber)) {
            this.set('customer.phone', phoneUtil.format(phoneNumber, PNF.INTERNATIONAL));
          }
        } catch (e) {// do nothing as validation will be performed on form submit
        }

        if (this.lastPhone !== this.get('customer.phone')) {
          this.sendAction('phoneChanged');
        }

        this.set('lastPhone', this.get('customer.phone'));
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('address')) {
        _browserLogs.datadogLogs.logger.info('Previous address value: ', this.get('address'));
      }

      if (this.get('ticket.isDropOff')) {
        this.set('addressType', 'selectedDeliveryAddress');
        this.set('address', this.get('customerService.selectedDeliveryAddress'));
      }

      if (!this.get('ticket.isDropOff') && (!this.get('ticket.isRememberCustomerAccountInfoFeatureEnabled') || !this.get('session.isAuthenticated'))) {
        if (!this.get('customer.deliveryAddresses.length') && !this.get('customerService.selectedDeliveryAddress')) {
          this.set('addressType', 'newAddress');
          this.set('address', this.store.createRecord('delivery-address'));
          this.set('customerService.selectedDeliveryAddress', this.address);
        } else if (this.get('customerService.selectedDeliveryAddress')) {
          this.set('addressType', 'selectedDeliveryAddress');
          this.set('address', this.get('customerService.selectedDeliveryAddress'));
        } else {
          this.set('addressType', 'defaultAddress');
          this.set('address', this.get('customer.deliveryAddresses.firstObject'));
          this.set('customerService.selectedDeliveryAddress', this.address);
        }

        if (!this.get('address')) {
          _browserLogs.datadogLogs.logger.info('Address is set to null:', '-', this.get('addressType'), 'Address value:', '-', this.get('address'), 'selectedDeliveryAddress value', '-', this.get('customerService.selectedDeliveryAddress'), 'deliveryAddresses object value:', '-', this.get('customer.deliveryAddresses.firstObject'));
        }
      }

      this.set('regionOptions', (0, _countryRegions.getRegions)(this.get('countryCode')));
      this.set('regionLabel', (0, _countryRegions.getRegionLabel)(this.get('countryCode')));
    }
  });

  _exports.default = _default;
});