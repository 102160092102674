define("lh-public-olo/models/location", ["exports", "@ember-data/model", "@ember/object", "@ember/object/computed", "@ember/service", "lh-public-olo/enums/country-code"], function (_exports, _model, _object, _computed, _service, _countryCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ticket: (0, _service.inject)(),
    locationId: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    logoUrl: (0, _model.attr)('string'),
    oloLogoUrl: (0, _model.attr)('string'),
    oloContactPhone: (0, _model.attr)('string'),
    oloContactEmail: (0, _model.attr)('string'),
    oloStoreStatus: (0, _model.attr)('string'),
    addressLine1: (0, _model.attr)('string'),
    addressLine2: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    zip: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    countryCode: (0, _model.attr)('string', {
      defaultValue: _countryCode.default.US
    }),
    timeZoneOffset: (0, _model.attr)('number'),
    menuSchedules: (0, _model.hasMany)('menu-schedule'),
    tenders: (0, _model.hasMany)('tender'),
    isClosed: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    closureReason: (0, _model.attr)('string'),
    tipSettings: (0, _model.attr)(),
    metaPixelId: (0, _model.attr)('string'),
    tipPercentages: (0, _computed.collect)('tipSettings.predefinedTipPercentage1', 'tipSettings.predefinedTipPercentage2', 'tipSettings.predefinedTipPercentage3'),
    storeClosureMessage: (0, _object.computed)('closureReason', function () {
      return this.closureReason || 'We apologize for the inconvenience, at this time online ordering is not available';
    }),
    orderTypes: (0, _model.hasMany)('order-type'),
    oloStoreActive: (0, _object.computed)('oloStoreStatus', function () {
      return this.oloStoreStatus === 'LIVE' || this.get('ticket.dineIn');
    }),
    allowGiftCardPayments: (0, _model.attr)('boolean'),
    allowCouponDiscounts: (0, _model.attr)('boolean'),
    dineInAllowDuringOffHours: (0, _model.attr)('boolean'),
    reCaptchaEnabled: (0, _model.attr)('boolean'),
    signInPromptEnabled: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});