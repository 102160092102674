define("lh-public-olo/enums/address-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ADDRESS_TYPES = Object.freeze({
    DROP_OFF: 'dropOffAddress',
    CUSTOMER: 'customerAddress'
  });
  var _default = ADDRESS_TYPES;
  _exports.default = _default;
});