define("lh-public-olo/models/delivery-address", ["exports", "@ember-data/model", "ember-cp-validations", "@ember/service", "@ember/object", "@ember/object/computed", "lh-public-olo/enums/address-types", "lh-public-olo/utils/country-regions"], function (_exports, _model, _emberCpValidations, _service, _object, _computed, _addressTypes, _countryRegions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    addressLine1: {
      description: 'Address Line 1',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: (0, _computed.not)('model.ticket.orderType.requiresAddress').volatile()
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    addressLine2: (0, _emberCpValidations.validator)('length', {
      max: 255
    }),
    city: {
      description: 'City',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: (0, _computed.not)('model.ticket.orderType.requiresAddress').volatile()
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    state: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: (0, _computed.not)('model.ticket.orderType.requiresAddress').volatile(),
      description: (0, _object.computed)('model.customerService.countryCode', function () {
        return (0, _countryRegions.getRegionLabel)(this.get('model.customerService.countryCode'));
      })
    }),
    zip: {
      description: 'Postal Code',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: (0, _computed.not)('model.ticket.orderType.requiresAddress').volatile()
      }), (0, _emberCpValidations.validator)('postal-code', {
        allowBlank: true,
        countryCode: (0, _object.computed)('model.customerService.countryCode', function () {
          return this.get('model.customerService.countryCode');
        })
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    ticket: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    addressLine1: (0, _model.attr)('string'),
    addressLine2: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    zip: (0, _model.attr)('string'),
    addressType: _addressTypes.default.CUSTOMER,
    reset: function reset() {
      this.setProperties({
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: null,
        zip: null
      });
    }
  });

  _exports.default = _default;
});