define("lh-public-olo/controllers/location/menu/account/update-password", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    ticket: (0, _service.inject)(),
    actions: {
      close: function close() {
        if (this.get('ticket.orderTypeGuid')) {
          this.transitionToRoute('location.menu');
        } else {
          this.transitionToRoute('location.menu.order-settings');
        }
      },
      redirectToSignIn: function redirectToSignIn() {
        this.transitionToRoute('location.menu.account.entry');
      }
    }
  });

  _exports.default = _default;
});