define("lh-public-olo/templates/components/back-to-menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Vheo6rGE",
    "block": "[[[41,[33,1],[[[1,\"\\t\"],[8,[39,2],null,[[\"@route\"],[[99,1,[\"@route\"]]]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[10,\"i\"],[14,0,\"arrow left icon\"],[12],[13],[1,\"Back to \"],[1,[34,3]],[1,\"\\n\\t\"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[33,4],[[[1,\"\\t\"],[11,3],[24,6,\"#\"],[4,[38,5],[[30,0],[33,4]],null],[12],[1,\"\\n\\t\\t\"],[10,\"i\"],[14,0,\"arrow left icon\"],[12],[13],[1,\"Back to \"],[1,[34,3]],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"link\",\"link-to\",\"name\",\"onClick\",\"action\",\"yield\"]]",
    "moduleName": "lh-public-olo/templates/components/back-to-menu.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});