define("lh-public-olo/adapters/discount", ["exports", "@ember-data/adapter/rest", "@ember/service", "lh-public-olo/config/environment"], function (_exports, _rest, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    host: _environment.default.host,
    namespace: 'api/v1/public',
    ticket: (0, _service.inject)(),
    urlForQuery: function urlForQuery() {
      var baseUrl = this.buildURL();
      var location = this.get('ticket.locationRef');
      return "".concat(baseUrl, "/discounts/").concat(location);
    }
  });

  _exports.default = _default;
});