define("lh-public-olo/components/loyalty-panel", ["exports", "@ember/component", "ember-cp-validations", "@ember/service", "@ember/object", "lh-public-olo/utils/validation-check", "libphonenumber", "lh-public-olo/enums/phone-input-placeholder"], function (_exports, _component, _emberCpValidations, _service, _object, _validationCheck, _libphonenumber, _phoneInputPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var phoneUtil = _libphonenumber.default.PhoneNumberUtil.getInstance();

  var PNF = _libphonenumber.default.PhoneNumberFormat;
  var Validations = (0, _emberCpValidations.buildValidations)({
    createMemberPhoneNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('phone-number', {
      countryCode: (0, _object.computed)('customerService.countryCode', function () {
        return this.get('customerService.countryCode');
      })
    })]
  });

  var _default = _component.default.extend(Validations, {
    classNames: ['loyalty-panel', 'ui', 'form', 'checkout'],
    ticket: (0, _service.inject)(),
    store: (0, _service.inject)(),
    loyaltyMember: (0, _service.inject)(),
    locale: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    isLoading: false,
    firstName: undefined,
    lastName: undefined,
    showErrors: false,
    phoneInputPlaceholder: (0, _object.computed)(function () {
      return _phoneInputPlaceholder.default[this.locale.countryCode] || _phoneInputPlaceholder.default.defaultFormat;
    }),
    lookupResponse: (0, _object.computed)('loyaltyProgram.pointName', 'loyaltyMember.memberRecord.pointsBalance', function () {
      return this.loyaltyMember.lookupResponse(this.get('loyaltyProgram.pointName'));
    }),
    createResponse: (0, _object.computed)('loyaltyProgram.name', 'loyaltyMember.memberCreated', function () {
      return this.loyaltyMember.createResponse(this.get('loyaltyProgram.name'));
    }),
    discounts: (0, _object.computed)('loyaltyMember.memberRecord.id', function () {
      if (!this.loyaltyMember || !this.loyaltyMember.memberRecord || !this.loyaltyMember.memberRecord.id) {
        return [];
      }

      var member = this.store.peekRecord('loyalty/member', this.loyaltyMember.memberRecord.id);

      if (!member) {
        return [];
      }

      return this.store.query('discount', {
        maxPointCost: member.pointsBalance
      });
    }),
    redeemedDiscountId: (0, _object.computed)('ticket.loyaltyDiscount', function () {
      var discount = this.get('ticket.loyaltyDiscount');
      return discount ? discount.get('id') : null;
    }),
    actions: {
      lookup: function lookup() {
        this.set('showErrors', false);
        this.loyaltyMember.searchMember(this.get('loyaltyMember.phoneNumber'));

        if (this.get('loyaltyMember.memberNotFound') && this.get('loyaltyMember.isUsingContactDetails')) {
          this.loyaltyMember.copyCustomerToCreateMember();
        }
      },
      create: function create() {
        var _this = this;

        if (this.isLoading) {
          return;
        }

        this.set('showErrors', false);
        this.set('isLoading', true);
        this.loyaltyMember.validate().then(_validationCheck.default).then(function () {
          return _this.loyaltyMember.create();
        }).catch(function (_ref) {
          var validations = _ref.validations;

          _this.set('showErrors', !!validations);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      useContactDetailsForPhone: function useContactDetailsForPhone() {
        this.loyaltyMember.useContactDetailsForPhone();
      },
      clearPhone: function clearPhone() {
        this.loyaltyMember.clearPhone();
      },
      redeemDiscount: function redeemDiscount(discountId) {
        var discount = this.discounts.findBy('id', discountId);
        this.set('ticket.loyaltyDiscount', discount);
        this.ticket.calculate();
      },
      unredeemDiscount: function unredeemDiscount() {
        this.set('ticket.loyaltyDiscount', null);
        this.ticket.calculate();
      },
      onPhoneBlur: function onPhoneBlur() {
        var phone = this.get('loyaltyMember.createMemberPhoneNumber');

        try {
          var phoneNumber = phoneUtil.parse(phone, this.get('customerService.countryCode'));

          if (phoneUtil.isValidNumber(phoneNumber)) {
            this.set('loyaltyMember.createMemberPhoneNumber', phoneUtil.format(phoneNumber, PNF.INTERNATIONAL));
          }
        } catch (e) {// do nothing as validation will be performed on form submit
        }
      }
    }
  });

  _exports.default = _default;
});