define("lh-public-olo/routes/location/user-profile", ["exports", "@ember/routing/route", "@ember/service", "rsvp"], function (_exports, _route, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    session: (0, _service.inject)(),
    ticket: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      if (!this.get('ticket.isAccountManageFeatureEnabled')) {
        return this.transitionTo('location.menu');
      }

      this.session.requireAuthentication(transition, function () {
        return _this.transitionTo('location.menu.account.entry');
      });
    },
    afterModel: function afterModel(model) {
      model.customer.rollbackAttributes();
    },
    model: function model() {
      var location = this.modelFor('location');
      return (0, _rsvp.hash)({
        location: location,
        customer: this.customerService.getCustomer(true),
        paymentMethods: this.store.findAll('payment-method')
      });
    }
  });

  _exports.default = _default;
});