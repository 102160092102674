define("lh-public-olo/templates/components/order-type-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rK1mnOBQ",
    "block": "[[[10,0],[14,0,\"order-info-body\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"order-type-container\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"\\t\\t\\t\"],[10,\"button\"],[15,0,[29,[\"ui button order-button \",[52,[28,[37,4],[[33,5],[30,1,[\"id\"]]],null],\"selected\"]]]],[15,\"onclick\",[28,[37,6],[[30,0],\"selectOrderType\",[30,1,[\"id\"]]],null]],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[1]],null],[1,\"\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,2,null],[1,\"\\n\"]],[\"orderType\",\"&default\"],false,[\"each\",\"-track-array\",\"orderTypes\",\"if\",\"eq\",\"selected\",\"action\",\"yield\"]]",
    "moduleName": "lh-public-olo/templates/components/order-type-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});