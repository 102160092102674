define("lh-public-olo/components/ui/custom-ui-radio", ["exports", "semantic-ui-ember/components/ui-radio"], function (_exports, _uiRadio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _uiRadio.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var inputElement = this.element.querySelector('input[type="radio"]');

      if (inputElement && this.get('aria-label')) {
        inputElement.setAttribute('aria-label', this.get('aria-label'));
      }
    }
  });

  _exports.default = _default;
});