define("lh-public-olo/components/order-type-select", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    selected: 'TAKEOUT',
    actions: {
      selectOrderType: function selectOrderType(orderTypeGuid) {
        this.set('selected', orderTypeGuid);
        this.sendAction('onSelect', orderTypeGuid);
      }
    }
  });

  _exports.default = _default;
});