define("lh-public-olo/components/delivery-address-dropdown", ["exports", "@ember/component", "@ember/service", "@datadog/browser-logs"], function (_exports, _component, _service, _browserLogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['delivery-address-dropdown', 'field'],
    store: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    addressType: 'initial',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('address')) {
        _browserLogs.datadogLogs.logger.info('Delivery dropdown previous address value: ', this.get('address'));
      }

      if (this.get('customerService.selectedDeliveryAddress.isNew')) {
        this.set('addressType', 'selectedDeliveryAddress');
        this.set('address', this.get('customerService.selectedDeliveryAddress'));
      } else {
        this.set('addressType', 'newAddress');
        this.set('address', this.store.createRecord('delivery-address'));
      }

      if (!this.get('address')) {
        _browserLogs.datadogLogs.logger.info('Delivery dropdown address is set to null:', '-', this.get('addressType'), 'Delivery dropdown address value:', '-', this.get('address'), 'Delivery dropdown selectedDeliveryAddress value', '-', this.get('customerService.selectedDeliveryAddress'));
      }

      if (!this.get('customerService.selectedDeliveryAddress') && !this.get('customer.deliveryAddresses.length')) {
        this.send('addressSelect', 'NEW_ADDRESS');
      } else if (!this.get('customerService.selectedDeliveryAddress')) {
        this.send('addressSelect', this.get('customer.deliveryAddresses.firstObject.id'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('customerService.selectedDeliveryAddress.id') && this.get('customerService.selectedDeliveryAddress.id') !== this.get('address.id')) {
        this.address.unloadRecord();
        this.set('address', undefined);
      }
    },
    actions: {
      addressSelect: function addressSelect(value) {
        if (value === 'NEW_ADDRESS') {
          this.set('customerService.selectedDeliveryAddress', this.address);
        } else {
          this.set('customerService.selectedDeliveryAddress', this.get('customer.deliveryAddresses').findBy('id', value));
        }
      }
    }
  });

  _exports.default = _default;
});