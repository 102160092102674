define("lh-public-olo/authenticators/jwt-register", ["exports", "ember-simple-auth-token/authenticators/jwt", "lh-public-olo/config/environment"], function (_exports, _jwt, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jwt.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.serverTokenEndpoint = "".concat(_environment.default.oocHost, "/online-ordering/mars/api/v2/customer/registration");
    }
  });

  _exports.default = _default;
});