define("lh-public-olo/components/account/sign-in-view", ["exports", "@ember/component", "@ember/service", "rsvp", "ember-cp-validations", "lh-public-olo/utils/validation-check"], function (_exports, _component, _service, _rsvp, _emberCpValidations, _validationCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: 'Email Address',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        minTldLength: 2
      })]
    },
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Password'
    })]
  });

  var _default = _component.default.extend(Validations, {
    classNames: ['user-account-view'],
    session: (0, _service.inject)(),
    isLoading: false,
    showErrors: false,
    signIn: function signIn() {
      this.set('isLoading', true);
      var email = this.email,
          password = this.password;
      return this.session.authenticate('authenticator:jwt-custom', {
        email: email,
        password: password
      }).catch(function (e) {
        var error = e.json;
        var loginError = error.message || 'Unknown Error';
        return (0, _rsvp.reject)({
          loginError: loginError
        });
      });
    },
    actions: {
      signIn: function signIn() {
        var _this = this;

        if (this.isLoading) {
          return;
        }

        this.setProperties({
          showErrors: false,
          loginError: undefined
        });
        this.validate().then(_validationCheck.default).then(function () {
          return _this.signIn();
        }).catch(function (_ref) {
          var validations = _ref.validations,
              loginError = _ref.loginError;

          _this.setProperties({
            showErrors: validations,
            loginError: loginError
          });
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      close: function close() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});