define("lh-public-olo/templates/components/credit-card-i4go-iframe", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ycIPuZcJ",
    "block": "[[[10,0],[14,0,\"ui active inverted dimmer\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"ui large loader\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,1,\"i4goFrame\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "lh-public-olo/templates/components/credit-card-i4go-iframe.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});