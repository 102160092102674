define("lh-public-olo/templates/components/store-closure-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rGgXd5K8",
    "block": "[[[8,[39,0],null,[[\"@autofocus\",\"@duration\",\"@closable\"],[false,200,false]],[[\"default\"],[[[[1,\"\\n\\t\"],[10,0],[14,0,\"header\"],[12],[1,\"\\n\"],[41,[28,[37,2],[\"isMobile\"],null],[[[41,[33,3,[\"name\"]],[[[1,\"\\t\\t\\t\\t\"],[10,\"h3\"],[14,0,\"ui header\"],[12],[1,\"Welcome to \"],[1,[33,3,[\"name\"]]],[1,\" Online Ordering\"],[13],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\\t\\t\"],[10,\"h3\"],[14,0,\"ui header\"],[12],[1,\"Online Ordering\"],[13],[1,\"\\n\"]],[]]]],[]],[[[41,[33,3,[\"name\"]],[[[1,\"\\t\\t\\t\\t\"],[10,\"h2\"],[14,0,\"ui header\"],[12],[1,\"Welcome to \"],[1,[33,3,[\"name\"]]],[1,\" Online Ordering\"],[13],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\\t\\t\"],[10,\"h2\"],[14,0,\"ui header\"],[12],[1,\"Online Ordering\"],[13],[1,\"\\n\"]],[]]]],[]]],[1,\"\\t\"],[13],[1,\"\\n\\t\"],[10,0],[14,0,\"content\"],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"ui segment\"],[12],[1,\"\\n\\t\\t\\t\"],[10,\"img\"],[14,0,\"store-closure-image\"],[14,\"alt\",\"Store closure\"],[12],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"store-closure-message\"],[12],[1,\"\\n\"],[41,[28,[37,2],[\"isMobile\"],null],[[[1,\"\\t\\t\\t\\t\\t\"],[10,\"h5\"],[12],[1,[34,4]],[13],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\\t\\t\\t\"],[10,\"h4\"],[12],[1,[34,4]],[13],[1,\"\\n\"]],[]]],[1,\"\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"ui-modal\",\"if\",\"media\",\"location\",\"storeClosureMessage\"]]",
    "moduleName": "lh-public-olo/templates/components/store-closure-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});