define("lh-public-olo/templates/components/quantity-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EzinbT6k",
    "block": "[[[11,0],[24,0,\"ui basic button\"],[4,[38,0],[[30,0],\"subtract\"],null],[12],[1,\"-\"],[13],[1,\"\\n\"],[8,[39,1],[[24,\"aria-label\",\"Quantity\"]],[[\"@type\",\"@min\",\"@value\"],[\"number\",\"1\",[99,2,[\"@value\"]]]],null],[1,\"\\n\"],[11,0],[24,0,\"ui basic button\"],[4,[38,0],[[30,0],\"add\"],null],[12],[1,\"+\"],[13],[1,\"\\n\"]],[],false,[\"action\",\"input\",\"value\"]]",
    "moduleName": "lh-public-olo/templates/components/quantity-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});