define("lh-public-olo/routes/location/checkout/account", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ticket: (0, _service.inject)(),
    session: (0, _service.inject)(),
    beforeModel: function beforeModel(transition) {
      if (!this.get('ticket.isUserAccountEnabled') || this.get('session.isAuthenticated')) {
        transition.abort();
        this.transitionTo('location.checkout');
      }
    }
  });

  _exports.default = _default;
});