define("lh-public-olo/utils/get-local-storage", ["exports", "lh-public-olo/utils/has-local-storage"], function (_exports, _hasLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getLocalStorage;

  /**
   * Gets data in localStorage for locations
   *
   * @method     getLocalStorage
   * @param      {String} locationRef ref for location related to data
   * @returns    {Object} the data from localStorage
   */
  function getLocalStorage(locationRef) {
    var blank = {
      items: [],
      orderSettings: {}
    };

    if ((0, _hasLocalStorage.default)()) {
      var data = localStorage.getItem(locationRef);

      try {
        data = JSON.parse(data);

        if (!data) {
          data = blank;
        }

        if (!data.items) {
          throw new Error();
        }

        if (data.expiresAt && data.expiresAt < Date.now()) {
          data = blank;
          localStorage.removeItem(locationRef);
        }
      } catch (e) {
        data = blank;
        localStorage.removeItem(locationRef);
        alert('There was an error reading item information. Any items you may have had selected' + ' previously were not able to be recovered. We apologize for this inconvenience.');
        location.reload();
      }

      return data;
    }

    return blank;
  }
});