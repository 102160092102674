define("lh-public-olo/templates/components/location-info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GKz6X1AL",
    "block": "[[[10,0],[14,0,\"location-info\"],[12],[1,\"\\n\\t\"],[10,\"h1\"],[14,0,\"ui header location\"],[12],[1,\"\\n\\t\\t\"],[1,[30,1,[\"name\"]]],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"sub header\"],[12],[1,\"\\n\\t\\t\\t\"],[1,[30,1,[\"addressLine1\"]]],[41,[30,1,[\"addressLine2\"]],[[[1,\" \"],[1,[30,1,[\"addressLine2\"]]]],[]],null],[1,\", \"],[1,[30,1,[\"city\"]]],[1,\" \"],[1,[30,1,[\"state\"]]],[1,\" \"],[1,[30,1,[\"zip\"]]],[1,\"\\n\\t\\t\\t\"],[41,[30,1,[\"oloContactPhone\"]],[[[1,\"| \"],[1,[30,1,[\"oloContactPhone\"]]]],[]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[41,[30,1,[\"logoUrl\"]],[[[1,\"\\t\\t\"],[10,\"img\"],[14,0,\"ui image\"],[15,\"src\",[29,[[30,1,[\"logoUrl\"]]]]],[15,\"alt\",[30,1,[\"name\"]]],[12],[13],[1,\"\\n\"]],[]],null],[13]],[\"@location\"],false,[\"if\"]]",
    "moduleName": "lh-public-olo/templates/components/location-info.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});