define("lh-public-olo/components/ui-calendar", ["exports", "@ember/component", "semantic-ui-ember/mixins/base"], function (_exports, _component, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_base.default, {
    module: 'calendar',
    classNames: ['ui', 'calendar']
  });

  _exports.default = _default;
});