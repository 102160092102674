define("lh-public-olo/controllers/location/confirmation", ["exports", "@ember/service", "@ember/controller", "lh-public-olo/config/environment", "@ember/object", "@ember/runloop", "lh-public-olo/utils/time-util"], function (_exports, _service, _controller, _environment, _object, _runloop, _timeUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CONNECTION_TIMEOUT_MS = 2 * 60 * 1000;

  var _default = _controller.default.extend({
    ticket: (0, _service.inject)(),
    locale: (0, _service.inject)(),
    queryParams: ['orderRef'],
    orderRef: undefined,
    ENV: _environment.default,
    loading: false,
    statusTypes: {
      inactive: 'LOCINA',
      // LOCATION_INACTIVE
      offline: 'LOCOFF',
      // LOCATION_OFFLINE
      cardDeclined: 'ORDCCNOPROC',
      // ORDER_CC_NOTPROCESSED
      giftCardDeclined: 'ORDGCNOPROC',
      // ORDER_GC_NOTPROCESSED
      confirmed: 'CONFIRMED',
      fulfilled: 'FULFILLED',
      rejected: 'REJECTED',
      readyForConfirmation: 'READY_FOR_CONFIRMATION',
      connectionInterrupted: 'CONNECTION_INTERRUPTED'
    },
    showSuccessMessage: (0, _object.computed)('model.status', function () {
      return this.model.status === this.statusTypes.confirmed || this.model.status === this.statusTypes.fulfilled || !this.ticket.isOrderConfirmationMessageFeatureEnabled && this.model.status === 'ok';
    }),
    backToMenuRoute: (0, _object.computed)('model.status', function () {
      if (this.model.status === this.statusTypes.rejected || this.model.status === this.statusTypes.connectionInterrupted) {
        return {
          name: 'Menu',
          link: 'location.menu'
        };
      }

      return {
        name: 'Checkout',
        link: 'location.checkout'
      };
    }),
    readyAtTime: (0, _object.computed)('model.orderReadyAtTime', function () {
      var readyAt = new Date(this.model.orderReadyAtTime);
      return "".concat((0, _timeUtil.isSameDay)(readyAt, new Date()) ? '' : (0, _timeUtil.getDisplayDay)(readyAt, undefined, this.locale.countryCode), " ").concat((0, _timeUtil.getTimeAsString)(readyAt, this.locale.countryCode));
    }),
    initSse: function initSse() {
      var _this = this;

      this.set('loading', true);
      var url = "".concat(_environment.default.host, "/api/v3/public/order/").concat(this.orderRef, "/status");
      this.set('sse', new EventSource(url));
      this.set('sseTimer', (0, _runloop.later)(function () {
        _this.closeSse();

        _this.set('model.status', _this.statusTypes.connectionInterrupted);
      }, CONNECTION_TIMEOUT_MS));
      this.sse.addEventListener('message', function (_ref) {
        var data = _ref.data;
        var model = JSON.parse(data);

        if (model.status === _this.statusTypes.readyForConfirmation) {
          return;
        }

        _this.closeSse();

        _this.set('model', model);
      });
      this.sse.addEventListener('error', function (_ref2) {
        var _errors$;

        var data = _ref2.data;

        _this.closeSse();

        var _ref3 = data ? JSON.parse(data) : {},
            errors = _ref3.errors;

        if ((errors === null || errors === void 0 ? void 0 : (_errors$ = errors[0]) === null || _errors$ === void 0 ? void 0 : _errors$.code) === 'ORDNF') {
          _this.transitionToRoute('location.menu');

          return;
        }

        _this.set('model.status', _this.statusTypes.connectionInterrupted);
      });
    },
    closeSse: function closeSse() {
      (0, _runloop.cancel)(this.sseTimer);
      this.sse.close();
      this.set('sse', undefined);
      this.set('loading', false);
    },
    actions: {
      refresh: function refresh() {
        this.initSse();
      }
    }
  });

  _exports.default = _default;
});