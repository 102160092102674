define("lh-public-olo/models/order-type", ["exports", "@ember-data/model", "@ember/object/computed", "@ember/object"], function (_exports, _model, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    isAsapOrderAvailable: (0, _model.attr)('boolean'),
    isAnticipatedOrderAvailable: (0, _model.attr)('boolean'),
    deliveryDistanceInMiles: (0, _model.attr)('number'),
    surchargeFeePercent: (0, _model.attr)('number'),
    requiresAddress: (0, _computed.equal)('id', 'DELIVERY'),
    minOrderAmount: (0, _model.attr)('number'),
    maxOrderAmount: (0, _model.attr)('number'),
    pickupLeadTimeInMinutes: (0, _model.attr)('number'),
    storeDeliveryLeadTimeInMinutes: (0, _model.attr)('number'),
    allowSpecialRequest: (0, _model.attr)('boolean'),
    storeDeliveryValidationType: (0, _model.attr)('string'),
    isServedByDoorDashDrive: (0, _model.attr)('boolean'),
    deliveryServedWithDSP: (0, _model.attr)('boolean'),
    leadTimeInMinutes: (0, _object.computed)(function () {
      if (this.id === 'TAKEOUT') {
        return this.pickupLeadTimeInMinutes;
      } else if (this.id === 'DELIVERY') {
        return this.storeDeliveryLeadTimeInMinutes;
      }
    })
  });

  _exports.default = _default;
});