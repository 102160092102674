define("lh-public-olo/enums/phone-input-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PHONE_INPUT_PLACEHOLDERS = void 0;
  var PHONE_INPUT_PLACEHOLDERS = Object.freeze({
    'US': 'e.g. (555) 555-5555',
    'GB': 'e.g. (117) 123-4567',
    defaultFormat: 'e.g. (555) 555-5555'
  });
  _exports.PHONE_INPUT_PLACEHOLDERS = PHONE_INPUT_PLACEHOLDERS;
  var _default = PHONE_INPUT_PLACEHOLDERS;
  _exports.default = _default;
});