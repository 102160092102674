define("lh-public-olo/mixins/header-child", ["exports", "@ember/service", "@ember/object/mixin", "jquery", "@ember/runloop"], function (_exports, _service, _mixin, _jquery, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    media: (0, _service.inject)(),
    didInsertElement: function didInsertElement() {
      // check for passive scroll listener support
      var supportsPassive = false;

      try {
        var opts = Object.defineProperty({}, 'passive', {
          get: function get() {
            supportsPassive = true;
            return true;
          }
        });
        window.addEventListener('test', null, opts);
      } catch (e) {
        /* ¯\_(ツ)_/¯ */
      } // watch scroll to dynamically hide header when mobile
      // this listener doesn't work well inside the runloop


      var scrollContainer = this.get('media.isMobile') ? window : document.querySelector('.menu-column');
      var menuColumn = document.querySelector('.menu-column');

      if (menuColumn) {
        this.set('_onScroll', (0, _runloop.bind)(this, function () {
          // optional function that can be used by the component to also include scrolling functionality
          this.onScroll();
        }));
        scrollContainer.addEventListener('scroll', this._onScroll, supportsPassive ? {
          passive: true
        } : false);
      }
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('scroll', this._onScroll);
    },
    onScroll: function onScroll() {},
    headerReset: function headerReset() {
      var lh = (0, _jquery.default)('.navigation-bar');
      var inner = lh.children('div');
      lh.height('auto');
      inner.css({
        top: 0
      });
    },
    headerHide: function headerHide() {
      var _document$querySelect = document.querySelector('.menu-column'),
          scrollTop = _document$querySelect.scrollTop,
          scrollHeight = _document$querySelect.scrollHeight,
          offsetHeight = _document$querySelect.offsetHeight;

      if (scrollTop <= 0) {
        return this.headerReset();
      }

      var lh = (0, _jquery.default)('.navigation-bar');
      var inner = lh.children('div');
      var headerHeight = (0, _jquery.default)('.navigation-bar .location-info').outerHeight(true); // Check if column's bottom has been reached
      // If it has - stop resizing or we sometimes get into infinite loop of resizing
      // on larger resolution mobile screens

      if (scrollTop + offsetHeight !== scrollHeight) {
        var st = scrollTop < headerHeight ? scrollTop : headerHeight;
        lh.height(Math.round(inner.outerHeight(true) - st));
        inner.css({
          top: "".concat(Math.round(-st), "px")
        });
      }
    }
  });

  _exports.default = _default;
});