define("lh-public-olo/routes/location/checkout/sold-out-warning", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ticket: (0, _service.inject)(),
    beforeModel: function beforeModel() {
      var _this$ticket, _this$ticket$itemsOut;

      if (!((_this$ticket = this.ticket) !== null && _this$ticket !== void 0 && (_this$ticket$itemsOut = _this$ticket.itemsOutOfStock) !== null && _this$ticket$itemsOut !== void 0 && _this$ticket$itemsOut.length)) {
        this.replaceWith('location.checkout');
      }
    }
  });

  _exports.default = _default;
});