define("lh-public-olo/services/session", ["exports", "@ember/service", "ember-simple-auth/services/session"], function (_exports, _service, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _session.default.extend({
    ticket: (0, _service.inject)(),
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    handleAuthentication: function handleAuthentication() {
      var baseUrl = "".concat(window.location.origin, "/").concat(this.get('ticket.locationRef'));

      if (this.get('router.currentRouteName').includes('checkout') || this.get('router.currentRouteName').includes('menu') && this.get('router.currentRoute.queryParams.nextRoute') === 'location.checkout') {
        baseUrl += '/checkout';
      } else if (this.get('router.currentRouteName').includes('user-profile')) {
        baseUrl += '/user-profile';
      }

      if (this.get('ticket.dineIn')) {
        baseUrl += "?dineIn=true".concat(this.get('ticket.tableNotes') ? "&tableNotes=".concat(this.get('ticket.tableNotes')) : '').concat(this.get('ticket.getParamSchedules') ? "&".concat(this.get('ticket.getParamSchedules')) : '');
      }

      window.location.replace("".concat(baseUrl).concat(this.get('ticket.getParamSchedules') && !this.get('ticket.dineIn') ? "?".concat(this.get('ticket.getParamSchedules')) : ''));
    },
    handleInvalidation: function handleInvalidation() {
      if (this.skipRedirectOnInvalidation) {
        this.set('skipRedirectOnInvalidation', false);
      } else {
        if (this.get('ticket.dineIn')) {
          return window.location.replace("".concat(window.location.origin, "/").concat(this.get('ticket.locationRef'), "?dineIn=true").concat(this.get('ticket.tableNotes') ? "&tableNotes=".concat(this.get('ticket.tableNotes')) : '').concat(this.get('ticket.getParamSchedules') ? "&".concat(this.get('ticket.getParamSchedules')) : ''));
        }

        window.location.replace("".concat(window.location.origin, "/").concat(this.get('ticket.locationRef')).concat(this.get('ticket.getParamSchedules') ? "?".concat(this.get('ticket.getParamSchedules')) : ''));
      }
    }
  });

  _exports.default = _default;
});