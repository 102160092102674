define("lh-public-olo/templates/components/items-removed-warning", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "aogTMvey",
    "block": "[[[1,[52,[28,[37,1],[[33,2],1],null],\"An item was\",\"Some items were\"]],[1,\" removed from your cart because \"],[1,[52,[28,[37,1],[[33,2],1],null],\"it was\",\"they were\"]],[1,\" no longer available. We apologize for the inconvenience.\\n\"]],[],false,[\"if\",\"eq\",\"number\"]]",
    "moduleName": "lh-public-olo/templates/components/items-removed-warning.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});