define("lh-public-olo/components/user-account", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/runloop"], function (_exports, _component, _service, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['user-account'],
    session: (0, _service.inject)(),
    router: (0, _service.inject)(),
    ticket: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    refreshDropdown: false,
    triggerRefreshDropdown: (0, _object.observer)('customerService.username', function () {
      var _this = this;

      this.set('refreshDropdown', true);
      (0, _runloop.next)(function () {
        return _this.set('refreshDropdown', false);
      });
    }),
    route: (0, _object.computed)('router.currentRouteName', function () {
      if (this.get('router.currentRouteName').includes('checkout')) {
        return 'location.checkout.account.entry';
      }

      return 'location.menu.account.entry';
    }),
    actions: {
      logout: function logout() {
        this.session.invalidate();
      },
      onShow: function onShow() {
        this.set('isCartHidden', this.hideCart);
      }
    }
  });

  _exports.default = _default;
});