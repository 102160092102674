define("lh-public-olo/routes/location/menu/edit-item", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model: function model(params) {
      var cartItem = this.store.peekRecord('cart-item', params.cartItemId);

      if (!cartItem) {
        return this.transitionTo('location');
      }

      return (0, _rsvp.hash)({
        item: this.store.findRecord('item', cartItem.get('item.id'), {
          reload: true
        }),
        cartItem: cartItem
      });
    }
  });

  _exports.default = _default;
});