define("lh-public-olo/components/account/sign-up-view", ["exports", "@ember/component", "@ember/service", "ember-cp-validations", "lh-public-olo/utils/validation-check", "rsvp"], function (_exports, _component, _service, _emberCpValidations, _validationCheck, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: [(0, _emberCpValidations.validator)('length', {
      max: 30,
      description: 'First Name'
    })],
    lastName: [(0, _emberCpValidations.validator)('length', {
      max: 30,
      description: 'Last Name'
    })],
    email: {
      description: 'Email Address',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        minTldLength: 2
      })]
    },
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Password'
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 128,
      description: 'Password'
    })]
  });

  var _default = _component.default.extend(Validations, {
    classNames: ['user-account-view'],
    isLoading: false,
    showErrors: false,
    session: (0, _service.inject)(),
    createAccount: function createAccount() {
      this.set('isLoading', true);
      var customer = {
        firstName: this.firstName && this.firstName.trim() || undefined,
        lastName: this.lastName && this.lastName.trim() || undefined,
        email: this.email,
        password: this.password
      };
      return this.session.authenticate('authenticator:jwt-register', customer).catch(function (e) {
        var _e$json$errors = _slicedToArray(e.json.errors, 1),
            error = _e$json$errors[0];

        var registerError = error ? error.friendlyError : 'Unknown error';
        return (0, _rsvp.reject)({
          registerError: registerError
        });
      });
    },
    actions: {
      createAccount: function createAccount() {
        var _this = this;

        if (this.isLoading) {
          return;
        }

        this.setProperties({
          showErrors: false,
          registerError: undefined
        });
        this.validate().then(_validationCheck.default).then(function () {
          return _this.createAccount();
        }).catch(function (_ref) {
          var validations = _ref.validations,
              registerError = _ref.registerError;

          _this.setProperties({
            showErrors: validations,
            registerError: registerError
          });
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      close: function close() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});