define("lh-public-olo/validators/phone-number", ["exports", "ember-cp-validations/validators/base", "libphonenumber"], function (_exports, _base, _libphonenumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var phoneUtil = _libphonenumber.default.PhoneNumberUtil.getInstance();

  var PhoneNumber = _base.default.extend({
    validate: function validate(value, options) {
      if (options && options.allowBlank && !value) {
        return true;
      }

      try {
        return phoneUtil.isValidNumber(phoneUtil.parse(value, options.countryCode)) ? true : this.createErrorMessage('invalid', value, options);
      } catch (error) {
        return this.createErrorMessage('invalid', value, options);
      }
    }
  });

  var _default = PhoneNumber;
  _exports.default = _default;
});