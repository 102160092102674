define("lh-public-olo/adapters/payment-method", ["exports", "lh-public-olo/adapters/application", "lh-public-olo/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.oocHost,
    namespace: "online-ordering/mars/api/v2",
    generateIdForRecord: function generateIdForRecord() {
      return;
    },
    pathForType: function pathForType() {
      return 'customer/payment-methods';
    }
  });

  _exports.default = _default;
});