define("lh-public-olo/components/store-closure-modal", ["exports", "@ember/service", "@ember/component", "@ember/object"], function (_exports, _service, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['store-closure-modal'],
    media: (0, _service.inject)(),
    ticket: (0, _service.inject)(),
    storeClosureMessage: (0, _object.computed)('location.isClosed', 'activeStoreClosureMessage', 'location.storeClosureMessage', 'isStoreClosureActive', function () {
      if (this.isStoreClosureActive && this.activeStoreClosureMessage) {
        return this.activeStoreClosureMessage;
      }

      return this.get('location.storeClosureMessage');
    }),
    didInsertElement: function didInsertElement() {
      this.$('.modal').modal('show');
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.modal').modal('hide');
    }
  });

  _exports.default = _default;
});