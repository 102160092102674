define("lh-public-olo/adapters/loyalty/program", ["exports", "@ember/service", "lh-public-olo/config/environment", "lh-public-olo/adapters/application"], function (_exports, _service, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    ticket: (0, _service.inject)(),
    host: _environment.default.oocHost,
    namespace: 'online-ordering/mars/api/v2/stores',
    urlForFindAll: function urlForFindAll() {
      var baseUrl = this.buildURL();
      var location = this.get('ticket.locationRef');
      var path = this.pathForType('loyalty/program');
      return "".concat(baseUrl, "/").concat(location, "/").concat(path);
    }
  });

  _exports.default = _default;
});