define("lh-public-olo/utils/validation-check", ["exports", "rsvp"], function (_exports, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validationCheck;

  /**
   * Returns based on whether or not validations are valid
   *
   * @method    validationCheck
   * @param     {Object} validations ember-cp-validations object
   * @returns   {Object|RSVP.Promise} The validations if they're valid, otherwise a rejected Promise
   */
  function validationCheck(_ref) {
    var validations = _ref.validations;

    if (validations.get('isValid')) {
      return validations;
    }

    return _rsvp.reject.apply(void 0, arguments);
  }
});