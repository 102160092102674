define("lh-public-olo/routes/location/menu/account/update-password", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    queryParams: {
      token: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var token = _ref.token;
      return token;
    }
  });

  _exports.default = _default;
});