define("lh-public-olo/components/error-popup", ["exports", "@ember/component", "jquery"], function (_exports, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['ui', 'error', 'popup'],
    showOnInsert: true,
    popup: undefined,
    didInsertElement: function didInsertElement() {
      var popup = (0, _jquery.default)(this.popupTarget).popup({
        position: this.position || 'top right',
        popup: this.$(),
        on: 'click',
        lastResort: 'top right'
      });

      if (this.showOnInsert) {
        popup.popup('show');
      }

      this.set('popup', popup);
    },
    willDestroyElement: function willDestroyElement() {
      this.popup.popup('destroy');
    }
  });

  _exports.default = _default;
});