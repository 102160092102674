define("lh-public-olo/serializers/discount", ["exports", "@ember-data/serializer/rest", "@ember/polyfills"], function (_exports, _rest, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    primaryKey: 'discountRef',
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      (0, _polyfills.assign)(hash, this.serialize(record, options));
    }
  });

  _exports.default = _default;
});