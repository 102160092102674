define("lh-public-olo/components/order-type-modal", ["exports", "@ember/object", "@ember/service", "@ember/component", "rsvp", "ember-cp-validations", "lh-public-olo/utils/validation-check", "@ember/runloop", "jquery", "lh-public-olo/utils/time-util", "lh-public-olo/utils/country-regions", "@datadog/browser-logs"], function (_exports, _object, _service, _component, _rsvp, _emberCpValidations, _validationCheck, _runloop, _jquery, _timeUtil, _countryRegions, _browserLogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    orderTypeGuid: (0, _emberCpValidations.validator)('presence', true)
  });
  var AddressValidations = (0, _emberCpValidations.buildValidations)({
    'address.addressLine1': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Address Line 1'
    }),
    'address.city': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'City'
    }),
    'address.state': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: (0, _object.computed)('model.customerService.countryCode', function () {
        return (0, _countryRegions.getRegionLabel)(this.get('model.customerService.countryCode'));
      })
    }),
    'address.zip': {
      description: 'Postal Code',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('postal-code', {
        allowBlank: true,
        countryCode: (0, _object.computed)('model.customerService.countryCode', function () {
          return this.get('model.customerService.countryCode');
        })
      })]
    }
  }, {
    disabled: (0, _object.computed)('model.orderType.requiresAddress', 'model.customerService.selectedDeliveryAddress.isNew', function () {
      if (this.get('model.ticket.isRememberCustomerAccountInfoFeatureEnabled')) {
        return !(this.get('model.orderType.requiresAddress') && this.get('model.customerService.selectedDeliveryAddress.isNew'));
      }

      return !this.get('model.orderType.requiresAddress');
    })
  });
  var daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  var _default = _component.default.extend(Validations, AddressValidations, {
    classNames: ['order-settings-modal'],
    ticket: (0, _service.inject)(),
    media: (0, _service.inject)(),
    store: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    session: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    addressType: 'initial',
    scheduleInitiated: false,
    isServedByDDDOrDSP: (0, _object.computed)('orderType.deliveryServedWithDSP', 'orderType.isServedByDoorDashDrive', function () {
      return this.get('ticket').isServedByDDDOrDSP(this.get('orderType'));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        orderTypeGuid: this.get('ticket.orderTypeGuid') || this.get('orderTypes.firstObject.id'),
        isDeferred: this.get('ticket.isDeferred'),
        expectedTimeUtc: this.get('ticket.expectedTimeUtc'),
        customerSelectedDay: this.get('ticket.customerSelectedDay')
      });

      if (!this.get('address')) {
        _browserLogs.datadogLogs.logger.info('Order type previous address value: ', this.get('address'));
      }

      if (!this.get('ticket.isRememberCustomerAccountInfoFeatureEnabled') || !this.get('session.isAuthenticated')) {
        if (!this.get('customer.deliveryAddresses.length') && !this.get('customerService.selectedDeliveryAddress')) {
          this.set('addressType', 'newAddress');
          this.set('address', this.store.createRecord('delivery-address'));
          this.set('customerService.selectedDeliveryAddress', this.address);
        } else if (this.get('customerService.selectedDeliveryAddress')) {
          this.set('addressType', 'selectedDeliveryAddress');
          this.set('address', this.get('customerService.selectedDeliveryAddress'));
        } else {
          this.set('addressType', 'defaultAddress');
          this.set('address', this.get('customer.deliveryAddresses.firstObject'));
          this.set('customerService.selectedDeliveryAddress', this.address);
        }

        if (!this.get('address')) {
          _browserLogs.datadogLogs.logger.info('Order type address is set to null:', '-', this.get('addressType'), 'Order type address value:', '-', this.get('address'), 'Order type selectedDeliveryAddress value', '-', this.get('customerService.selectedDeliveryAddress'));
        }
      }

      this.set('regionOptions', (0, _countryRegions.getRegions)(this.get('location.countryCode')));
      this.set('regionLabel', (0, _countryRegions.getRegionLabel)(this.get('location.countryCode')));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$('.modal').modal('show');
      var isDeferred = this.isDeferred;

      if (this.isDeferred === undefined) {
        this.set('isDeferred', false);
      }

      if (this.get('ticket.isDeferredFeatureEnabled')) {
        this.getTimeSlots().then(function () {
          if (isDeferred && !_this.isAnticipatedAvailable || isDeferred === false && !_this.isAsapAvailable) {
            _this.set('scheduleChangedError', 'Your chosen order time is no longer available.');
          }
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.modal').modal('setting', 'onHide', function () {}).modal('hide');
    },
    getTimeSlots: function getTimeSlots() {
      var _this2 = this;

      this.set('loading', true);
      return this.ticket.getAvailableTimeSlots(this.orderType).then(function (timeSlots) {
        _this2.set('timeSlots', timeSlots); // we loaded new time slots so let's do some validations


        if (!_this2.isAsapAvailable && !_this2.isAnticipatedAvailable) {
          // Both ASAP and deferred not available. Show error.
          return (0, _rsvp.reject)("Online ordering not available for ".concat(_this2.get('orderType.name')));
        }

        if (_this2.isAnticipatedAvailable && _this2.expectedTimeUtc) {
          // Expected time already selected. Select the Day/Time dropdown values
          var selectedTimeSlot = _this2.get('timeSlots.futureTimeSlots').find(function (timeSlot) {
            return timeSlot.isAvailable && timeSlot.expectedTime === _this2.expectedTimeUtc;
          });

          if (!selectedTimeSlot) {
            // Last selected expected date not available. Set a new default.
            _this2.set('customerSelectedDay', undefined);

            (0, _runloop.next)(function () {
              return _this2.set('expectedTimeUtc', _this2.get('timeSlots.futureTimeSlots').findBy('isAvailable').expectedTime);
            });
          }
        } // if only one of the options is available, let's select it


        if (!_this2.isAsapAvailable && _this2.isAnticipatedAvailable && !_this2.isDeferred) {
          _this2.set('isDeferred', true);

          _this2.set('expectedTimeUtc', _this2.get('timeSlots.futureTimeSlots').findBy('isAvailable').expectedTime);
        } else if (_this2.isAsapAvailable && !_this2.isAnticipatedAvailable && _this2.isDeferred) {
          _this2.set('isDeferred', false);
        }
      }).catch(function (message) {
        return _this2.set('orderSettingsError', message);
      }).finally(function () {
        return _this2.set('loading', false);
      });
    },
    getCustomerDisplayDay: function getCustomerDisplayDay(applicableDate, expectedTime) {
      return this.ticket.getDisplayDay(new Date(applicableDate) > new Date() && new Date() < new Date(expectedTime) ? applicableDate : new Date());
    },
    clearErrors: function clearErrors() {
      this.setProperties({
        orderSettingsError: null,
        scheduleChangedError: null,
        staticMapUrl: null,
        showErrors: false
      });
      this.set('ticket.storeSettingsChangedError', null);
    },
    orderType: (0, _object.computed)('orderTypeGuid', function () {
      return this.orderTypes.findBy('id', this.orderTypeGuid);
    }),
    isAsapAvailable: (0, _object.computed)('timeSlots.asapTimeSlot.isAvailable', 'ticket.isDeferredFeatureEnabled', function () {
      return this.get('timeSlots.asapTimeSlot.isAvailable') || !this.get('ticket.isDeferredFeatureEnabled');
    }),
    isAnticipatedAvailable: (0, _object.computed)('timeSlots.futureTimeSlots', function () {
      return this.get('timeSlots.futureTimeSlots') && this.get('timeSlots.futureTimeSlots').isAny('isAvailable');
    }),
    selectedDay: (0, _object.computed)('timeSlots.futureTimeSlots.[]', 'expectedTimeUtc', 'customerSelectedDay', function () {
      var _this3 = this;

      if (this.get('customerSelectedDay')) {
        return this.get('customerSelectedDay');
      }

      var getApplicableDates = this.get('timeSlots.futureTimeSlots').filter(function (_ref) {
        var expectedTime = _ref.expectedTime;
        return expectedTime === _this3.expectedTimeUtc;
      });
      return getApplicableDates[0] ? this.getCustomerDisplayDay(getApplicableDates[0].applicableDate, getApplicableDates[0].expectedTime) : this.ticket.getDisplayDay(this.expectedTimeUtc);
    }),
    days: (0, _object.computed)('timeSlots.futureTimeSlots.[]', function () {
      var _this4 = this;

      var days = this.get('timeSlots.futureTimeSlots').map(function (_ref2) {
        var applicableDate = _ref2.applicableDate,
            isAvailable = _ref2.isAvailable,
            expectedTime = _ref2.expectedTime;
        return {
          displayDay: _this4.getCustomerDisplayDay(applicableDate, expectedTime),
          isAvailable: isAvailable
        };
      });
      var uniqueDays = days.uniqBy('displayDay');
      uniqueDays.forEach(function (day) {
        day.isAvailable = days.some(function (_ref3) {
          var displayDay = _ref3.displayDay,
              isAvailable = _ref3.isAvailable;
          return isAvailable && displayDay === day.displayDay;
        });
      });
      return uniqueDays;
    }),
    times: (0, _object.computed)('timeSlots.futureTimeSlots.[]', 'selectedDay', function () {
      var _this5 = this;

      var times = this.get('timeSlots.futureTimeSlots').filter(function (_ref4) {
        var applicableDate = _ref4.applicableDate,
            expectedTime = _ref4.expectedTime;
        return _this5.getCustomerDisplayDay(applicableDate, expectedTime) === _this5.selectedDay;
      });
      var uniqueTimes = times.uniqBy('expectedTime');
      return uniqueTimes.map(function (_ref5) {
        var expectedTime = _ref5.expectedTime,
            isAvailable = _ref5.isAvailable,
            applicableDate = _ref5.applicableDate;
        return {
          expectedTime: expectedTime,
          displayTime: _this5.ticket.getDisplayTime(expectedTime),
          displayDaysName: _this5.ticket.getDisplayDay(expectedTime) !== _this5.getCustomerDisplayDay(applicableDate, expectedTime) ? " (".concat(daysOfWeek[new Date((0, _timeUtil.getAdjustedTime)(_this5.ticket.timeZoneOffset, new Date(expectedTime))).getDay()], ")") : '',
          isAvailable: isAvailable
        };
      });
    }),
    activeStoreClosures: (0, _object.computed)('expectedTimeUtc', 'isDeferred', 'timeSlots.storeClosures.[]', function () {
      if (!this.get('timeSlots.storeClosures')) {
        return [];
      }

      var orderTime = this.isDeferred ? this.expectedTimeUtc : this.get('timeSlots.asapTimeSlot.orderTime');
      var orderDate = new Date(orderTime);
      return this.get('timeSlots.storeClosures').filter(function (closure) {
        var closureDate = new Date(closure.date);
        return closureDate.getUTCFullYear() === orderDate.getUTCFullYear() && closureDate.getUTCMonth() === orderDate.getUTCMonth() && closureDate.getUTCDate() === orderDate.getUTCDate();
      });
    }),
    actions: {
      onImageLoad: function onImageLoad() {
        this.$('.ui.modal').modal('refresh');
        this.$('.content').animate({
          scrollTop: (0, _jquery.default)('.message').offset().top
        }, 200);
      },
      onModalHide: function onModalHide() {
        this.sendAction('close');
      },
      deferredSelect: function deferredSelect(value) {
        this.clearErrors();
        this.set('isDeferred', value); // if there's no time set, let's set a default

        if (value && !this.expectedTimeUtc) {
          this.set('expectedTimeUtc', this.get('timeSlots.futureTimeSlots').findBy('isAvailable').expectedTime);
        }
      },
      orderTypeSelect: function orderTypeSelect(orderTypeGuid) {
        this.clearErrors();
        this.set('orderTypeGuid', orderTypeGuid);

        if (!(this.get('orderType.requiresAddress') && this.get('orderType.deliveryDistanceInMiles'))) {
          this.set('rangeError', false);
        }

        if (this.get('ticket.isDeferredFeatureEnabled')) {
          this.getTimeSlots();
        }
      },
      daySelect: function daySelect(value) {
        var _this6 = this;

        this.clearErrors();
        var firstTimeSlot = this.get('timeSlots.futureTimeSlots').find(function (_ref6) {
          var applicableDate = _ref6.applicableDate,
              isAvailable = _ref6.isAvailable;
          return isAvailable && _this6.ticket.getDisplayDay(applicableDate) === value;
        });
        this.set('expectedTimeUtc', firstTimeSlot.expectedTime);
        this.set('customerSelectedDay', value);
      },
      timeSelect: function timeSelect(value) {
        this.clearErrors();
        this.set('expectedTimeUtc', value);
      },
      save: function save() {
        var _this7 = this;

        if (!this.loading && !this.orderSettingsError) {
          this.setProperties({
            showErrors: false,
            rangeError: false
          });
          this.validate().then(_validationCheck.default).then(function () {
            if (_this7.get('orderType.requiresAddress') && _this7.get('customerService.selectedDeliveryAddress.isNew')) {
              return _this7.get('customerService.selectedDeliveryAddress').validate().then(_validationCheck.default);
            }

            return (0, _rsvp.resolve)();
          }).then(function () {
            if (!_this7.get('orderType.requiresAddress') || _this7.get('orderType.storeDeliveryValidationType') === 'RADIUS' && !_this7.get('orderType.deliveryDistanceInMiles') && !_this7.isServedByDDDOrDSP) {
              return (0, _rsvp.resolve)();
            } // if the order type requires address and has a delivery distance defined
            // validate that the address is within range of that distance


            _this7.set('loading', true);

            return _this7.ticket.validateDistance(_this7.get('customerService.selectedDeliveryAddress'), _this7.orderType).then(function () {
              return _this7.setProperties({
                loading: false,
                staticMapUrl: undefined
              });
            }).catch(function (_ref7) {
              var message = _ref7.message,
                  staticMapUrl = _ref7.staticMapUrl;

              _this7.setProperties({
                loading: false,
                rangeError: "".concat(message, "."),
                staticMapUrl: staticMapUrl
              });

              return (0, _rsvp.reject)();
            });
          }).then(function () {
            _this7.ticket.setOrderSettings({
              orderTypeGuid: _this7.orderTypeGuid,
              isDeferred: _this7.isDeferred,
              expectedTimeUtc: _this7.isDeferred ? _this7.expectedTimeUtc : null,
              customerSelectedDay: _this7.customerSelectedDay || _this7.selectedDay
            });

            _this7.set('ticket.orderType', _this7.orderType);

            _this7.clearErrors();

            _this7.ticket.calculate();

            _this7.sendAction('save', function () {
              //failed because selected deferred order time no longer fits the schedule,
              // refresh the available time slots
              _this7.getTimeSlots();

              _this7.set('scheduleInitiated', true);
            });
          }).catch(function () {
            return _this7.set('showErrors', true);
          });
        }
      },
      initiateScheduleSelect: function initiateScheduleSelect() {
        var _this8 = this;

        if (this.loading || this.orderSettingsError) {
          return;
        }

        if (!this.loading && !this.orderSettingsError) {
          this.set('loading', true);
          this.setProperties({
            showErrors: false,
            rangeError: false
          });
          this.validate().then(_validationCheck.default).then(function () {
            if (_this8.get('orderType.requiresAddress') && _this8.get('customerService.selectedDeliveryAddress.isNew')) {
              return _this8.get('customerService.selectedDeliveryAddress').validate().then(_validationCheck.default);
            }

            return (0, _rsvp.resolve)();
          }).then(function () {
            if (!_this8.get('orderType.requiresAddress') || _this8.get('orderType.storeDeliveryValidationType') === 'RADIUS' && !_this8.get('orderType.deliveryDistanceInMiles') && !_this8.isServedByDDDOrDSP) {
              return (0, _rsvp.resolve)();
            }

            return _this8.ticket.validateDistance(_this8.get('customerService.selectedDeliveryAddress'), _this8.orderType).then(function () {
              return _this8.setProperties({
                staticMapUrl: undefined
              });
            }).catch(function (_ref8) {
              var message = _ref8.message,
                  staticMapUrl = _ref8.staticMapUrl;

              _this8.setProperties({
                rangeError: "".concat(message, "."),
                staticMapUrl: staticMapUrl
              });

              return (0, _rsvp.reject)();
            });
          }).then(function () {
            _this8.getTimeSlots();

            _this8.set('scheduleInitiated', true);
          }).finally(function () {
            _this8.set('loading', false);
          }).catch(function () {
            return _this8.set('showErrors', true);
          });
        }
      }
    }
  });

  _exports.default = _default;
});