define("lh-public-olo/templates/location/checkout", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5RIrWghB",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@loyaltyProgram\",\"@location\",\"@customer\",\"@tenders\",\"@paymentMethod\",\"@showSoldOutWarningModal\",\"@submitResponse\"],[\"order-checkout\",[33,1,[\"loyaltyProgram\"]],[33,1,[\"location\"]],[33,1,[\"customer\"]],[33,1,[\"tenders\"]],[33,1,[\"paymentMethods\",\"firstObject\"]],[28,[37,2],[[30,0],\"showSoldOutWarningModal\"],null],[28,[37,2],[[30,0],\"submitResponse\"],null]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@named\",\"@send\"],[\"order-details\",[28,[37,4],null,[[\"orderTypeDetails\",\"removeItem\"],[true,[28,[37,2],[[30,0],\"removeItem\"],null]]]]]],null],[1,\"\\n\\n\"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"order-checkout\",\"model\",\"action\",\"to-elsewhere\",\"hash\",\"component\",\"-outlet\"]]",
    "moduleName": "lh-public-olo/templates/location/checkout.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});