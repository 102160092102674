define("lh-public-olo/components/payment-methods", ["exports", "@ember/component", "@ember/service", "lh-public-olo/utils/general-error-response-parser"], function (_exports, _component, _service, _generalErrorResponseParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    customerService: (0, _service.inject)('customer'),
    deletePaymentMethodError: undefined,
    loading: {},
    actions: {
      deletePaymentMethod: function deletePaymentMethod(paymentMethod) {
        var _this = this;

        if (this.get("loading.".concat(paymentMethod.id))) {
          return;
        }

        this.set("loading.".concat(paymentMethod.id), true);
        this.set('deletePaymentMethodError', undefined);
        paymentMethod.destroyRecord().catch(function (e) {
          var error = (0, _generalErrorResponseParser.default)(e.errors);

          _this.set('deletePaymentMethodError', error.friendlyError || 'Something went wrong, please try again');
        }).finally(function () {
          _this.set("loading.".concat(paymentMethod.id), false);
        });
      }
    }
  });

  _exports.default = _default;
});