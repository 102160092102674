define("lh-public-olo/templates/components/menu-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "P6TzXv68",
    "block": "[[[8,[39,0],null,[[\"@route\",\"@model\",\"@disabled\"],[\"location.menu.add-item\",[33,1,[\"id\"]],[99,2,[\"@disabled\"]]]],[[\"default\"],[[[[1,\"\\n\\t\"],[10,0],[14,0,\"menu-item\"],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"info\"],[12],[1,\"\\n\\t\\t\\t\"],[10,\"h4\"],[14,0,\"ui header\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[10,1],[12],[1,[33,1,[\"name\"]]],[13],[1,\"\\n\"],[41,[51,[28,[37,4],[[33,1,[\"description\"]],[33,1,[\"name\"]]],null]],[[[1,\"\\t\\t\\t\\t\\t\"],[10,0],[14,0,\"sub header\"],[12],[1,[33,1,[\"description\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"item-details-footer\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[10,1],[14,0,\"price\"],[12],[1,[28,[35,5],[[28,[37,6],[[33,1,[\"onlinePrice\"]],[33,1,[\"price\"]]],null]],null]],[13],[1,\"\\n\"],[41,[28,[37,8],[[33,9,[\"isQuantityOnHandFeatureEnabled\"]],[33,1,[\"stockStatus\"]]],null],[[[1,\"\\t\\t\\t\\t\\t\"],[10,1],[15,0,[29,[[52,[33,1,[\"isItemUnavailable\"]],\"sold-out\",\"almost-sold-out\"]]]],[15,\"aria-disabled\",[29,[[28,[37,10],[[33,1,[\"isItemUnavailable\"]]],null]]]],[12],[1,[28,[35,11],[[33,1,[\"stockStatus\"]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\"],[41,[28,[37,8],[[33,9,[\"isItemImagesFeatureEnabled\"]],[33,1,[\"imageUrl800x800\"]]],null],[[[1,\"\\t\\t\\t\"],[10,\"img\"],[14,0,\"ui image\"],[15,\"src\",[29,[[33,1,[\"imageUrl800x800\"]]]]],[15,\"alt\",[33,1,[\"name\"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\t\"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"link-to\",\"item\",\"disabled\",\"unless\",\"eq\",\"currency-format\",\"or\",\"if\",\"and\",\"ticket\",\"not\",\"stock-status\"]]",
    "moduleName": "lh-public-olo/templates/components/menu-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});