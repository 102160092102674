define("lh-public-olo/serializers/loyalty/member", ["exports", "@ember-data/serializer/rest", "@ember/polyfills"], function (_exports, _rest, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    primaryKey: 'guid',
    // removes root property that ember automatically adds
    // ie/ its doing this { loyalty/member: {firstName } }
    // whereas, we want { firstName }
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      (0, _polyfills.assign)(hash, this.serialize(record, options));
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'member') {
        return this._super('loyalty/member');
      }

      return this._super(payloadKey);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.id;
      delete json.guid;
      delete json.pointsBalance;
      return json;
    }
  });

  _exports.default = _default;
});