define("lh-public-olo/models/cart-item", ["exports", "@ember-data/model", "@ember/object", "@ember/object/computed"], function (_exports, _model, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    locationRef: (0, _model.attr)('string'),
    itemRef: (0, _model.belongsTo)('item'),
    quantity: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    specialRequest: (0, _model.attr)('string'),
    item: (0, _computed.alias)('itemRef'),
    subtotal: (0, _object.computed)('item.price', 'quantity', 'modifierTotal', function () {
      return (parseFloat(this.get('item.price')) + this.modifierTotal) * this.quantity;
    }),
    modifierTotal: (0, _object.computed)('modifiers.[]', 'modifiers.@each.subtotal', function () {
      return this.modifiers.reduce(function (prev, curr) {
        return prev + curr.get('subtotal');
      }, 0);
    }),
    modifiers: (0, _model.hasMany)('cart-item-modifier', {
      async: false
    })
  });

  _exports.default = _default;
});