define("lh-public-olo/controllers/location/menu/edit-item", ["exports", "@ember/controller", "lh-public-olo/mixins/item-selection"], function (_exports, _controller, _itemSelection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_itemSelection.default, {
    actions: {
      cancelOrder: function cancelOrder() {
        var cartItem = this.get('model.cartItem');
        cartItem.rollbackAttributes(); // delete any modifiers that have been newly created

        cartItem.get('modifiers').filterBy('isNew').forEach(function (modifier) {
          return modifier.unloadRecord();
        }); // refresh cart item from local storage

        this.store.findRecord('cart-item', cartItem.get('id'));

        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});