define("lh-public-olo/initializers/datadog-logger", ["exports", "ember", "@datadog/browser-logs", "lh-public-olo/config/environment"], function (_exports, _ember, _browserLogs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * Datadog log initializer
   *
   * Capture both ember and global console errors and forward them to Datadog
   */
  function initialize() {
    var datadogConfig = _environment.default.datadog;

    if (!datadogConfig.enabled) {
      return;
    }

    _browserLogs.datadogLogs.init({
      clientToken: datadogConfig.clientToken,
      site: datadogConfig.site,
      forwardErrorsToLogs: datadogConfig.forwardErrorsToLogs,
      sessionSampleRate: datadogConfig.sessionSampleRate,
      service: datadogConfig.service,
      env: datadogConfig.env
    });

    _browserLogs.datadogLogs.setGlobalContext(datadogConfig.tags);

    _ember.default.onerror = function (error) {
      var emberError = "'Ember error:' ".concat(error);

      _browserLogs.datadogLogs.logger.error(emberError);

      throw error;
    };

    window.onerror = function (message, source, lineno, colno, error) {
      var globalError = "'Global error:' ".concat(JSON.stringify({
        message: message,
        source: source,
        lineno: lineno,
        colno: colno,
        error: error
      }));

      _browserLogs.datadogLogs.logger.error(globalError);
    };
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});