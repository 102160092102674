define("lh-public-olo/components/order-details", ["exports", "@ember/object", "@ember/service", "@ember/component", "jquery"], function (_exports, _object, _service, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    ticket: (0, _service.inject)(),
    showOnlySurchargeTotal: (0, _object.computed)('ticket.calculations.surcharges', 'ticket.calculations.surcharges.[]', function () {
      // show total surcharges because of the bug in surcharges LH-12466
      return this.get('ticket.calculations.surcharges') && this.get('ticket.calculations.surcharges').some(function (surcharge) {
        return surcharge.name === 'None';
      });
    }),
    actions: {
      removeItem: function removeItem(item) {
        item.get('modifiers').toArray().forEach(function (mod) {
          return mod.unloadRecord();
        });
        item.destroyRecord();
        this.get('ticket.items').removeObject(item);

        if (this.get('ticket.orderTypeGuid')) {
          this.ticket.calculate();
        }

        if (this.removeItem) {
          this.removeItem();
        }
      },
      closeDetails: function closeDetails() {
        (0, _jquery.default)('.order.column').removeClass('open');
        (0, _jquery.default)('main > .column:first-child').dimmer({
          onHide: function onHide() {}
        }).dimmer('hide');
      }
    }
  });

  _exports.default = _default;
});