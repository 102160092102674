define("lh-public-olo/helpers/error-message", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.errorMessage = errorMessage;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var ERROR_MESSAGES = {
    LODINC: 'Ordering has been temporarily disabled, please place your order with a staff member.' + ' We apologize for the inconvenience',
    LOCINA: 'Ordering has been temporarily disabled. We apologize for the inconvenience',
    SCHDNONEAVAIL: "The location doesn't have an active Schedule",
    COMMONERROR: 'We are currently unable to load the requested information. Please try refreshing the page or check back later.' + ' We apologize for any inconvenience this may cause.',
    FORBIDDEN: 'Our services are not available in your region based on your IP address.'
  };
  var USER_DISPLAYABLE_ERROR_CODES = ['POS_CONFIGURATION'];

  function errorMessage(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        error = _ref2[0];

    if (ERROR_MESSAGES[error.code]) {
      return "".concat(ERROR_MESSAGES[error.code], ".");
    }

    if (USER_DISPLAYABLE_ERROR_CODES.includes(error.code)) {
      return "".concat(error.message, "\nError Code: ").concat(error.code);
    }

    if (error.friendlyError) {
      return "".concat(error.friendlyError, ".");
    }

    if (error.status === '403') {
      return ERROR_MESSAGES.FORBIDDEN;
    }

    if (!error.message) {
      return ERROR_MESSAGES.COMMONERROR;
    }

    return "".concat(error.status, " - ").concat(error.message, ".");
  }

  var _default = (0, _helper.helper)(errorMessage);

  _exports.default = _default;
});