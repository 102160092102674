define("lh-public-olo/templates/components/user-account", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZmE6+0Hr",
    "block": "[[[41,[28,[37,1],[[33,2,[\"isAuthenticated\"]],[28,[37,3],[[33,4]],null]],null],[[[1,\"\\t\"],[8,[39,5],null,[[\"@action\",\"@onShow\"],[\"hide\",[28,[37,6],[[30,0],\"onShow\"],null]]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[10,0],[14,0,\"default text\"],[12],[1,[33,7,[\"username\"]]],[13],[1,\"\\n\\t\\t\"],[10,\"i\"],[14,0,\"chevron down icon\"],[12],[13],[1,\"\\n\\t\\t\"],[10,0],[15,0,[29,[\"menu \",[52,[33,8],\"cart-hidden\",\"cart-visible\"]]]],[12],[1,\"\\n\"],[41,[33,9,[\"isAccountManageFeatureEnabled\"]],[[[1,\"\\t\\t\\t\\t\"],[10,0],[14,0,\"item\"],[12],[8,[39,10],null,[[\"@route\",\"@query\"],[\"location.user-profile\",[28,[37,11],null,[[\"previous\"],[[33,12,[\"currentRouteName\"]]]]]]],[[\"default\"],[[[[1,\"Edit Profile\"]],[]]]]],[13],[1,\"\\n\\t\\t\\t\\t\"],[10,0],[14,0,\"ui divider\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\t\\t\\t\"],[11,0],[24,0,\"item\"],[4,[38,6],[[30,0],\"logout\"],null],[12],[1,\"Logout\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"\\t\"],[8,[39,10],[[24,0,\"sign-in\"]],[[\"@route\"],[[99,13,[\"@route\"]]]],[[\"default\"],[[[[1,\"\\n\\t\\tSign In\\n\\t\"]],[]]]]],[1,\"\\n\\t\"],[8,[39,10],[[24,0,\"sign-up\"]],[[\"@route\",\"@query\"],[[99,13,[\"@route\"]],[28,[37,11],null,[[\"signUpTab\"],[true]]]]],[[\"default\"],[[[[1,\"\\n\\t\\tSign Up\\n\\t\"]],[]]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"and\",\"session\",\"not\",\"refreshDropdown\",\"ui-dropdown\",\"action\",\"customerService\",\"isCartHidden\",\"ticket\",\"link-to\",\"hash\",\"router\",\"route\"]]",
    "moduleName": "lh-public-olo/templates/components/user-account.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});