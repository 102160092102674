define("lh-public-olo/templates/location/user-profile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OSp7KHey",
    "block": "[[[8,[39,0],null,[[\"@named\",\"@send\"],[\"navigation\",[50,\"back-to-menu\",0,null,[[\"name\",\"link\"],[[33,2,[\"name\"]],[33,2,[\"route\"]]]]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@named\",\"@send\"],[\"sidebar\",[28,[37,3],null,[[\"hide\"],[true]]]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"user-profile menu-column\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"ui centered grid\"],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"twelve wide computer sixteen wide column\"],[12],[1,\"\\n\\t\\t\\t\"],[10,\"h1\"],[14,0,\"ui header\"],[12],[1,\"User Profile\"],[13],[1,\"\\n\\n\\t\\t\\t\"],[10,\"h2\"],[14,0,\"ui header menu-header checkout-header\"],[12],[1,\"General\"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"ui divider\"],[12],[13],[1,\"\\n\\t\\t\\t\"],[8,[39,4],null,[[\"@customer\"],[[33,5,[\"customer\"]]]],null],[1,\"\\n\\n\\t\\t\\t\"],[10,\"h2\"],[14,0,\"ui header menu-header checkout-header\"],[12],[1,\"Security\"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"ui divider security-section\"],[12],[13],[1,\"\\n\\t\\t\\t\"],[8,[39,6],null,null,null],[1,\"\\n\\n\\t\\t\\t\"],[10,\"h2\"],[14,0,\"ui header menu-header checkout-header\"],[12],[1,\"Addresses\"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"ui divider\"],[12],[13],[1,\"\\n\\t\\t\\t\"],[8,[39,7],null,[[\"@customer\"],[[33,5,[\"customer\"]]]],null],[1,\"\\n\\n\\t\\t\\t\"],[10,\"h2\"],[14,0,\"ui header menu-header checkout-header\"],[12],[1,\"Payment Methods\"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"ui divider\"],[12],[13],[1,\"\\n\\t\\t\\t\"],[8,[39,8],null,[[\"@paymentMethods\"],[[33,5,[\"paymentMethods\"]]]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"to-elsewhere\",\"component\",\"previousRoute\",\"hash\",\"general-user-details\",\"model\",\"change-password\",\"delivery-address-details\",\"payment-methods\"]]",
    "moduleName": "lh-public-olo/templates/location/user-profile.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});