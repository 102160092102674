define("lh-public-olo/enums/country-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COUNTRY_CODE = Object.freeze({
    US: 'US',
    CA: 'CA',
    VI: 'VI'
  });
  var _default = COUNTRY_CODE;
  _exports.default = _default;
});