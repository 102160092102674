define("lh-public-olo/components/order-info", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['order-info'],
    ticket: (0, _service.inject)()
  });

  _exports.default = _default;
});