define("lh-public-olo/templates/components/general-user-details", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lGplS008",
    "block": "[[[10,0],[14,0,\"ui form checkout\"],[12],[1,\"\\n\\t\"],[8,[39,0],null,[[\"@orderType\",\"@specialRequest\",\"@customer\",\"@showErrors\",\"@componentType\",\"@countryCode\"],[[33,1,[\"orderType\"]],[99,2,[\"@specialRequest\"]],[99,3,[\"@customer\"]],true,\"user-profile\",\"US\"]],null],[1,\"\\n\\n\"],[41,[33,5],[[[1,\"\\t\\t\"],[10,0],[14,0,\"ui negative message\"],[12],[1,\"\\n\\t\\t\\t\"],[10,2],[12],[1,[34,5]],[1,\".\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,3,[\"hasDirtyAttributes\"]],[[[1,\"\\t\\t\"],[10,0],[14,0,\"field\"],[12],[1,\"\\n\\t\\t\\t\"],[11,\"button\"],[16,0,[29,[\"ui button primary \",[52,[33,6],\"loading\"]]]],[4,[38,7],[[30,0],\"savePersonalInfo\"],null],[12],[1,\"Save\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\n\"],[41,[33,8],[[[1,\"\\t\\t\\t\"],[8,[39,9],null,[[\"@position\",\"@popupTarget\"],[\"top left\",\".general-user-details .ui.button\"]],[[\"default\"],[[[[1,\"There are errors that need to be resolved.\"]],[]]]]],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"customer-info\",\"ticket\",\"specialRequest\",\"customer\",\"if\",\"savePersonalInfoErrors\",\"isLoading\",\"action\",\"showErrors\",\"error-popup\"]]",
    "moduleName": "lh-public-olo/templates/components/general-user-details.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});