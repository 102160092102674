define("lh-public-olo/utils/mask-card-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = maskCardNumber;

  /**
   * Mask a given card number to show only the last 4 digits.
   *
   * This function replaces all characters in the provided card number except for the last four digits with 'X's.
   * Length of the returned masked card number will be the same as the length of the provided card number.
   * If the card number is null, undefined, or has 4 or fewer characters, it returns the card number unchanged.
   *
   * @method     maskCardNumber
   * @param      {String} cardNumber - Card number to mask
   * @returns    {String} Masked card number
   */
  function maskCardNumber(cardNumber) {
    if (!cardNumber || cardNumber.length <= 4) {
      return cardNumber;
    }

    var maskedSection = 'X'.repeat(cardNumber.length - 4);
    var lastFour = cardNumber.slice(-4);
    return maskedSection + lastFour;
  }
});