define("lh-public-olo/components/order-details/ticket-item/modifier-set", ["exports", "@ember/object/computed", "@ember/service", "@ember/component"], function (_exports, _computed, _service, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'tbody',
    store: (0, _service.inject)(),
    modifiers: (0, _computed.filter)('item.modifiers', function (mod) {
      return mod.get('modifierSetRef') === this.get('set.id');
    })
  });

  _exports.default = _default;
});