define("lh-public-olo/templates/components/page-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Tje3H/cX",
    "block": "[[[1,\" \"],[10,0],[15,0,[29,[\"ui inverted segment page-message \",[52,[28,[37,1],[[33,2],\"error\"],null],\"red\",\"warning\"]]]],[12],[1,\"\\n    \"],[1,[34,3]],[1,\"\\n\"],[13]],[],false,[\"if\",\"eq\",\"type\",\"message\"]]",
    "moduleName": "lh-public-olo/templates/components/page-message.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});