define("lh-public-olo/templates/components/account/account-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iin7X8qh",
    "block": "[[[8,[39,0],null,[[\"@autofocus\",\"@duration\",\"@onHide\"],[false,200,[28,[37,1],[[30,0],\"onModalHide\"],null]]],[[\"default\"],[[[[1,\"\\n\\t\"],[10,\"i\"],[14,0,\"close icon\"],[12],[13],[1,\"\\n\\t\"],[10,0],[14,0,\"header\"],[12],[1,\"\\n\\t\"],[13],[1,\"\\n\\t\"],[10,0],[14,0,\"scrolling content\"],[12],[1,\"\\n\\t\\t\"],[18,1,null],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"ui-modal\",\"action\",\"yield\"]]",
    "moduleName": "lh-public-olo/templates/components/account/account-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});