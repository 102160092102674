define("lh-public-olo/services/payment", ["exports", "@ember/service", "rsvp", "lh-public-olo/config/environment"], function (_exports, _service, _rsvp, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    ajax: (0, _service.inject)(),
    ticket: (0, _service.inject)(),
    featureFlag: (0, _service.inject)(),

    /**
     * Authorize IP address to allow card tokenization request
     * @param {string} customerEmail Customer email address for verification
     * @param {string} captchaResponse Google reCaptcha response token
     * @returns {RSVP.Promise|Error} object has { i4go_accessblock: string, i4go_server: string }
     */
    authorize: function authorize(customerEmail, captchaResponse) {
      var _this = this;

      // TODO: this feature flag was never enabled, as the migration effort for authorization in the OOC was stopped - see LH-13702 and DEVMARKET-3086
      // const url = this.get('featureFlag').isEnabled('skytab-online-ooc-preauthorize')
      // 	? `${ENV.oocHost}/online-ordering/mars/api/v2/stores/${this.get('ticket').locationRef}/pre-authorize`
      // 	: `${ENV.host}/api/v2/public/${this.get('ticket').locationRef}/payment/authorize`;
      var isV3Enabled = this.featureFlag.isEnabled('sto-nested-mods');
      var url = isV3Enabled ? "".concat(_environment.default.host, "/api/v3/public/").concat(this.ticket.locationRef, "/payment/authorize") : "".concat(_environment.default.host, "/api/v2/public/").concat(this.ticket.locationRef, "/payment/authorize");
      var data = {
        cart: this.ticket.getCart(),
        tips: this.get('ticket.tipAmount'),
        captchaResponse: captchaResponse,
        customerEmail: customerEmail
      };

      if (isV3Enabled) {
        data.giftAmount = this.get('ticket.giftCardAppliedAmount') ? this.get('ticket.giftCardAppliedAmount') : 0;
        data.tenderRef = this.get('ticket.tenderTypeGuid');
      }

      return this.ajax.post(url, {
        data: data
      }).then(function (res) {
        if (isV3Enabled && !_this.isValidAuthorizations(res) || !Array.isArray(res) && !_this.isValidAuthorization(res)) {
          return _rsvp.Promise.reject({
            payload: {
              errors: [{
                message: 'Something went wrong, please try again'
              }]
            }
          });
        }

        return res;
      }).catch(function (e) {
        var message = 'Something went wrong, please try again';

        if (e.payload && e.payload.errors && e.payload.errors.length && e.payload.errors[0].friendlyError) {
          message = e.payload.errors[0].friendlyError;
        }

        return _rsvp.Promise.reject({
          payload: {
            errors: [{
              message: message
            }]
          }
        });
      });
    },
    isValidAuthorizations: function isValidAuthorizations(authorizations) {
      var _this2 = this;

      if (!authorizations || !Array.isArray(authorizations) || authorizations.length === 0) {
        return false;
      }

      return authorizations.every(function (auth) {
        return _this2.isValidAuthorization(auth);
      });
    },
    isValidAuthorization: function isValidAuthorization(authorization) {
      if (authorization.accessBlock && authorization.server && authorization.invoiceNumber) {
        return true;
      }

      return false;
    },
    validateIframeResponse: function validateIframeResponse(i4goTokenResponse) {
      return new _rsvp.Promise(function (resolve, reject) {
        if (i4goTokenResponse.i4go_responsecode !== '1' && i4goTokenResponse.i4go_responsecode !== 1) {
          return reject({
            payload: {
              error: {
                message: i4goTokenResponse.i4go_responsetext
              }
            }
          });
        }

        return resolve(i4goTokenResponse);
      });
    }
  });

  _exports.default = _default;
});