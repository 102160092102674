define("lh-public-olo/helpers/masked-gift-card-number", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.maskedGiftCardNumber = maskedGiftCardNumber;
  _exports.default = void 0;

  function maskedGiftCardNumber(params
  /*, hash*/
  ) {
    var giftCardNumber = params[0];

    if (!giftCardNumber) {
      return '';
    }

    return giftCardNumber.length > 4 ? "****".concat(giftCardNumber.slice(giftCardNumber.length - 4)) : "****".concat(giftCardNumber);
  }

  var _default = (0, _helper.helper)(maskedGiftCardNumber);

  _exports.default = _default;
});