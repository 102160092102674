define("lh-public-olo/adapters/cart-item", ["exports", "lh-public-olo/adapters/application", "rsvp", "lh-public-olo/utils/get-local-storage", "lh-public-olo/utils/set-local-storage", "@ember/service"], function (_exports, _application, _rsvp, _getLocalStorage3, _setLocalStorage, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    ticket: (0, _service.inject)(),
    query: function query(store, type, _ref) {
      var location = _ref.location;
      return new _rsvp.Promise(function (resolve) {
        var _getLocalStorage = (0, _getLocalStorage3.default)(location),
            items = _getLocalStorage.items;

        return resolve({
          cartItems: items
        });
      });
    },
    findRecord: function findRecord(store, type, id) {
      var _this = this;

      return new _rsvp.Promise(function (resolve) {
        var location = _this.get('ticket.locationRef');

        var _getLocalStorage2 = (0, _getLocalStorage3.default)(location),
            items = _getLocalStorage2.items;

        return resolve({
          cartItem: items.findBy('ref', id)
        });
      });
    },
    createRecord: function createRecord(store, type, snapshot) {
      var _this2 = this;

      return new _rsvp.Promise(function (resolve) {
        var data = _this2.serialize(snapshot, {
          includeId: true
        });

        var storage = (0, _getLocalStorage3.default)(data.locationRef);
        storage.items.push(data);
        (0, _setLocalStorage.default)(data.locationRef, storage);
        return resolve();
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this3 = this;

      return new _rsvp.Promise(function (resolve) {
        var data = _this3.serialize(snapshot, {
          includeId: true
        });

        var storage = (0, _getLocalStorage3.default)(data.locationRef); // ie11 doesn't support findIndex :(

        var pos = storage.items.indexOf(storage.items.find(function (item) {
          return item.ref === data.ref;
        }));
        storage.items[pos] = data;
        (0, _setLocalStorage.default)(data.locationRef, storage);
        return resolve();
      });
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var _this4 = this;

      return new _rsvp.Promise(function (resolve) {
        var data = _this4.serialize(snapshot, {
          includeId: true
        });

        var storage = (0, _getLocalStorage3.default)(data.locationRef); // remove item from localStorage

        var pos = storage.items.indexOf(storage.items.find(function (item) {
          return item.ref === data.ref;
        }));
        storage.items.splice(pos, 1);
        (0, _setLocalStorage.default)(data.locationRef, storage);
        return resolve();
      });
    }
  });

  _exports.default = _default;
});