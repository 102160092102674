define("lh-public-olo/initializers/datadog-rum-logger", ["exports", "@datadog/browser-rum", "lh-public-olo/config/environment"], function (_exports, _browserRum, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * DatadogRum log initializer
   *
   */
  function initialize() {
    var datadogConfig = _environment.default.datadogRum;

    if (!datadogConfig.enabled) {
      return;
    }

    _browserRum.datadogRum.init({
      applicationId: datadogConfig.applicationId,
      clientToken: datadogConfig.clientToken,
      site: datadogConfig.site,
      service: datadogConfig.service,
      env: datadogConfig.env,
      version: datadogConfig.version,
      sessionSampleRate: datadogConfig.sessionSampleRate,
      sessionReplaySampleRate: datadogConfig.sessionReplaySampleRate,
      trackUserInteractions: datadogConfig.trackUserInteractions,
      trackResources: datadogConfig.trackResources,
      trackLongTasks: datadogConfig.trackLongTasks,
      defaultPrivacyLevel: datadogConfig.defaultPrivacyLevel
    });

    _browserRum.datadogRum.startSessionReplayRecording();
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});