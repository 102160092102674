define("lh-public-olo/utils/time-util", ["exports", "lh-public-olo/enums/country-locales", "lh-public-olo/enums/date-formats"], function (_exports, _countryLocales, _dateFormats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addMinutes = addMinutes;
  _exports.differenceInMinutes = differenceInMinutes;
  _exports.getTimeAsString = getTimeAsString;
  _exports.isSameDay = isSameDay;
  _exports.getDisplayDay = getDisplayDay;
  _exports.getAdjustedTime = getAdjustedTime;
  _exports.convertMinutesToHoursAndMinutes = convertMinutesToHoursAndMinutes;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * This method adds the given minutes to the current time
   *
   * @param {Date} currentDate The current Date.
   * @param {number} minutes Minutes to be added
   * @returns {Date} Updated Time
   */
  function addMinutes(currentDate, minutes) {
    return new Date(currentDate.getTime() + (minutes ? minutes * 60000 : 0));
  }
  /**
   * This method subtracts currentDate from future date to get time difference in minutes
   *
   * @param {Date} currentDate The current date.
   * @param {Date} futureDate Future date
   * @returns {number} Minutes count
   */


  function differenceInMinutes(currentDate, futureDate) {
    return Math.round((new Date(futureDate) - new Date(currentDate || new Date())) / (60 * 1000));
  }
  /**
   * Formats Date to Time and returns it as string;
   *
   * @param {Date} date Date Object
   * @param {string} countryCode Country Code
   * @returns {*} Time as string
   */


  function getTimeAsString(date, countryCode) {
    var _COUNTRY_LOCALES$coun = _slicedToArray(_countryLocales.default[countryCode], 1),
        locale = _COUNTRY_LOCALES$coun[0];

    var formatOptions = _dateFormats.default[locale] || _dateFormats.default.defaultFormat;
    return date.toLocaleTimeString(locale, formatOptions);
  }
  /**
   * Checks if two dates are same day (e.g. today)
   *
   * @param {Date} date Date Object
   * @param {Date} dateRef Date Object to be compared
   * @returns {boolean} boolean
   */


  function isSameDay(date, dateRef) {
    return date.getFullYear() === dateRef.getFullYear() && date.getMonth() === dateRef.getMonth() && date.getDate() === dateRef.getDate();
  }
  /**
   * Get display day (e.g. Today, Tomorrow, Monday, 10/5, etc)
   *
   * @param {Date} time Date Object
   * @param {number=} timeZoneOffset Optional timezone offset of the passed time parameter as number
   * @param {string} countryCode Country Code
   * @returns {string} Day as string
   */


  function getDisplayDay(time, timeZoneOffset, countryCode) {
    var timeNow = timeZoneOffset ? getAdjustedTime(timeZoneOffset) : new Date();
    var timeTomorrow = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate() + 1);

    var _COUNTRY_LOCALES$coun2 = _slicedToArray(_countryLocales.default[countryCode], 1),
        locale = _COUNTRY_LOCALES$coun2[0];

    if (isSameDay(time, timeNow)) {
      return 'Today';
    }

    if (isSameDay(time, timeTomorrow)) {
      return 'Tomorrow';
    }

    return time.toLocaleDateString(locale, {
      weekday: 'long',
      month: 'numeric',
      day: 'numeric'
    });
  }
  /**
   * Get store time for a local time. Current store time is returned if time is not passed.
   *
   * @param {number} timeZoneOffset store time zone offset
   * @param {Date=} time time to be converted to store time. Defaults to current time if not provided
   * @returns {Date} Store time as Date Object
   */


  function getAdjustedTime(timeZoneOffset) {
    var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
    var browserOffset = time.getTimezoneOffset();
    var storeOffset = timeZoneOffset * -60 || 0;
    return new Date(time - (storeOffset - browserOffset) * 60000); // 60000 - milliseconds in minute
  }
  /**
   * This method converts minutes to hours and remaining minutes
   *
   * @param {number} minutes Minutes to be converted
   * @returns {string} Hours and Minutes
   */


  function convertMinutesToHoursAndMinutes(minutes) {
    var hours = Math.floor(minutes / 60);
    var remainingMinutes = minutes % 60;

    if (isNaN(minutes) || minutes < 0) {
      return "Invalid input";
    }

    if (hours === 0) {
      return "".concat(remainingMinutes, " minute").concat(remainingMinutes !== 1 ? 's' : '');
    } else if (remainingMinutes === 0) {
      return "".concat(hours, " hour").concat(hours !== 1 ? 's' : '');
    }

    return "".concat(hours, " hour").concat(hours !== 1 ? 's' : '', " and ").concat(remainingMinutes, " minute").concat(remainingMinutes !== 1 ? 's' : '');
  }
});