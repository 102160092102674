define("lh-public-olo/adapters/delivery-address", ["exports", "lh-public-olo/adapters/application", "lh-public-olo/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.oocHost,
    namespace: "online-ordering/mars/api/v2/customer",
    generateIdForRecord: function generateIdForRecord() {
      return;
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName) {
      return this.buildURL(modelName);
    },
    dataForRequest: function dataForRequest(params) {
      var store = params.store,
          snapshot = params.snapshot,
          type = params.type,
          requestType = params.requestType;

      if (requestType === 'updateRecord') {
        var serializer = store.serializerFor(type.modelName);
        var data = {};
        serializer.serializeIntoHash(data, type, snapshot, {
          includeId: true
        });
        return data;
      }

      return this._super(params);
    }
  });

  _exports.default = _default;
});