define("lh-public-olo/utils/country-regions", ["exports", "lh-public-olo/enums/country-code"], function (_exports, _countryCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRegions = getRegions;
  _exports.getRegionLabel = getRegionLabel;
  _exports.isValidPostalCode = isValidPostalCode;
  _exports.isRegionRequired = isRegionRequired;

  var _countries, _POSTAL_CODE_VALIDATO;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var countries = (_countries = {}, _defineProperty(_countries, _countryCode.default.US, {
    regions: [{
      name: 'Alabama',
      code: 'AL'
    }, {
      name: 'Alaska',
      code: 'AK'
    }, {
      name: 'Arizona',
      code: 'AZ'
    }, {
      name: 'Arkansas',
      code: 'AR'
    }, {
      name: 'California',
      code: 'CA'
    }, {
      name: 'Colorado',
      code: 'CO'
    }, {
      name: 'Connecticut',
      code: 'CT'
    }, {
      name: 'Delaware',
      code: 'DE'
    }, {
      name: 'District of Columbia',
      code: 'DC'
    }, {
      name: 'Florida',
      code: 'FL'
    }, {
      name: 'Georgia',
      code: 'GA'
    }, {
      name: 'Hawaii',
      code: 'HI'
    }, {
      name: 'Idaho',
      code: 'ID'
    }, {
      name: 'Illinois',
      code: 'IL'
    }, {
      name: 'Indiana',
      code: 'IN'
    }, {
      name: 'Iowa',
      code: 'IA'
    }, {
      name: 'Kansas',
      code: 'KS'
    }, {
      name: 'Kentucky',
      code: 'KY'
    }, {
      name: 'Louisiana',
      code: 'LA'
    }, {
      name: 'Maine',
      code: 'ME'
    }, {
      name: 'Maryland',
      code: 'MD'
    }, {
      name: 'Massachusetts',
      code: 'MA'
    }, {
      name: 'Michigan',
      code: 'MI'
    }, {
      name: 'Minnesota',
      code: 'MN'
    }, {
      name: 'Mississippi',
      code: 'MS'
    }, {
      name: 'Missouri',
      code: 'MO'
    }, {
      name: 'Montana',
      code: 'MT'
    }, {
      name: 'Nebraska',
      code: 'NE'
    }, {
      name: 'Nevada',
      code: 'NV'
    }, {
      name: 'New Hampshire',
      code: 'NH'
    }, {
      name: 'New Jersey',
      code: 'NJ'
    }, {
      name: 'New Mexico',
      code: 'NM'
    }, {
      name: 'New York',
      code: 'NY'
    }, {
      name: 'North Carolina',
      code: 'NC'
    }, {
      name: 'North Dakota',
      code: 'ND'
    }, {
      name: 'Ohio',
      code: 'OH'
    }, {
      name: 'Oklahoma',
      code: 'OK'
    }, {
      name: 'Oregon',
      code: 'OR'
    }, {
      name: 'Pennsylvania',
      code: 'PA'
    }, {
      name: 'Rhode Island',
      code: 'RI'
    }, {
      name: 'South Carolina',
      code: 'SC'
    }, {
      name: 'South Dakota',
      code: 'SD'
    }, {
      name: 'Tennessee',
      code: 'TN'
    }, {
      name: 'Texas',
      code: 'TX'
    }, {
      name: 'Utah',
      code: 'UT'
    }, {
      name: 'Vermont',
      code: 'VT'
    }, {
      name: 'Virginia',
      code: 'VA'
    }, {
      name: 'Washington',
      code: 'WA'
    }, {
      name: 'West Virginia',
      code: 'WV'
    }, {
      name: 'Wisconsin',
      code: 'WI'
    }, {
      name: 'Wyoming',
      code: 'WY'
    }],
    regionDisplayName: 'State'
  }), _defineProperty(_countries, _countryCode.default.CA, {
    regions: [{
      name: 'Alberta',
      code: 'AB'
    }, {
      name: 'British Columbia',
      code: 'BC'
    }, {
      name: 'Manitoba',
      code: 'MB'
    }, {
      name: 'New Brunswick',
      code: 'NB'
    }, {
      name: 'Newfoundland and Labrador',
      code: 'NL'
    }, {
      name: 'Northwest Territories',
      code: 'NT'
    }, {
      name: 'Nova Scotia',
      code: 'NS'
    }, {
      name: 'Nunavut',
      code: 'NU'
    }, {
      name: 'Ontario',
      code: 'ON'
    }, {
      name: 'Prince Edward Island',
      code: 'PE'
    }, {
      name: 'Quebec',
      code: 'QC'
    }, {
      name: 'Saskatchewan',
      code: 'SK'
    }, {
      name: 'Yukon',
      code: 'YT'
    }],
    regionDisplayName: 'Province'
  }), _defineProperty(_countries, _countryCode.default.VI, {
    regions: [{
      name: 'United States Virgin Islands',
      code: 'VI'
    }],
    regionDisplayName: 'State'
  }), _defineProperty(_countries, _countryCode.default.GB, {
    regions: [],
    regionDisplayName: null
  }), _countries);

  var commonValidator = function commonValidator(value) {
    return !value || value.length < 11;
  };

  var POSTAL_CODE_VALIDATORS = (_POSTAL_CODE_VALIDATO = {}, _defineProperty(_POSTAL_CODE_VALIDATO, _countryCode.default.US, function (value) {
    return value && /^\d{5}(-\d{4})?$/.test(value.toString());
  }), _defineProperty(_POSTAL_CODE_VALIDATO, _countryCode.default.CA, function (value) {
    return value && /^(?!.*[DFIOQU])[A-VXY]\d[A-Z] ?\d[A-Z]\d$/i.test(value.toString());
  }), _defineProperty(_POSTAL_CODE_VALIDATO, _countryCode.default.VI, function (value) {
    return value && /^(008\d{2}(-\d{4})?)$/.test(value.toString());
  }), _defineProperty(_POSTAL_CODE_VALIDATO, _countryCode.default.GB, function (value) {
    return value && /^([A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}|GIR 0AA)$/i.test(value.toString());
  }), _defineProperty(_POSTAL_CODE_VALIDATO, "DEFAULT", commonValidator), _POSTAL_CODE_VALIDATO);
  /**
   * Gets region data for a given countryCode
   *
   * @method     getRegions
   * @param      {String} countryCode Country code in ISO 3166-1 (Alpha-2) format
   * @returns    {Object} A list of regions
   */

  function getRegions(countryCode) {
    var _countries$countryCod;

    return ((_countries$countryCod = countries[countryCode]) === null || _countries$countryCod === void 0 ? void 0 : _countries$countryCod.regions) || [];
  }
  /**
   * Gets region display name for a given countryCode
   *
   * @method     getRegionLabel
   * @param      {String} countryCode Country code in ISO 3166-1 (Alpha-2) format
   * @returns    {String} Region display name
   */


  function getRegionLabel(countryCode) {
    var _countries$countryCod2;

    return ((_countries$countryCod2 = countries[countryCode]) === null || _countries$countryCod2 === void 0 ? void 0 : _countries$countryCod2.regionDisplayName) || 'Province';
  }
  /**
   * Validate a given postal code
   *
   * @method     isValidPostalCode
   * @param      {String} countryCode Country code in ISO 3166-1 (Alpha-2) format
   * @param      {String} value postal code
   * @returns    {boolean} Return true if the the given postal code is valid
   */


  function isValidPostalCode(countryCode, value) {
    var postalCodeValidator = POSTAL_CODE_VALIDATORS[countryCode] || POSTAL_CODE_VALIDATORS.DEFAULT;
    return postalCodeValidator(value);
  }
  /**
   * Return whether the region is a required component in the addresses in a given county (based on countryCode)
   *
   * @method     isRegionRequired
   * @param      {String} countryCode Country code in ISO 3166-1 (Alpha-2) format
   * @returns    {boolean} Return true if the region is required in addresses in a given county (countryCode)
   */


  function isRegionRequired(countryCode) {
    var _countries$countryCod3;

    var regions = (_countries$countryCod3 = countries[countryCode]) === null || _countries$countryCod3 === void 0 ? void 0 : _countries$countryCod3.regions;
    return regions && regions.length > 0;
  }
});