define("lh-public-olo/templates/location/menu/account/entry", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cjve4cXh",
    "block": "[[[8,[39,0],null,[[\"@named\",\"@send\"],[\"item-modal\",[50,\"account/entry-view\",0,null,[[\"nextRoute\",\"signUpTab\",\"close\",\"route\"],[[33,2],[33,3],[28,[37,4],[[30,0],\"close\"],null],\"location.menu\"]]]]],null]],[],false,[\"to-elsewhere\",\"component\",\"nextRoute\",\"signUpTab\",\"action\"]]",
    "moduleName": "lh-public-olo/templates/location/menu/account/entry.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});