define("lh-public-olo/templates/location/loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "w491xTEF",
    "block": "[[[10,0],[14,0,\"ui active inverted page dimmer\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"ui large text loader\"],[12],[1,\"Loading...\"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "lh-public-olo/templates/location/loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});