define("lh-public-olo/routes/location/menu/order-settings", ["exports", "@ember/routing/route", "@ember/service", "rsvp"], function (_exports, _route, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    featureFlag: (0, _service.inject)(),
    session: (0, _service.inject)(),
    ticket: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    beforeModel: function beforeModel() {
      var _this = this;

      var location = this.modelFor('location');

      if (this.get('ticket.dineIn')) {
        this.ticket.setOrderSetting('orderTypeGuid', location.orderTypes.firstObject.id);
        this.set('ticket.orderType', location.orderTypes.firstObject);
        this.ticket.setOrderSetting('tableNotes', this.get('ticket.tableNotes'));
        return this.replaceWith('location.menu');
      }

      if (this.featureFlag.isEnabled('sto-skip-order-settings') && location.orderTypes.length === 1 && location.orderTypes.firstObject.id === "TAKEOUT") {
        return this.ticket.getAvailableTimeSlots(location.orderTypes.firstObject).then(function (response) {
          if (response.futureTimeSlots.length === 0 && response.asapTimeSlot) {
            _this.ticket.setOrderSetting('orderTypeGuid', location.orderTypes.firstObject.id);

            _this.set('ticket.orderType', location.orderTypes.firstObject);

            _this.ticket.setOrderSetting('isOrderTypeAsapAndTakeout', true);

            _this.replaceWith('location.menu');
          }
        });
      }
    },
    model: function model() {
      var location = this.modelFor('location');
      return (0, _rsvp.hash)({
        location: location,
        orderTypes: location.get('orderTypes'),
        customer: this.customerService.getCustomer()
      });
    }
  });

  _exports.default = _default;
});