define("lh-public-olo/services/ajax", ["exports", "ember-ajax/services/ajax", "@ember/service", "@ember/object"], function (_exports, _ajax, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: (0, _service.inject)(),
    contentType: 'application/json; charset=utf-8',
    trustedHosts: [/\.shift4payments\./],
    headers: (0, _object.computed)('session.data', 'session.isAuthenticated', {
      get: function get() {
        var headers = {};

        if (this.get('session.isAuthenticated')) {
          headers.Authorization = "Bearer ".concat(this.get('session.data.authenticated.accessToken'));
        }

        return headers;
      }
    })
  });

  _exports.default = _default;
});