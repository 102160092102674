define("lh-public-olo/adapters/category", ["exports", "lh-public-olo/adapters/menu"], function (_exports, _menu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _menu.default.extend({
    urlForQuery: function urlForQuery() {
      var url = this._super.apply(this, arguments);

      return this.get('ticket.dineIn') ? "".concat(url, "?dineIn=true") : url;
    }
  });

  _exports.default = _default;
});