define("lh-public-olo/templates/components/order-details/ticket-item/modifier-set", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GmPkYgyq",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"\\t\"],[10,\"tr\"],[12],[1,\"\\n\\t\\t\"],[10,\"td\"],[12],[1,[52,[28,[37,4],[[30,2],0],null],[33,5,[\"name\"]]]],[13],[1,\"\\n\\t\\t\"],[10,\"td\"],[12],[1,\"\\n\\t\\t\\t\"],[1,[30,1,[\"modifier\",\"name\"]]],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[10,\"td\"],[14,0,\"right aligned\"],[12],[1,\"\\n\"],[41,[30,1,[\"subtotal\"]],[[[1,\"\\t\\t\\t\\t\"],[10,0],[14,0,\"ui circular label\"],[12],[1,\"+\"],[1,[28,[35,6],[[30,1,[\"subtotal\"]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[1,2]],null]],[\"modifier\",\"index\"],false,[\"each\",\"-track-array\",\"modifiers\",\"if\",\"eq\",\"set\",\"currency-format\"]]",
    "moduleName": "lh-public-olo/templates/components/order-details/ticket-item/modifier-set.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});