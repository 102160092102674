define("lh-public-olo/enums/date-formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DATE_FORMATS = void 0;
  var DATE_FORMATS = Object.freeze({
    'en-US': {
      hour: 'numeric',
      minute: '2-digit'
    },
    'en-GB': {
      hour: '2-digit',
      minute: '2-digit'
    },
    defaultFormat: {
      hour: 'numeric',
      minute: '2-digit'
    }
  });
  _exports.DATE_FORMATS = DATE_FORMATS;
  var _default = DATE_FORMATS;
  _exports.default = _default;
});