define("lh-public-olo/authenticators/jwt-custom", ["exports", "@ember/service", "rsvp", "ember-simple-auth-token/authenticators/jwt", "lh-public-olo/config/environment"], function (_exports, _service, _rsvp, _jwt, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jwt.default.extend({
    ajax: (0, _service.inject)(),
    invalidate: function invalidate(data) {
      var _this = this;

      var skipBackEndLogout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return this._super(data).then(function () {
        if (skipBackEndLogout) {
          return (0, _rsvp.resolve)();
        }

        return _this.ajax.delete("".concat(_environment.default.oocHost, "/online-ordering/mars/api/v2/customer/logout"));
      }).catch(function () {
        return (0, _rsvp.resolve)();
      });
    }
  });

  _exports.default = _default;
});