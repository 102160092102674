define("lh-public-olo/routes/vanity", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    beforeModel: function beforeModel() {
      this.store.unloadAll();
    },
    model: function model(params) {
      return this.store.queryRecord('location', {
        vanityRef: params.ref
      });
    },
    redirect: function redirect(model) {
      this.replaceWith('location', model);
    }
  });

  _exports.default = _default;
});