define("lh-public-olo/helpers/mult", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mult = mult;
  _exports.default = void 0;

  // stole this from ember-math-helpers
  function mult(params
  /*, hash*/
  ) {
    return params.reduce(function (a, b) {
      return Number(a) * Number(b);
    });
  }

  var _default = (0, _helper.helper)(mult);

  _exports.default = _default;
});