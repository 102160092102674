define("lh-public-olo/templates/components/order-details/ticket-item/modifier", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "B9eHIj0o",
    "block": "[[[1,\"+ \"],[1,[30,1,[\"modifierSet\",\"name\"]]],[1,\": \"],[1,[30,1,[\"modifier\",\"name\"]]],[1,\" \"],[1,[28,[35,0],[[30,1,[\"subtotal\"]]],null]],[1,\"\\n\"],[41,[33,2,[\"modifiers\"]],[[[42,[28,[37,4],[[28,[37,4],[[33,2,[\"modifiers\"]]],null]],null],null,[[[1,\"    \"],[8,[39,5],null,[[\"@modifier\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null]],[]],null]],[\"@modifier\",\"modifier\"],false,[\"usd\",\"if\",\"modifier\",\"each\",\"-track-array\",\"order-details/ticket-item/modifier\"]]",
    "moduleName": "lh-public-olo/templates/components/order-details/ticket-item/modifier.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});