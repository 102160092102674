define("lh-public-olo/adapters/menu", ["exports", "@ember/service", "@ember/object", "lh-public-olo/config/environment", "lh-public-olo/adapters/application"], function (_exports, _service, _object, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    ticket: (0, _service.inject)(),
    featureFlag: (0, _service.inject)(),
    host: (0, _object.computed)(function () {
      return this.featureFlag.isEnabled('skytab-online-ooc-get-menu') ? _environment.default.oocHost : _environment.default.host;
    }),
    namespace: (0, _object.computed)(function () {
      if (this.featureFlag.isEnabled('skytab-online-ooc-get-menu')) {
        return 'online-ordering/mars/api/v2/stores';
      }

      if (this.featureFlag.isEnabled('sto-nested-mods')) {
        return 'api/v2/public';
      }

      return 'api/v1/public';
    }),
    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, 'query', _query);
      return this.ajax(url, 'GET');
    },
    urlForQuery: function urlForQuery(query, modelName) {
      var baseUrl = this.buildURL();
      var location = this.get('ticket.locationRef');
      var path = this.pathForType(modelName);
      var pathSuffix = this.featureFlag.isEnabled('skytab-online-ooc-get-menu') ? 'schedules' : 'menu';
      return "".concat(baseUrl, "/").concat(location, "/").concat(pathSuffix, "/").concat(query.schedule, "/").concat(path);
    }
  });

  _exports.default = _default;
});