define("lh-public-olo/components/item-modal", ["exports", "@ember/service", "@ember/object", "@ember/runloop", "@ember/component"], function (_exports, _service, _object, _runloop, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['item-modal'],
    didInsertElement: function didInsertElement() {
      this.$('.modal').modal('show');
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.modal').modal('setting', 'onHide', function () {}).modal('hide');
    },
    store: (0, _service.inject)(),
    ticket: (0, _service.inject)(),
    featureFlag: (0, _service.inject)(),
    showErrors: false,
    modifierSets: (0, _object.computed)('selectedNestedModifier', function () {
      return this.selectedNestedModifier ? this.get('selectedNestedModifier.modifier.modifierSets') : this.get('item.modifierSets');
    }),
    // return if a modifier set has any validation errors
    validateModifierSet: function validateModifierSet(set) {
      var group = this.get('cartItem.modifiers').filterBy('modifierSetRef', set.get('id'));
      var total = group.reduce(function (quantity, mod) {
        return quantity + mod.get('quantity');
      }, 0);
      return total > set.get('maxModifiersPerSet') && !set.get('oneOnly') || total < set.get('minModifiersPerSet') && set.get('isRequired');
    },
    // return if a cartItemModifier or it's children has any validation errors
    validateCartItemModifier: function validateCartItemModifier(cartItemModifier) {
      var _this = this;

      // check if any of the cartItemModifier's child
      if (cartItemModifier.get('modifier.modifierSets').toArray().some(function (set) {
        return _this.validateModifierSet(set);
      })) {
        return true;
      } // check the child cart item modifiers for errors


      return cartItemModifier.get('modifiers').toArray().some(function (modifier) {
        return _this.validateCartItemModifier(modifier);
      });
    },
    exitCurrentNested: function exitCurrentNested() {
      this.set('showNestedErrors', false);
      this.set('selectedNestedModifier', this.get('selectedNestedModifier.parentModifier') || undefined);
    },
    scrollToInvalidModifier: function scrollToInvalidModifier() {
      (0, _runloop.next)(function () {
        var invalidModifier = document.querySelector('.item-details .ui.form .field.error');

        if (invalidModifier) {
          invalidModifier.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      });
    },
    errors: (0, _object.computed)('cartItem.modifiers', 'cartItem.modifiers.@each.quantity', 'modifierSets', function () {
      var _this2 = this;

      var errors = {};
      this.modifierSets.forEach(function (set) {
        if (_this2.validateModifierSet(set)) {
          errors[set.get('id')] = true;
        }
      });
      return errors;
    }),
    modifierErrors: (0, _object.computed)('cartItem.modifiers', 'selectedNestedModifier', function () {
      var _this3 = this;

      var errors = {};
      this.get('cartItem.modifiers').filterBy('modifier.modifierSets.length').filterBy('parentModifier.id', this.get('selectedNestedModifier.id')).forEach(function (cartItemModifier) {
        if (_this3.validateCartItemModifier(cartItemModifier)) {
          errors[cartItemModifier.get('modifier.id')] = true;
        }
      });
      return errors;
    }),
    thereAreErrors: (0, _object.computed)('errors', function () {
      return !!Object.keys(this.errors).length || !!Object.keys(this.modifierErrors).length;
    }),
    quantityError: (0, _object.computed)('cartItem.quantity', function () {
      var quantity = Number(this.get('cartItem.quantity'));
      return quantity < 1 || !Number.isInteger(quantity);
    }),
    isSpecialRequestEnabled: (0, _object.computed)(function () {
      return this.get('ticket.orderType.allowSpecialRequest');
    }),
    actions: {
      onModalHide: function onModalHide() {
        this.sendAction('cancelOrder');
      },
      modifierSelect: function modifierSelect() {
        // passthrough action
        this.sendAction.apply(this, ['modifierSelect'].concat(Array.prototype.slice.call(arguments), [this.selectedNestedModifier])); // using notifyPropertyChange allows me to control when the CPs are recomputed

        this.notifyPropertyChange('cartItem.modifiers');
      },
      setSelectedNestedModifier: function setSelectedNestedModifier(modifier) {
        this.set('selectedNestedModifier', modifier);
        var applicableModifiers = []; // eslint-disable-next-line require-jsdoc

        function pushChildModifiers(cartItemModifier) {
          cartItemModifier.get('modifiers').forEach(function (mod) {
            applicableModifiers.push(mod);
            pushChildModifiers(mod);
          });
        }

        pushChildModifiers(modifier); // make a serialized copy of the existing modifiers when entering, used for cancel feature

        var initialCartItemModifiers = applicableModifiers.map(function (mod) {
          return mod.serialize({
            includeId: true
          });
        });
        this.set('initialCartItemModifiers', initialCartItemModifiers);
      },
      cancelNested: function cancelNested() {
        var _this4 = this;

        // unload any new modifiers
        this.get('selectedNestedModifier.modifiers').filterBy('isNew').forEach(function (modifier) {
          return modifier.unloadRecord();
        });

        if (this.get('initialCartItemModifiers.length')) {
          // push the serialized modifiers into the store in case of them were deleted or modified
          this.initialCartItemModifiers.forEach(function (modifier) {
            return _this4.store.pushPayload('cart-item-modifier', {
              cartItemModifier: modifier
            });
          });
        }

        this.exitCurrentNested();
      },
      saveNested: function saveNested() {
        if (!this.thereAreErrors) {
          this.exitCurrentNested();
        } else {
          this.set('showNestedErrors', true);
          this.scrollToInvalidModifier();
        }
      },
      addToOrder: function addToOrder() {
        var _this5 = this;

        // make sure there are no errors before doing anything
        if (!this.thereAreErrors && !this.quantityError) {
          this.cartItem.save().then(function () {
            _this5.sendAction('addToOrder');
          });
        } else {
          this.set('showErrors', true);
          this.scrollToInvalidModifier();
        }
      }
    }
  });

  _exports.default = _default;
});