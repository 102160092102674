define("lh-public-olo/utils/time-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addMinutes = addMinutes;
  _exports.differenceInMinutes = differenceInMinutes;
  _exports.getTimeAsString = getTimeAsString;
  _exports.isSameDay = isSameDay;
  _exports.getDisplayDay = getDisplayDay;
  _exports.getAdjustedTime = getAdjustedTime;
  _exports.convertMinutesToHoursAndMinutes = convertMinutesToHoursAndMinutes;

  /**
   * This method adds the given minutes to the current time
   *
   * @param {Date} currentDate The current Date.
   * @param {number} minutes Minutes to be added
   * @returns {Date} Updated Time
   */
  function addMinutes(currentDate, minutes) {
    return new Date(currentDate.getTime() + (minutes ? minutes * 60000 : 0));
  }
  /**
   * This method subtracts currentDate from future date to get time difference in minutes
   *
   * @param {Date} currentDate The current date.
   * @param {Date} futureDate Future date
   * @returns {number} Minutes count
   */


  function differenceInMinutes(currentDate, futureDate) {
    return Math.round((new Date(futureDate) - new Date(currentDate || new Date())) / (60 * 1000));
  }
  /**
   * Formats Date to Time and returns it as string;
   *
   * @param {Date} date Date Object
   * @returns {*} Time as string
   */


  function getTimeAsString(date) {
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit'
    });
  }
  /**
   * Checks if two dates are same day (e.g. today)
   *
   * @param {Date} date Date Object
   * @param {Date} dateRef Date Object to be compared
   * @returns {boolean} boolean
   */


  function isSameDay(date, dateRef) {
    return date.getFullYear() === dateRef.getFullYear() && date.getMonth() === dateRef.getMonth() && date.getDate() === dateRef.getDate();
  }
  /**
   * Get display day (e.g. Today, Tomorrow, Monday, 10/5, etc)
   *
   * @param {Date} time Date Object
   * @param {number=} timeZoneOffset Optional timezone offset of the passed time parameter as number
   * @returns {string} Day as string
   */


  function getDisplayDay(time, timeZoneOffset) {
    var timeNow = timeZoneOffset ? getAdjustedTime(timeZoneOffset) : new Date();
    var timeTomorrow = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate() + 1);

    if (isSameDay(time, timeNow)) {
      return 'Today';
    }

    if (isSameDay(time, timeTomorrow)) {
      return 'Tomorrow';
    }

    return time.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'numeric',
      day: 'numeric'
    });
  }
  /**
   * Get store time for a local time. Current store time is returned if time is not passed.
   *
   * @param {number} timeZoneOffset store time zone offset
   * @param {Date=} time time to be converted to store time. Defaults to current time if not provided
   * @returns {Date} Store time as Date Object
   */


  function getAdjustedTime(timeZoneOffset) {
    var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
    var browserOffset = time.getTimezoneOffset();
    var storeOffset = timeZoneOffset * -60 || 0;
    return new Date(time - (storeOffset - browserOffset) * 60000); // 60000 - milliseconds in minute
  }
  /**
   * This method converts minutes to hours and remaining minutes
   *
   * @param {number} minutes Minutes to be converted
   * @returns {string} Hours and Minutes
   */


  function convertMinutesToHoursAndMinutes(minutes) {
    var hours = Math.floor(minutes / 60);
    var remainingMinutes = minutes % 60;

    if (isNaN(minutes) || minutes < 0) {
      return "Invalid input";
    }

    if (hours === 0) {
      return "".concat(remainingMinutes, " minute").concat(remainingMinutes !== 1 ? 's' : '');
    } else if (remainingMinutes === 0) {
      return "".concat(hours, " hour").concat(hours !== 1 ? 's' : '');
    }

    return "".concat(hours, " hour").concat(hours !== 1 ? 's' : '', " and ").concat(remainingMinutes, " minute").concat(remainingMinutes !== 1 ? 's' : '');
  }
});