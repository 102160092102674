define("lh-public-olo/utils/round-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = roundDate;

  /**
   * Rounds a date to a five minute increment
   *
   * @method     roundDate
   * @param      {Date|String} date Date to round
   * @returns    {Date} Rounded date
   */
  function roundDate(date) {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    return new Date(300000 * Math.ceil(date.valueOf() / 300000));
  }
});