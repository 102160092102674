define("lh-public-olo/components/account/account-modal", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['account-modal'],
    media: (0, _service.inject)(),
    didInsertElement: function didInsertElement() {
      this.$('.modal').modal('show');
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.modal').modal('setting', 'onHide', function () {}).modal('hide');
    },
    actions: {
      onModalHide: function onModalHide() {
        this.sendAction('close');
      }
    }
  });

  _exports.default = _default;
});