define("lh-public-olo/controllers/location/user-profile", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: ['previous'],
    previous: null,
    previousRoute: (0, _object.computed)('previous', function () {
      if (this.previous && this.previous.includes('checkout')) {
        return {
          name: 'Checkout',
          route: 'location.checkout'
        };
      }

      return {
        name: 'Menu',
        route: 'location.menu'
      };
    })
  });

  _exports.default = _default;
});