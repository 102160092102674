define("lh-public-olo/adapters/location", ["exports", "@ember/service", "@ember/object", "lh-public-olo/config/environment", "lh-public-olo/adapters/application"], function (_exports, _service, _object, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    ticket: (0, _service.inject)(),
    featureFlag: (0, _service.inject)(),
    host: (0, _object.computed)(function () {
      return this.featureFlag.isEnabled('skytab-online-ooc-get-location') ? _environment.default.oocHost : _environment.default.host;
    }),
    namespace: (0, _object.computed)(function () {
      return this.featureFlag.isEnabled('skytab-online-ooc-get-location') ? 'online-ordering/mars/api/v2/stores' : 'api/v1/public/location';
    }),
    pathForType: function pathForType() {
      return ''; // embedded into namespace for consistency
    },
    urlForFindRecord: function urlForFindRecord() {
      var url = this._super.apply(this, arguments);

      return this.get('ticket.dineIn') ? "".concat(url, "?dineIn=true") : url;
    },
    urlForQueryRecord: function urlForQueryRecord(query) {
      var url = this._super.apply(this, arguments);

      if (query.vanityRef) {
        url = "".concat(url, "/vanity/").concat(query.vanityRef);
        query.vanityRef = undefined;
      }

      return this.get('ticket.dineIn') ? "".concat(url, "?dineIn=true") : url;
    }
  });

  _exports.default = _default;
});