define("lh-public-olo/components/back-to-menu", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['back-to-menu']
  });

  _exports.default = _default;
});