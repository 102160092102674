define("lh-public-olo/controllers/location/menu", ["exports", "@ember/controller", "@ember/service", "@ember/runloop"], function (_exports, _controller, _service, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    ticket: (0, _service.inject)(),
    actions: {
      setMenuSchedule: function setMenuSchedule(menuScheduleRef) {
        // Running in separate run loop prevents error
        // Transition: Element is no longer attached to DOM
        // and hopefully dropdown flickering.
        // @see https://github.com/Semantic-Org/Semantic-UI-Ember/issues/129
        (0, _runloop.next)(this, function () {
          var menuSchedule = this.get('model.menuSchedules').findBy('id', menuScheduleRef);
          this.ticket.setOrderSetting('menuScheduleRef', menuScheduleRef);
          this.ticket.set('menuSchedule', menuSchedule);
        });
      }
    }
  });

  _exports.default = _default;
});