define("lh-public-olo/utils/general-error-response-parser", ["exports", "ember-ajax/errors", "@ember/polyfills"], function (_exports, _errors, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseErrorResponse;

  /**
   * Parsing options
   * @typedef {Object} ParserOptions
   * @property {string} fallbackMessage - a fall back message used when parsed error input yield is invalid/empty
   * @property {boolean} preserveArray - Do not truncate list of errors
   * (i.e parse all items in errors instead of just using errors[0])
   */
  var defaultOptions = {
    fallbackMessage: 'An error occured',
    preserveArray: false
  };
  /**
   * An error object returned from the parser
   * @typedef {Object} ParsedErrorResponse
   * @property {string=} friendlyError - a fiendly error message (optional) suitable for display.
   * @property {string} message - a (detailed) error message. It can be used as a fallback to `friendlyError`
   *                              if the latter is unspecified.
   */

  /**
   * Gets some error value and returns an error of the shape { friendlyError, message }
   *
   * @method     parseErrorResponse
   * @param      {string | Array | Object} errResponse an error value
   * @param      {ParserOptions=} opts options object
   * @returns    {ParsedErrorResponse | Array<ParsedErrorResponse>} a simple error message object
   */

  function parseErrorResponse(errResponse, opts) {
    var options = (0, _polyfills.assign)({}, defaultOptions, opts);

    if (typeof errResponse === 'undefined' || errResponse === null) {
      return {
        message: options.fallbackMessage
      };
    }

    if (typeof errResponse === 'string') {
      return {
        message: errResponse.trim() || options.fallbackMessage
      };
    }

    if (Array.isArray(errResponse)) {
      if (options.preserveArray) {
        return errResponse.map(function (e) {
          return parseErrorResponse(e, opts);
        });
      }

      return errResponse.length ? parseErrorResponse(errResponse[0], opts) : parseErrorResponse(null, opts);
    }

    if (!(0, _errors.isAjaxError)(errResponse) && !isPlainObject(errResponse)) {
      return {
        message: options.fallbackMessage
      };
    }

    if (errResponse.payload && Array.isArray(errResponse.payload.errors)) {
      if (options.preserveArray) {
        return errResponse.payload.errors.map(function (e) {
          return parseErrorResponse(e, opts);
        });
      }

      return errResponse.payload.errors.length ? parseErrorResponse(errResponse.payload.errors[0], opts) : parseErrorResponse(null, opts);
    }

    if (Array.isArray(errResponse.errors)) {
      if (options.preserveArray) {
        return errResponse.errors.map(function (e) {
          return parseErrorResponse(e, opts);
        });
      }

      return errResponse.errors.length ? parseErrorResponse(errResponse.errors[0], opts) : parseErrorResponse(null, opts);
    }

    if (errResponse.error) {
      return parseErrorResponse(errResponse.error, opts);
    }

    return (0, _polyfills.assign)({
      message: errResponse.message || options.fallbackMessage
    }, errResponse.friendlyError ? {
      friendlyError: errResponse.friendlyError
    } : {});
  }
  /**
   * Checks if input value is a plain js object
   *
   * @method     isPlainObject
   * @param      {Object} value some input value
   * @returns    {boolean} indicates whether or not the input value is a plain js object
   */


  function isPlainObject(value) {
    if (Array.isArray(value)) {
      return false;
    }

    if (Object.prototype.toString.call(value) !== '[object Object]') {
      return false;
    }

    var prototype = Object.getPrototypeOf(value);
    return prototype === null || prototype === Object.prototype;
  }
});