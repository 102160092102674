define("lh-public-olo/templates/components/calculation-errors", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3vOfcxR0",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"\\t\"],[10,0],[12],[1,[30,1,[\"friendlyError\"]]],[1,\".\"],[13],[1,\"\\n\"]],[1]],null]],[\"error\"],false,[\"each\",\"-track-array\",\"errors\"]]",
    "moduleName": "lh-public-olo/templates/components/calculation-errors.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});