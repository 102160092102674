define("lh-public-olo/models/modifier-set", ["exports", "@ember-data/model", "@ember/object/computed"], function (_exports, _model, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    minModifiersPerSet: (0, _model.attr)('number'),
    maxModifiersPerSet: (0, _model.attr)('number'),
    modifiers: (0, _model.hasMany)('modifier'),
    oneOnly: (0, _computed.equal)('maxModifiersPerSet', 1),
    isRequired: (0, _computed.gte)('minModifiersPerSet', 1),
    parentModifiers: (0, _model.hasMany)('modifier')
  });

  _exports.default = _default;
});