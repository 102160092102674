define("lh-public-olo/components/order-checkout", ["exports", "@ember/service", "@ember/component", "@ember/object", "rsvp", "lh-public-olo/config/environment", "lh-public-olo/utils/validation-check", "lh-public-olo/utils/currency-converter", "ember-cp-validations", "@ember/object/computed", "lh-public-olo/utils/general-error-response-parser", "lh-public-olo/utils/time-util", "lh-public-olo/utils/mask-card-number"], function (_exports, _service, _component, _object, _rsvp, _environment, _validationCheck, _currencyConverter, _emberCpValidations, _computed, _generalErrorResponseParser, _timeUtil, _maskCardNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    giftAmountToRedeem: {
      validators: [(0, _emberCpValidations.validator)('number', {
        positive: true,
        allowString: true,
        message: 'Gift amount must be more than $0.00'
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^\d*(\.\d{0,2})?$/,
        message: 'Enter a valid amount. Example: 2.00'
      })],
      description: 'Gift amount',
      disabled: (0, _computed.not)('model.giftAmountToRedeem')
    },
    securityCode: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'CVC is required'
      }), (0, _emberCpValidations.validator)('number', {
        positive: true,
        allowString: true,
        message: 'Invalid CVC'
      }), (0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 4,
        message: 'Invalid CVC'
      })],
      description: 'CVC',
      disabled: (0, _computed.not)('model.isSavedCardSelected')
    }
  });

  var _default = _component.default.extend(Validations, {
    ENV: _environment.default,
    ticket: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    payment: (0, _service.inject)(),
    session: (0, _service.inject)(),
    store: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    loyaltyMember: (0, _service.inject)(),
    featureFlag: (0, _service.inject)(),
    step: 'info',
    paymentType: undefined,
    orderTimeUtc: undefined,
    authorizations: undefined,
    placeOrderInitiated: false,
    securityCode: undefined,
    isContactlessDeliveryEnabled: (0, _object.computed)('ticket.orderType.id', 'ticket.tenderTypeGuid', function () {
      return this.get('ticket.orderType.id') === 'DELIVERY' && this.get('ticket.tenderTypeGuid') !== this.get('tenders.cash.id');
    }),
    isLoyaltyEnabled: (0, _object.computed)('location', function () {
      return this.featureFlag.isEnabled('sto-loyalty') && this.loyaltyProgram;
    }),
    allowUnpaidOrders: (0, _object.computed)(function () {
      return this.featureFlag.isEnabled('sto-allow-unpaid-orders');
    }),
    shouldShowTipsView: (0, _object.computed)('location.tenders.[]', 'ticket.tenderTypeGuid', 'isFullyPayByGiftCard', 'ticket.isCustomCashPayment', function () {
      if (this.ticket.isCustomCashPayment) {
        return false;
      }

      if (this.isPayByGiftCardEnabled) {
        return this.get('location.tenders').findBy('id', this.get('ticket.tenderTypeGuid')).get('isTipAllowed') && !this.isFullyPayByGiftCard;
      }

      return !!this.get('location.tenders').findBy('isTipAllowed', true);
    }),
    isPayByGiftCardEnabled: (0, _object.computed)('location.allowGiftCardPayments', function () {
      return this.featureFlag.isEnabled('sto-pay-by-gift-card') && this.get('location.allowGiftCardPayments');
    }),
    isDiscountCodesEnabled: (0, _object.computed)('location.allowCouponDiscounts', function () {
      return this.featureFlag.isEnabled('sto-coupon-codes') && this.get('location.allowCouponDiscounts');
    }),
    showPayByGiftCardErrors: (0, _object.computed)('ticket.giftCardErrors', 'validatedGiftCardNumber', 'giftCardNumber', function () {
      if (!this.get('ticket.giftCardErrors')) {
        return false;
      }

      if (!this.validatedGiftCardNumber) {
        return !this.giftCardNumber;
      }

      return this.validatedGiftCardNumber === this.giftCardNumber;
    }),
    showDiscountCodeErrors: (0, _object.computed)('ticket.discountCodeError', 'validatedDiscountCode', 'discountCode', function () {
      if (!this.get('ticket.discountCodeError')) {
        return false;
      }

      if (!this.validatedDiscountCode) {
        return !this.discountCode;
      }

      return this.validatedDiscountCode === this.discountCode.toUpperCase();
    }),
    isFullyPayByGiftCard: (0, _object.computed)('ticket.giftCardAppliedAmount', 'ticket.total', function () {
      return this.get('ticket.giftCardAppliedAmount') === this.get('ticket.total');
    }),
    applicableGiftCardBalance: (0, _object.computed)('ticket.giftCardBalance', 'ticket.total', function () {
      return (0, _currencyConverter.toDollars)(Math.min(this.get('ticket.giftCardBalance'), this.get('ticket.total')));
    }),
    captchaEnabled: (0, _object.computed)('ENV.captchaEnabled', 'customerService.selectedPaymentType', 'location.reCaptchaEnabled', function () {
      return _environment.default.captchaEnabled && this.get('location.reCaptchaEnabled') && (this.get('ticket.tenderTypeGuid') === this.get('tenders.credit.id') || this.get('ticket.giftCardAppliedAmount'));
    }),
    isServedByDDDOrDSP: (0, _object.computed)('ticket.orderType.deliveryServedWithDSP', 'ticket.orderType.isServedByDoorDashDrive', function () {
      return this.get('ticket').isServedByDDDOrDSP();
    }),
    hasValidReferralDetails: (0, _object.computed)('ticket.referralGuid', 'ticket.referralSource', function () {
      return this.featureFlag.isEnabled('sto-referrals') && this.get('ticket.referralSource') && this.get('ticket.referralGuid') && this.get('ticket.referralGuid').length <= 36;
    }),
    isSavedCardSelected: (0, _object.computed)('customerService.selectedPaymentType', 'isFullyPayByGiftCard', function () {
      return this.get('customerService.selectedPaymentType') === 'SAVED_CARD' && !this.isFullyPayByGiftCard;
    }),
    didInsertElement: function didInsertElement() {
      this.ticket.setProperties({
        discountCodeError: undefined,
        discountCodeValid: false
      });

      if (this.get('ticket.discountCode')) {
        this.set('discountCode', this.get('ticket.discountCode'));
        this.set('validatedDiscountCode', this.discountCode);
        this.send('applyDiscount');
      }

      if (this.get('ticket.giftCardNumber')) {
        this.set('giftCardNumber', this.get('ticket.giftCardNumber'));
        this.set('validatedGiftCardNumber', this.giftCardNumber);
      }

      if (this.customer.phone) {
        this.send('customerPhoneChanged');
      }
    },
    validateDistance: function validateDistance() {
      var _this = this;

      if (!this.get('ticket.orderType.requiresAddress') || this.get('ticket.orderType.storeDeliveryValidationType') === 'RADIUS' && !this.get('ticket.orderType.deliveryDistanceInMiles') && !this.isServedByDDDOrDSP) {
        return (0, _rsvp.resolve)();
      } // if the order type requires address and has a delivery distance defined
      // validate that the address is within range of that distance


      this.set('loading', true);
      return this.ticket.validateDistance(this.get('customerService.selectedDeliveryAddress'), this.get('ticket.orderType')).then(function () {
        return _this.setProperties({
          loading: false,
          rangeError: undefined,
          staticMapUrl: undefined
        });
      }).catch(function (_ref) {
        var message = _ref.message,
            staticMapUrl = _ref.staticMapUrl;

        _this.setProperties({
          loading: false,
          rangeError: "".concat(message, "."),
          staticMapUrl: staticMapUrl
        });

        return (0, _rsvp.reject)();
      });
    },
    authorize: function authorize() {
      var _this2 = this;

      this.set('loading', true);
      return this.payment.authorize(this.get('customer.email'), this.captchaResponse).then(function (authorizations) {
        if (_this2.featureFlag.isEnabled('sto-nested-mods')) {
          var i4go = authorizations.find(function (authorization) {
            return authorization.cardType === 'CREDIT_CARD';
          });

          _this2.setProperties({
            loading: false,
            authorizeError: undefined,
            i4go: i4go,
            authorizations: authorizations
          });
        } else {
          _this2.setProperties({
            loading: false,
            authorizeError: undefined,
            i4go: authorizations
          });
        }
      }).catch(function (payload) {
        var error = (0, _generalErrorResponseParser.default)(payload);

        _this2.setProperties({
          loading: false,
          authorizeError: error.friendlyError || error.message
        });

        return (0, _rsvp.reject)();
      });
    },
    showErrors: false,
    validateOrder: function validateOrder() {
      var _this3 = this;

      if (!this.loading && !this.get('ticket.amountRangeViolation') && !this.get('ticket.calculateErrors') && !this.showPayByGiftCardErrors && !this.showDiscountCodeErrors) {
        this.setProperties({
          showErrors: false,
          rangeError: false
        });
        return this.customer.validate().then(_validationCheck.default).then(function () {
          return _this3.validate();
        }).then(_validationCheck.default).then(function () {
          if (_this3.get('ticket.orderType.requiresAddress') && _this3.get('customerService.selectedDeliveryAddress.isNew')) {
            return _this3.get('customerService.selectedDeliveryAddress').validate().then(_validationCheck.default);
          }

          return (0, _rsvp.resolve)();
        }).then(function () {
          return _this3.ticket.calculate(true);
        }).then(function () {
          return _this3.ticket.validate();
        }).then(_validationCheck.default).then(function () {
          return _this3.validateDistance();
        }).then(function () {
          return _this3.getOrderTime();
        }).then(function () {
          return _this3.ticket.validateAppliedGiftCardAmount();
        }).then(function () {
          if (_this3.get('ticket.calculateErrors')) {
            return (0, _rsvp.reject)();
          }
        }).catch(function (e) {
          if (e && e.validations || _this3.rangeError || _this3.orderSettingsError) {
            _this3.set('showErrors', true);
          } else if (e === 'ITMOOSTK') {
            _this3.showSoldOutWarningModal();
          }

          return (0, _rsvp.reject)();
        });
      }

      return (0, _rsvp.reject)();
    },
    getOrderTime: function getOrderTime() {
      var _this4 = this;

      this.set('orderSettingsError', null);

      if (!this.get('ticket.isDeferredFeatureEnabled') || this.get('ticket.orderTypeGuid') === 'DINE_IN') {
        return (0, _rsvp.resolve)();
      }

      this.set('loading', true);

      if (!this.get('ticket.isDeferred')) {
        return this.ticket.getAvailableTimeSlots().then(function (timeSlot) {
          _this4.set('loading', false);

          if (!timeSlot.asapTimeSlot || !timeSlot.asapTimeSlot.isAvailable) {
            _this4.set('orderSettingsError', 'Your chosen order time is no longer available.');

            return (0, _rsvp.reject)();
          }

          return (0, _rsvp.resolve)();
        }).catch(function (message) {
          _this4.setProperties({
            loading: false,
            orderSettingsError: message
          });

          return (0, _rsvp.reject)();
        });
      }

      return this.ticket.getOrderTime().then(function (orderTimeUtc) {
        _this4.setProperties({
          loading: false,
          orderTimeUtc: orderTimeUtc
        });
      }).catch(function (message) {
        _this4.setProperties({
          loading: false,
          orderSettingsError: message
        });

        return (0, _rsvp.reject)();
      });
    },
    saveCustomerInfo: function saveCustomerInfo() {
      var _this5 = this;

      if (!this.get('ticket.isRememberCustomerAccountInfoFeatureEnabled') || !this.get('session.isAuthenticated')) {
        return;
      }

      this.customer.save().then(function (updatedCustomer) {
        return _this5.customerService.updateUsername(updatedCustomer);
      }).catch(function () {
        return (0, _rsvp.resolve)();
      });

      if (this.get('ticket.orderType.requiresAddress') && this.get('customerService.selectedDeliveryAddress.isNew')) {
        this.get('customerService.selectedDeliveryAddress').save().then(function () {
          _this5.get('customer.deliveryAddresses').pushObject(_this5.get('customerService.selectedDeliveryAddress'));
        }).catch(function () {
          return (0, _rsvp.resolve)();
        });
      }
    },
    completeOrderProcess: function completeOrderProcess(i4goTokenResponse) {
      if (!this.get('ticket.isRememberCustomerAccountInfoFeatureEnabled') || !this.get('session.isAuthenticated') || this.get('ticket.submitResponse.error') || this.get('ticket.submitResponse.errors.firstObject.code') === 'ORDCCNOPROC' || this.get('customerService.selectedPaymentType') === 'SAVED_CARD' || this.paymentType === 'GOOGLE_PAY' || this.paymentType === 'APPLE_PAY' || !this.get('ticket.tenderTypeGuid') === this.get('tenders.credit.id') || !i4goTokenResponse) {
        this.set('securityCode', undefined);
        this.submitResponse();
      } else {
        this.set('step', 'saving_credit_card_info');
        this.set('i4goTokenResponse', i4goTokenResponse);
      }
    },
    actions: {
      clearDiscount: function clearDiscount() {
        this.set('discountCode', null);
        this.set('validatedDiscountCode', undefined);
        this.ticket.validateDiscountCode(this.discountCode);
      },
      applyDiscount: function applyDiscount() {
        if (!this.discountCode) {
          this.ticket.setProperties({
            discountCodeError: 'Coupon code can\'t be blank.',
            discountCodeValid: false
          });
          this.set('validatedDiscountCode', this.discountCode);
          return;
        }

        this.set('discountCode', this.discountCode.toUpperCase());
        this.ticket.validateDiscountCode(this.discountCode);
        this.set('validatedDiscountCode', this.discountCode);
      },
      tenderTypeKeyboardSelect: function tenderTypeKeyboardSelect(tenderTypeGuid, event) {
        var keyCodes = [37, 38, 39, 40]; //Arrow Keys

        if (keyCodes.includes(event.keyCode)) {
          this.send('tenderTypeSelect', tenderTypeGuid);
        }
      },
      tenderTypeSelect: function tenderTypeSelect(tenderTypeGuid) {
        if (tenderTypeGuid === 'SAVED_CARD') {
          this.set('ticket.tenderTypeGuid', this.get('tenders.credit.id'));
          this.set('customerService.selectedPaymentType', 'SAVED_CARD');
        } else {
          this.set('ticket.tenderTypeGuid', tenderTypeGuid);
          this.set('customerService.selectedPaymentType', tenderTypeGuid);
        }

        this.set('securityCode', undefined);
      },
      customTipSelect: function customTipSelect() {
        if (this.get('ticket.selectedTip') === 'custom') {
          this.ticket.setProperties({
            selectedTip: undefined,
            selectedTipCustom: undefined
          });
        } else {
          this.set('ticket.selectedTip', 'custom');
          this.$('.ui.basic.label > .ui.input > input').focus();
        }
      },
      selectTip: function selectTip(tipIndex) {
        this.set('ticket.selectedTip', this.get('ticket.selectedTip') === tipIndex ? undefined : tipIndex);
      },
      goToPaymentMethod: function goToPaymentMethod() {
        var _this6 = this;

        if (!this.placeOrderInitiated) {
          this.set('placeOrderInitiated', true);
          this.validateOrder().then(function () {
            return _this6.saveCustomerInfo();
          }).then(function () {
            return _this6.authorize();
          }).then(function () {
            return _this6.set('step', 'payment_method');
          }).finally(function () {
            return _this6.set('placeOrderInitiated', false);
          });
        }
      },
      payWithCard: function payWithCard() {
        this.setProperties({
          step: 'credit_card',
          paymentType: 'MANUAL_ENTRY'
        });
      },
      submitOrder: function submitOrder(i4goTokenResponse) {
        var _this7 = this;

        if (this.loading || this.get('ticket.calculateErrors')) {
          return;
        }

        this.set('step', 'submitting_order');
        var orderPromise = (0, _rsvp.resolve)();

        if (this.get('ticket.tenderTypeGuid') === this.get('tenders.credit.id') && this.get('customerService.selectedPaymentType') !== 'SAVED_CARD' && !this.isFullyPayByGiftCard) {
          orderPromise = this.payment.validateIframeResponse(i4goTokenResponse);
        }

        orderPromise.then(function () {
          var data = {
            cart: _this7.ticket.getCart(),
            orderTypeRef: _this7.get('ticket.orderTypeGuid'),
            customer: _this7.customerService.getCustomerInfoForOrder(_this7.customer),
            tenderRef: _this7.get('ticket.tenderTypeGuid'),
            grandTotal: _this7.get('ticket.totalWithTip'),
            tipAmount: _this7.get('ticket.tipAmount'),
            specialRequest: _this7.specialRequest,
            tableNotes: _this7.get('ticket.tableNotes'),
            invoiceNumber: _this7.get('i4go.invoiceNumber'),
            // will be undefined for cash
            paymentType: _this7.paymentType,
            // will be undefined for cash
            isDeferred: _this7.get('ticket.isDeferred'),
            deferredUntil: _this7.orderTimeUtc,
            loyaltyMemberRef: _this7.get('loyaltyMember.memberRecord.id'),
            isDoorDashDriveDelivery: _this7.get('ticket.orderType.isServedByDoorDashDrive'),
            isContactlessDelivery: _this7.isContactlessDeliveryEnabled ? _this7.get('ticket.isContactlessDelivery') : false
          };

          if (_this7.featureFlag.isEnabled('sto-dsp-integration') && _this7.get('ticket.orderType.id') === 'DELIVERY') {
            data.deliveryServedWithDSP = _this7.get('ticket.orderType.deliveryServedWithDSP');
            data.isDoorDashDriveDelivery = false;

            if (_this7.get('ticket.orderType.deliveryServedWithDSP') && _this7.get('ticket.preferredQuoteId')) {
              data.preferredQuoteId = _this7.get('ticket.preferredQuoteId');
              data.expectedAt = _this7.get('ticket.isDeferred') ? _this7.get('ticket.expectedTimeUtc') : (0, _timeUtil.addMinutes)(new Date(), _this7.ticket.getLeadTimeInMinutes(_this7.get('ticket.orderType'))).toISOString();
            }
          } // CC tokenization data, will not exist for cash


          if (i4goTokenResponse) {
            if (i4goTokenResponse.i4go_uniqueid) {
              // eslint-disable-next-line camelcase
              data.i4go_uniqueid = i4goTokenResponse.i4go_uniqueid;
            }

            if (i4goTokenResponse.i4go_postalcode) {
              data.billingZip = i4goTokenResponse.i4go_postalcode;
            }

            if (i4goTokenResponse.i4go_extendedcarddata) {
              data.extendedCardData = i4goTokenResponse.i4go_extendedcarddata;
            }

            if (i4goTokenResponse.i4go_streetaddress) {
              data.billingStreetAddress = i4goTokenResponse.i4go_streetaddress;
            }
          }

          if (_this7.get('ticket.giftCardAppliedAmount')) {
            data.giftId = _this7.get('ticket.giftCardNumber');
            data.giftAmount = _this7.get('ticket.giftCardAppliedAmount');
          }

          if (_this7.get('customerService.selectedPaymentType') === 'SAVED_CARD' && !_this7.isFullyPayByGiftCard) {
            if (_this7.featureFlag.isEnabled('sto-cvv-validation')) {
              data.cvvCode = _this7.get('securityCode');
              data.paymentMethodRef = _this7.get('paymentMethod.id');
            } else {
              // eslint-disable-next-line camelcase
              data.i4go_uniqueid = _this7.get('paymentMethod.i4goUniqueId');
            }
          }

          if (_this7.hasValidReferralDetails) {
            data.referral = {
              source: _this7.get('ticket.referralSource'),
              guid: _this7.get('ticket.referralGuid')
            };
          }

          var url = "".concat(_environment.default.host, "/api/v2/public/").concat(_this7.get('ticket.locationRef'), "/order");

          if (_this7.featureFlag.isEnabled('skytab-online-ooc-place-order')) {
            url = "".concat(_environment.default.oocHost, "/online-ordering/mars/api/v2/stores/").concat(_this7.get('ticket.locationRef'), "/order");
          }

          if (_this7.featureFlag.isEnabled('sto-nested-mods')) {
            if (_this7.authorizations) {
              data.invoiceNumber = undefined;
              data.invoiceNumbers = _this7.authorizations.map(function (authorization) {
                return authorization.invoiceNumber;
              });
            }

            url = "".concat(_environment.default.host, "/api/v3/public/").concat(_this7.get('ticket.locationRef'), "/order");
          }

          return _this7.ajax.raw(url, {
            type: 'POST',
            data: data
          });
        }).then(function (_ref2) {
          var response = _ref2.response;
          return _this7.set('ticket.submitResponse', response);
        }).catch(function (e) {
          return _this7.set('ticket.submitResponse', e && e.payload ? e.payload : {
            status: 'error'
          });
        }).finally(function () {
          return _this7.completeOrderProcess(i4goTokenResponse);
        });
      },
      placeOrder: function placeOrder() {
        var _this8 = this;

        if (!this.placeOrderInitiated) {
          this.set('placeOrderInitiated', true);
          this.validateOrder().then(function () {
            _this8.saveCustomerInfo();
          }).then(function () {
            if (_this8.get('customerService.selectedPaymentType') === 'SAVED_CARD' || _this8.get('ticket.giftCardAppliedAmount')) {
              return _this8.authorize();
            }
          }).then(function () {
            _this8.send('submitOrder');
          }).finally(function () {
            return _this8.set('placeOrderInitiated', false);
          });
        }
      },
      customerPhoneChanged: function customerPhoneChanged() {
        this.loyaltyMember.customerPhoneChanged(this.customer);
      },
      checkGiftCardBalance: function checkGiftCardBalance() {
        var _this9 = this;

        if (!this.giftCardNumber) {
          this.ticket.setProperties({
            giftCardErrors: 'Gift Card Code is not valid. Must be between 1 and 32 characters in length',
            giftCardBalance: undefined
          });
          return;
        }

        if (this.giftCardNumber !== this.validatedGiftCardNumber) {
          this.ticket.getGiftCardBalance(this.giftCardNumber).then(function (balance) {
            _this9.ticket.setProperties({
              giftCardErrors: undefined,
              giftCardNumber: _this9.giftCardNumber,
              giftCardBalance: balance
            });
          }).catch(function (message) {
            _this9.ticket.setProperties({
              giftCardErrors: message,
              giftCardBalance: undefined
            });
          }).finally(function () {
            _this9.set('validatedGiftCardNumber', _this9.giftCardNumber);
          });
        }
      },
      applyGiftCardBalance: function applyGiftCardBalance() {
        this.ticket.redeemGiftCardAmount((0, _currencyConverter.toCents)(this.giftAmountToRedeem));
        this.get('ticket').calculate();

        if (this.isFullyPayByGiftCard) {
          this.set('ticket.tenderTypeGuid', this.get('tenders.credit.id'));
          this.set('customerService.selectedPaymentType', this.paymentMethod ? 'SAVED_CARD' : this.tenders.credit.id);
        }
      },
      clearGiftCardNumber: function clearGiftCardNumber() {
        if (this.get('isFullyPayByGiftCard') && this.get('customerService.selectedPaymentType') === this.get('tenders.cash.id')) {
          this.set('ticket.tenderTypeGuid', this.get('tenders.cash.id'));
        }

        this.setProperties({
          giftCardNumber: undefined,
          validatedGiftCardNumber: undefined,
          giftAmountToRedeem: undefined
        });
        this.ticket.clearGiftCardDetails();
      },
      clearGiftCardAppliedBalance: function clearGiftCardAppliedBalance() {
        if (this.get('isFullyPayByGiftCard') && this.get('customerService.selectedPaymentType') === this.get('tenders.cash.id')) {
          this.set('ticket.tenderTypeGuid', this.get('tenders.cash.id'));
        }

        this.set('ticket.giftCardAppliedAmount', undefined);
        this.get('ticket').calculate();
        this.setProperties({
          giftAmountToRedeem: undefined,
          giftCardErrors: undefined
        });
      },
      confirmSaveCreditCardInfo: function confirmSaveCreditCardInfo(value) {
        if (!value) {
          return this.submitResponse();
        } // find the current payment method from the store.


        var currentPaymentMethods = this.store.peekAll('payment-method').filterBy('locationId', this.get('location.locationId'));
        var newPaymentMethod = this.store.createRecord('payment-method', {
          locationId: this.get('location.locationId'),
          i4goUniqueId: this.get('i4goTokenResponse.i4go_uniqueid'),
          maskedCardNumber: (0, _maskCardNumber.default)(this.get('i4goTokenResponse.i4go_maskedcard')),
          expiration: "".concat(this.get('i4goTokenResponse.i4go_expirationmonth'), "/").concat(this.get('i4goTokenResponse.i4go_expirationyear').slice(2))
        }); // Let stored payment method be selected in the next order.

        this.set('customerService.selectedPaymentType', undefined);
        newPaymentMethod.save().then(function () {
          return currentPaymentMethods.forEach(function (currentPaymentMethod) {
            return currentPaymentMethod.unloadRecord();
          });
        }).catch(function () {
          return (0, _rsvp.resolve)();
        });
        this.submitResponse();
      }
    }
  });

  _exports.default = _default;
});