define("lh-public-olo/serializers/cart-item", ["exports", "@ember-data/serializer/rest", "lh-public-olo/serializers/application"], function (_exports, _rest, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      modifiers: {
        embedded: 'always'
      }
    },
    serialize: function serialize(snapshot) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var json = this._super.apply(this, arguments);

      if (options.tree) {
        json.modifiers = json.modifiers.filter(function (modifier) {
          if (!modifier.parentModifier) {
            delete modifier.parentModifier;
            return true;
          }

          var parent = json.modifiers.find(function (_ref) {
            var ref = _ref.ref;
            return ref === modifier.parentModifier;
          });
          parent.modifiers ? parent.modifiers.push(modifier) : parent.modifiers = [modifier];
          delete modifier.parentModifier;
          return false;
        });
      }

      return json;
    }
  });

  _exports.default = _default;
});