define("lh-public-olo/components/change-password", ["exports", "@ember/component", "lh-public-olo/config/environment", "rsvp", "@ember/runloop", "@ember/service", "ember-cp-validations", "lh-public-olo/utils/validation-check"], function (_exports, _component, _environment, _rsvp, _runloop, _service, _emberCpValidations, _validationCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Password'
    })],
    newPassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'New Password'
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 128,
      description: 'New Password'
    })],
    confirmPassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Confirm Password'
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'newPassword',
      message: 'New Password and Confirm Password do not match',
      description: 'Confirm Password'
    })]
  });

  var _default = _component.default.extend(Validations, {
    ajax: (0, _service.inject)(),
    session: (0, _service.inject)(),
    showErrors: false,
    isLoading: false,
    passwordUpdateSuccess: false,
    signIn: function signIn(email, password) {
      return this.session.authenticate('authenticator:jwt-custom', {
        email: email,
        password: password
      }).catch(function (e) {
        var error = e.json;
        var loginError = error.message || 'Unknown Error';
        return (0, _rsvp.reject)({
          loginError: loginError
        });
      });
    },
    updatePassword: function updatePassword() {
      var _this = this;

      var url = "".concat(_environment.default.oocHost, "/online-ordering/mars/api/v2/customer/password");
      return this.ajax.put(url, {
        data: {
          currentPassword: this.password,
          newPassword: this.newPassword
        }
      }).then(function () {
        _this.set('passwordUpdateSuccess', true);

        _this.set('email', _this.get('session.data.authenticated.tokenData.user.email'));

        _this.set('session.skipRedirectOnInvalidation', true);

        return _this.session.invalidate(true);
      }).then(function () {
        (0, _runloop.later)(function () {
          _this.set('passwordUpdateSuccess', false);

          return _this.signIn(_this.email, _this.newPassword);
        }, 3000);
      }).catch(function (e) {
        var updateError = 'Something went wrong, please try again';

        if (e.payload && e.payload.errors && e.payload.errors.length && e.payload.errors[0].friendlyError) {
          updateError = e.payload.errors[0].friendlyError;
        }

        _this.set('passwordUpdateError', updateError);
      });
    },
    actions: {
      changePassword: function changePassword() {
        var _this2 = this;

        this.set('isLoading', true);
        this.setProperties({
          showErrors: false,
          passwordUpdateError: undefined
        });
        this.validate().then(_validationCheck.default).then(function () {
          return _this2.updatePassword();
        }).catch(function (_ref) {
          var validations = _ref.validations,
              passwordUpdateError = _ref.passwordUpdateError;

          _this2.setProperties({
            showErrors: validations,
            passwordUpdateError: passwordUpdateError
          });
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});