define("lh-public-olo/utils/currency-converter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toCents = toCents;
  _exports.toDollars = toDollars;
  _exports.formatCentsToDollars = formatCentsToDollars;

  /**
   * Convert dollars to cents
   *
   * @param {number} dollars - Amount in dollars
   * @returns {number} Dollars amount in cents
   */
  function toCents(dollars) {
    if (isNaN(dollars)) {
      return 0;
    }

    return Math.round(parseFloat(dollars) * 100);
  }
  /**
   * Convert cents to dollar
   *
   * @param {number} cents - Amount in cents
   * @returns {number} Cents amount in dollars
   */


  function toDollars(cents) {
    return Math.round(cents) / 100;
  }
  /**
   * Convert cents to dollars and format the result with two decimal places
   *
   * @param {number} cents - Amount in cents
   * @returns {string} cents amount in dollars as a string with two decimal places
   */


  function formatCentsToDollars(cents) {
    var dollars = Math.round(cents) / 100;
    return dollars.toFixed(2);
  }
});