define("lh-public-olo/components/general-user-details", ["exports", "@ember/component", "@ember/service", "lh-public-olo/utils/validation-check", "rsvp"], function (_exports, _component, _service, _validationCheck, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['general-user-details'],
    customerService: (0, _service.inject)('customer'),
    isLoading: false,
    willDestroyElement: function willDestroyElement() {
      this.customer.rollbackAttributes();
    },
    saveCustomer: function saveCustomer() {
      var _this = this;

      return this.customer.save().then(function () {
        _this.customerService.updateUsername(_this.customer);
      }).catch(function (e) {
        var errorMessage = 'Something went wrong, please try again';

        if (e.errors && e.errors.length && e.errors[0].friendlyError) {
          errorMessage = e.errors[0].friendlyError;
        }

        return (0, _rsvp.reject)({
          errorMessage: errorMessage
        });
      });
    },
    actions: {
      savePersonalInfo: function savePersonalInfo() {
        var _this2 = this;

        if (this.isLoading) {
          return;
        }

        this.setProperties({
          showErrors: false,
          savePersonalInfoErrors: undefined
        });
        this.set('isLoading', true);
        this.customer.validate().then(_validationCheck.default).then(function () {
          return _this2.saveCustomer();
        }).catch(function (_ref) {
          var validations = _ref.validations,
              errorMessage = _ref.errorMessage;

          _this2.setProperties({
            showErrors: !!validations,
            savePersonalInfoErrors: errorMessage
          });

          return (0, _rsvp.reject)();
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});