define("lh-public-olo/serializers/menu-schedule", ["exports", "@ember-data/serializer/rest", "lh-public-olo/serializers/application"], function (_exports, _rest, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    normalize: function normalize(model, hash) {
      if (hash.hours) {
        // return only a single relevant hours item from hours array
        var now = new Date();
        var availableHour = hash.hours.find(function (hour) {
          return now > new Date(hour.startDateTime) && now < new Date(hour.endDateTime);
        });

        if (!availableHour) {
          var sortedHours = hash.hours.sort(function (a, b) {
            return new Date(a.startDateTime) - new Date(b.startDateTime);
          });
          availableHour = sortedHours[0];
        }

        hash.hours = [availableHour];

        for (var i = 0; i <= hash.hours.length - 1; i++) {
          // construct unique ids for hours
          hash.hours[i].id = "".concat(hash.ref, "---").concat(hash.hours[i].dayOfWeek);
        }
      }

      return this._super.apply(this, arguments);
    },
    attrs: {
      hours: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});