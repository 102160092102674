define("lh-public-olo/models/modifier", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    price: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    percent: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    modifierSets: (0, _model.hasMany)('modifier-set', {
      inverse: 'parentModifiers'
    })
  });

  _exports.default = _default;
});