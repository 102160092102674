define("lh-public-olo/adapters/customer", ["exports", "lh-public-olo/adapters/application", "lh-public-olo/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.oocHost,
    namespace: "online-ordering/mars/api/v2",
    //This can be replaced with methodForRequest() or something similar when we are upgrading the ember-data version
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var url = this.buildURL(type.modelName, null, snapshot, 'updateRecord');
      return this.ajax(url, 'PATCH', {
        data: data
      });
    },
    pathForType: function pathForType(modelName) {
      return modelName;
    }
  });

  _exports.default = _default;
});