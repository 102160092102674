define("lh-public-olo/components/item-modal/modifier-set", ["exports", "@ember/object/computed", "@ember/service", "@ember/component"], function (_exports, _computed, _service, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['modifier-set'],
    store: (0, _service.inject)(),
    selected: (0, _computed.filter)('cartItem.modifiers', function (mod) {
      return mod.get('modifierSetRef') === this.get('set.id');
    }),
    selectedIds: (0, _computed.mapBy)('selected', 'modifierRef'),
    actions: {
      handleClick: function handleClick(modifier, set, checked, e) {
        if (set.get('oneOnly') && set.get('isRequired')) {
          this.send('modifierRadioSelect', modifier, set);
        } else {
          if (e.target !== e.currentTarget) {
            return;
          }

          this.send('modifierSelect', modifier, set, checked);
        }
      },
      modifierSelect: function modifierSelect(modifier, set, checked) {
        // handle case where oneOnly is true, but maxModifiersPerSet is > 1
        var maxModifiersPerSet = set.get('oneOnly') ? 1 : set.get('maxModifiersPerSet');

        if (this.get('selected.length') !== maxModifiersPerSet || !checked) {
          this.sendAction('modifierSelect', checked, modifier, set);

          if (modifier.get('modifierSets.length')) {
            this.send('nestedModifierEdit', modifier.get('id'));
          }
        } else {
          // I tried using the checked property to be more ember-y
          // but apparently that doesn't work the way I think it does
          this.$("[id=\"".concat(modifier.get('id')).concat(set.get('id')).concat(this.get('cartItem.id'), "\"]")).checkbox('set unchecked');
        }
      },
      modifierRadioSelect: function modifierRadioSelect(modifier, set) {
        // remove old selected modifier in this set
        var oldRecord = this.get('selected.firstObject');

        if (oldRecord) {
          this.sendAction('modifierSelect', false, oldRecord.get('modifier'), oldRecord.get('modifierSet'));
        } // use existing action to add new modifier


        this.sendAction('modifierSelect', true, modifier, set);

        if (modifier.get('modifierSets.length')) {
          this.send('nestedModifierEdit', modifier.get('id'));
        }
      },
      nestedModifierEdit: function nestedModifierEdit(modifierRef) {
        var selected = this.selected.findBy('modifierRef', modifierRef);

        if (selected) {
          this.sendAction('selectNestedModifier', selected);
        }
      }
    }
  });

  _exports.default = _default;
});