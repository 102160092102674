define("lh-public-olo/templates/components/error-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "umxWFXbg",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@closable\",\"@autofocus\",\"@duration\"],[\"error-modal\",false,false,200]],[[\"default\"],[[[[1,\"\\n\\t\"],[10,0],[14,0,\"content\"],[12],[1,\"\\n\\t\\t\"],[10,\"h3\"],[12],[1,\"Sorry to Interrupt\"],[13],[1,\"\\n\\t\\t\"],[10,2],[12],[18,1,null],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\\t\"],[10,0],[14,0,\"actions\"],[12],[1,\"\\n\\t\\t\"],[10,0],[12],[1,\"\\n\\t\\t\\t\"],[11,\"button\"],[24,0,\"ui small inverted black button\"],[4,[38,2],[[30,0],\"reloadPage\"],null],[12],[1,\"Try Refreshing\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"ui-modal\",\"yield\",\"action\"]]",
    "moduleName": "lh-public-olo/templates/components/error-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});