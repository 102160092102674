define("lh-public-olo/templates/error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Z82QeqXx",
    "block": "[[[41,[30,0,[\"hasLocationDisableError\"]],[[[1,\"\\t\"],[8,[39,1],null,[[\"@activeStoreClosureMessage\",\"@isStoreClosureActive\"],[[28,[37,2],[[33,3,[\"errors\",\"firstObject\"]]],null],true]],null],[1,\"\\n\"]],[]],[[[1,\"\\t\"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[33,3,[\"errors\"]],[[[42,[28,[37,6],[[28,[37,6],[[33,3,[\"errors\"]]],null]],null],null,[[[1,\"\\t\\t\\t\\t\"],[1,[28,[35,2],[[30,1]],null]],[1,\"\\n\"]],[1]],null]],[]],[[[41,[33,3,[\"message\"]],[[[1,\"\\t\\t\\t\"],[1,[33,3,[\"message\"]]],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\\t\"],[1,[34,3]],[1,\"\\n\\t\\t\"]],[]]]],[]]],[1,\"\\t\"]],[]]]]],[1,\"\\n\"]],[]]]],[\"error\"],false,[\"if\",\"store-closure-modal\",\"error-message\",\"model\",\"error-modal\",\"each\",\"-track-array\"]]",
    "moduleName": "lh-public-olo/templates/error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});