define("lh-public-olo/templates/components/ui-calendar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OjQscY+V",
    "block": "[[[10,0],[14,0,\"ui input right icon\"],[12],[1,\"\\n\\t\"],[10,\"i\"],[15,0,[29,[[52,[28,[37,1],[[33,2],\"time\"],null],\"clock\",\"calendar\"],\" icon\"]]],[12],[13],[1,\"\\n\\t\"],[8,[39,3],[[16,\"placeholder\",[36,4]]],[[\"@value\",\"@readonly\"],[[28,[37,5],[[33,6]],null],true]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"eq\",\"type\",\"input\",\"placeholder\",\"unbound\",\"value\"]]",
    "moduleName": "lh-public-olo/templates/components/ui-calendar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});