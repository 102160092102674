define("lh-public-olo/validators/postal-code", ["exports", "ember-cp-validations/validators/base", "lh-public-olo/utils/country-regions"], function (_exports, _base, _countryRegions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var postalCode = _base.default.extend({
    validate: function validate(value, options) {
      var countryCode = options.countryCode,
          allowBlank = options.allowBlank;

      if (allowBlank && !value) {
        return true;
      }

      if ((0, _countryRegions.isValidPostalCode)(countryCode, value)) {
        return true;
      }

      return this.createErrorMessage('invalid', value, options);
    }
  });

  var _default = postalCode;
  _exports.default = _default;
});