define("lh-public-olo/models/menu-schedule-hour", ["exports", "@ember-data/model", "@ember/object", "@ember/service", "lh-public-olo/utils/time-util"], function (_exports, _model, _object, _service, _timeUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  var _default = _model.default.extend({
    ticket: (0, _service.inject)(),
    menuScheduleRef: (0, _model.belongsTo)('menu-schedule'),
    dayOfWeek: (0, _model.attr)('string'),
    startTime: (0, _model.attr)('string', {
      defaultValue: '00:00:00'
    }),
    endTime: (0, _model.attr)('string', {
      defaultValue: '23:59:59'
    }),
    startDateTime: (0, _model.attr)('string'),
    endDateTime: (0, _model.attr)('string'),
    displayDayOfWeek: (0, _object.computed)('dayOfWeek', function () {
      // format day of week to use capitalized words
      var dayOfWeek = this.dayOfWeek;
      var dowFormatted = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1).toLowerCase(); // display empty if it's today

      if (this.isAvailable || days[new Date().getDay()] === dowFormatted) {
        return '';
      }

      return dowFormatted;
    }),
    displayStartTime: (0, _object.computed)('startTime', function () {
      return prettyHours(this.startTime);
    }),
    displayEndTime: (0, _object.computed)('endTime', 'endDateTime', 'startDateTime', function () {
      return prettyHours(this.endTime) + prettyDate(this.endDateTime, this.startDateTime, this.get('ticket.timeZoneOffset'));
    }),
    isAvailable: (0, _object.computed)('startDateTime', 'endDateTime', function () {
      var now = new Date();
      return now > new Date(this.startDateTime) && now < new Date(this.endDateTime);
    })
  });
  /**
   * Converts normal time format into american time format
   * Examples
   * 20:00:00 => 8 PM
   * 08:00:00 => 8 AM
   * 08:30:30 => 8:30 AM
   * 00:00:00 => 12 AM
   *
   * @param {string} time - time
   * @returns {string} pretty time
   */


  _exports.default = _default;

  function prettyHours(time) {
    var _time$split = time.split(':'),
        _time$split2 = _slicedToArray(_time$split, 2),
        hour = _time$split2[0],
        minute = _time$split2[1];

    var hourInt = parseInt(hour) === 24 ? 0 : parseInt(hour);
    var minuteInt = parseInt(minute);
    var prettyTime = '';

    if (hourInt === 0) {
      prettyTime += '12';
    } else if (hourInt > 12) {
      prettyTime += "".concat(hourInt - 12);
    } else {
      prettyTime += "".concat(hourInt);
    }

    if (minuteInt > 0) {
      prettyTime += ":".concat(minute);
    }

    prettyTime += " ".concat(hourInt >= 12 ? 'PM' : 'AM');
    return prettyTime;
  }
  /**
   * Retrieves the abbreviated day of the week
   * Examples
   * Monday => Mon
   * Tuesday => Tue
   * Wednesday => Wed
   * Thursday => Thu
   * Friday => Fri
   * Saturday => Sat
   * Sunday => Sun
   *
   * @param {string} endDateUtc - endDateUtc
   * @param {string} startDateUtc - startDateUtc
   * @param {number} timeZoneOffset - timeZoneOffset
   * @returns {string} pretty Date
   */


  function prettyDate(endDateUtc, startDateUtc, timeZoneOffset) {
    var startDate = new Date((0, _timeUtil.getAdjustedTime)(timeZoneOffset, new Date(startDateUtc))).getDay();
    var endDate = new Date((0, _timeUtil.getAdjustedTime)(timeZoneOffset, new Date(endDateUtc))).getDay();

    if (endDate !== startDate) {
      return " (".concat(days[endDate].substring(0, 3), ")");
    }

    return '';
  }
});