define("lh-public-olo/controllers/error", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    hasLocationDisableError: (0, _object.computed)('model.errors', function () {
      var error = this.model.errors.findBy('code', 'LOCINA');

      if (error) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});