define("lh-public-olo/serializers/payment-method", ["exports", "lh-public-olo/serializers/application", "@ember/polyfills"], function (_exports, _application, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.locationName;
      return json;
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      (0, _polyfills.assign)(hash, this.serialize(record, options));
    }
  });

  _exports.default = _default;
});