define("lh-public-olo/templates/location/menu/add-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+xvm8n1O",
    "block": "[[[8,[39,0],null,[[\"@named\",\"@send\"],[\"item-modal\",[50,\"item-modal\",0,null,[[\"item\",\"cartItem\",\"modifierSelect\",\"addToOrder\",\"cancelOrder\"],[[33,2,[\"item\"]],[33,2,[\"cartItem\"]],[28,[37,3],[[30,0],\"modifierSelect\"],null],[28,[37,3],[[30,0],\"addToOrder\"],null],[28,[37,3],[[30,0],\"cancelOrder\"],null]]]]]],null],[1,\"\\n\\n\"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"to-elsewhere\",\"component\",\"model\",\"action\",\"-outlet\"]]",
    "moduleName": "lh-public-olo/templates/location/menu/add-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});