define("lh-public-olo/helpers/usd", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.usd = usd;
  _exports.default = void 0;

  function usd(params
  /*, hash*/
  ) {
    var value;

    if ((0, _utils.isEmpty)(params[0]) || isNaN(params[0])) {
      // Fallback to zero whenever provided value is NaN or empty
      value = 0;
    } else {
      value = parseFloat(params[0]) / 100;
    }

    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
  }

  var _default = (0, _helper.helper)(usd);

  _exports.default = _default;
});