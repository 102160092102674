define("lh-public-olo/models/item", ["exports", "@ember-data/model", "@ember/service", "@ember/object"], function (_exports, _model, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ticket: (0, _service.inject)(),
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    imageUrl800x800: (0, _model.attr)('string'),
    price: (0, _model.attr)('number'),
    modifierSets: (0, _model.hasMany)('modifier-set'),
    stockStatus: (0, _model.attr)('string'),
    isItemUnavailable: (0, _object.computed)('stockStatus', function () {
      return this.ticket.isQuantityOnHandFeatureEnabled && this.stockStatus === 'OUT_OF_STOCK';
    })
  });

  _exports.default = _default;
});