define("lh-public-olo/templates/location/menu/order-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cZnYjp5x",
    "block": "[[[8,[39,0],null,[[\"@named\",\"@send\"],[\"item-modal\",[50,\"order-type-modal\",0,null,[[\"location\",\"orderTypes\",\"customer\",\"isNewOrderModal\",\"save\",\"close\"],[[33,2,[\"location\"]],[33,2,[\"orderTypes\"]],[33,2,[\"customer\"]],[28,[37,3],[[33,4,[\"orderTypeGuid\"]]],null],[28,[37,5],[[30,0],\"save\"],null],[28,[37,5],[[30,0],\"close\"],null]]]]]],null],[1,\"\\n\\n\"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"to-elsewhere\",\"component\",\"model\",\"not\",\"ticket\",\"action\",\"-outlet\"]]",
    "moduleName": "lh-public-olo/templates/location/menu/order-settings.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});