define("lh-public-olo/controllers/location/checkout/sold-out-warning", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    ticket: (0, _service.inject)(),
    actions: {
      close: function close() {
        if (this.ticket.items.length) {
          this.transitionToRoute('location.checkout');
          return;
        }

        this.transitionToRoute('location.menu');
      }
    }
  });

  _exports.default = _default;
});