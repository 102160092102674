define("lh-public-olo/routes/location/checkout", ["exports", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ticket: (0, _service.inject)(),
    session: (0, _service.inject)(),
    payment: (0, _service.inject)(),
    loyaltyMember: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    beforeModel: function beforeModel() {
      var _this = this;

      if (this.get('ticket.items.length') === 0 || !this.get('ticket.menuSchedule.isAvailable')) {
        this.transitionTo('location.menu');
        return;
      }

      return this.ticket.calculate(true).catch(function (e) {
        if (e === 'ITMOOSTK') {
          _this.transitionTo('location.menu.sold-out-warning');

          return;
        }

        return (0, _rsvp.reject)();
      });
    },
    model: function model() {
      var _this2 = this;

      var location = this.modelFor('location');
      return (0, _rsvp.hash)({
        loyaltyProgram: this.store.findAll('loyalty/program').then(function (loyaltyPrograms) {
          return loyaltyPrograms.get('firstObject');
        }).catch(function () {// swallow as we don't want to break site if loyalty fails
        }),
        paymentMethods: this.getPaymentMethods(location.get('locationId'))
      }).then(function (_ref) {
        var loyaltyProgram = _ref.loyaltyProgram,
            paymentMethods = _ref.paymentMethods;
        return (0, _rsvp.hash)({
          location: location,
          loyaltyProgram: loyaltyProgram,
          customer: _this2.customerService.getCustomer(),
          paymentMethods: paymentMethods,
          tenders: {
            cash: location.get('tenders').findBy('id', 'CASH'),
            credit: location.get('tenders').findBy('id', 'NON_CASH')
          }
        });
      });
    },
    getPaymentMethods: function getPaymentMethods(locationId) {
      if (!this.get('session.isAuthenticated')) {
        return (0, _rsvp.resolve)([]);
      }

      return this.store.query('payment-method', {
        locationId: locationId
      }).catch(function () {
        return (0, _rsvp.resolve)([]);
      });
    },
    afterModel: function afterModel(model) {
      // set selectedPaymentType and tenderTypeGuid to Saved Payment Type when available or Credit by default
      if (!this.get('customerService.selectedPaymentType')) {
        this.set('ticket.tenderTypeGuid', model.tenders.credit.get('id'));

        if (model.paymentMethods && model.paymentMethods.get('length')) {
          this.set('customerService.selectedPaymentType', 'SAVED_CARD');
        } else {
          this.set('customerService.selectedPaymentType', model.tenders.credit.get('id'));
        }
      }

      if (model.loyaltyProgram) {
        this.loyaltyMember.load();
      }

      return this.ticket.reApplyGiftCardAmount();
    },
    actions: {
      willTransition: function willTransition() {
        // when the user leaves this route, clear the calculations
        // because if they edit their ticket, the calculations will be wrong
        return true;
      }
    }
  });

  _exports.default = _default;
});