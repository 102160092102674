define("lh-public-olo/components/quantity-select", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['ui', 'action', 'input', 'quantity-select'],
    validate: function validate() {
      if (this.value < 1) {
        this.set('value', 1);
      }

      if (!Number.isInteger(this.value)) {
        this.set('value', Math.floor(this.value));
      }
    },
    actions: {
      subtract: function subtract() {
        if (this.value > 1) {
          this.decrementProperty('value');
        }

        this.validate();
      },
      add: function add() {
        this.incrementProperty('value');
        this.validate();
      }
    }
  });

  _exports.default = _default;
});