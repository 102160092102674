define("lh-public-olo/models/payment-method", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    locationId: (0, _model.attr)('number'),
    locationName: (0, _model.attr)('string'),
    maskedCardNumber: (0, _model.attr)('string'),
    expiration: (0, _model.attr)('string'),
    i4goUniqueId: (0, _model.attr)('string')
  });

  _exports.default = _default;
});