define("lh-public-olo/components/account/update-password", ["exports", "@ember/component", "@ember/service", "@ember/runloop", "rsvp", "ember-cp-validations", "lh-public-olo/utils/validation-check", "lh-public-olo/config/environment"], function (_exports, _component, _service, _runloop, _rsvp, _emberCpValidations, _validationCheck, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'New Password'
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 128,
      description: 'New Password'
    })],
    confirmPassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Confirm Password'
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 128,
      description: 'Confirm Password'
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'New Password and Confirm Password do not match',
      description: 'Confirm Password'
    })]
  });

  var _default = _component.default.extend(Validations, {
    ajax: (0, _service.inject)(),
    session: (0, _service.inject)(),
    classNames: ['user-account-view'],
    isPasswordUpdated: false,
    isLoading: false,
    showErrors: false,
    didRender: function didRender() {
      if (!this.token) {
        this.set('passwordUpdateError', "Invalid token. Please request password reset again and click on the reset password link in the received email.");
      }
    },
    updatePassword: function updatePassword() {
      this.set('isLoading', true);
      var url = "".concat(_environment.default.oocHost, "/online-ordering/mars/api/v2/customer/reset-password");
      return this.ajax.post(url, {
        data: {
          token: this.token,
          password: this.password
        }
      }).catch(function (e) {
        var passwordUpdateError = 'Something went wrong, please try again';

        if (e.payload && e.payload.errors && e.payload.errors.length && e.payload.errors[0].friendlyError) {
          passwordUpdateError = e.payload.errors[0].friendlyError;
        }

        return (0, _rsvp.reject)({
          passwordUpdateError: passwordUpdateError
        });
      });
    },
    actions: {
      updatePassword: function updatePassword() {
        var _this = this;

        if (this.isLoading && !this.token) {
          return;
        }

        this.setProperties({
          showErrors: false,
          passwordUpdateError: undefined
        });
        this.validate().then(_validationCheck.default).then(function () {
          return _this.updatePassword();
        }).then(function () {
          _this.set('isPasswordUpdated', true);
        }).then(function () {
          var SIGN_IN_REDIRECT_DELAY = 3000;

          _this.set('session.skipRedirectOnInvalidation', true);

          _this.get('session').invalidate();

          (0, _runloop.later)(function () {
            _this.redirectToSignIn();
          }, SIGN_IN_REDIRECT_DELAY);
        }).catch(function (_ref) {
          var validations = _ref.validations,
              passwordUpdateError = _ref.passwordUpdateError;

          _this.setProperties({
            showErrors: validations,
            passwordUpdateError: passwordUpdateError
          });
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      close: function close() {
        this.close();
      },
      redirectToSignIn: function redirectToSignIn() {
        this.redirectToSignIn();
      }
    }
  });

  _exports.default = _default;
});