define("lh-public-olo/serializers/customer", ["exports", "@ember-data/serializer/rest", "lh-public-olo/serializers/application", "@ember/polyfills"], function (_exports, _rest, _application, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'guid',
    attrs: {
      deliveryAddresses: {
        embedded: 'always'
      }
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.email;
      delete json.deliveryAddresses;
      return json;
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      (0, _polyfills.assign)(hash, this.serialize(record, options));
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      // TODO: Currently PATCH api is sending a different response than the GET API.
      // So we are failing to assign personalInfo in PATCH API response.
      // Adding this condition for now to temporarly prevent the failure.
      // We should remove this condition when we have a fix for the PATCH API.
      if (payload.customer) {
        (0, _polyfills.assign)(payload.customer, payload.customer.personalInfo);
        delete payload.customer.personalInfo;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});