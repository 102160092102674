define("lh-public-olo/components/error-modal", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      this.$('.modal').modal('show');
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.modal').modal('hide');
    },
    actions: {
      reloadPage: function reloadPage() {
        window.location.reload();
      }
    }
  });

  _exports.default = _default;
});