define("lh-public-olo/templates/location/checkout/account/entry", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IfkqCrti",
    "block": "[[[8,[39,0],null,[[\"@named\",\"@send\"],[\"item-modal\",[50,\"account/entry-view\",0,null,[[\"signUpTab\",\"close\",\"route\"],[[33,2],[28,[37,3],[[30,0],\"close\"],null],\"location.checkout\"]]]]],null],[1,\"\\n\"]],[],false,[\"to-elsewhere\",\"component\",\"signUpTab\",\"action\"]]",
    "moduleName": "lh-public-olo/templates/location/checkout/account/entry.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});