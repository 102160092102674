define("lh-public-olo/utils/has-local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hasLocalStorage;

  /**
   * Determines if browser supports localStorage
   * Straight up stolen from modernizr
   *
   * @method     hasLocalStorage
   * @returns    {Boolean} whether or not the browser supports localStorage
   */
  function hasLocalStorage() {
    var mod = 'modernizr';

    try {
      localStorage.setItem(mod, mod);
      localStorage.removeItem(mod);
      return true;
    } catch (e) {
      return false;
    }
  }
});