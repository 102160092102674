define("lh-public-olo/mixins/item-selection", ["exports", "@ember/service", "@ember/object/mixin"], function (_exports, _service, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    ticket: (0, _service.inject)(),
    deleteChildCartItemModifiers: function deleteChildCartItemModifiers(modifier) {
      var _this = this;

      this.get('model.cartItem.modifiers').filterBy('parentModifier.id', modifier.get('id')).forEach(function (child) {
        _this.deleteChildCartItemModifiers(child);

        child.unloadRecord();
      });
    },
    actions: {
      modifierSelect: function modifierSelect(checked, modifier, set, parentModifier) {
        if (checked) {
          var record = this.store.createRecord('cart-item-modifier', {
            cartItem: this.get('model.cartItem.id'),
            modifierSetRef: set.get('id'),
            modifierRef: modifier.get('id'),
            parentModifier: parentModifier
          });
          this.get('model.cartItem.modifiers').pushObject(record);
        } else {
          var _record = this.get('model.cartItem.modifiers').find(function (item) {
            return item.get('modifierSetRef') === set.get('id') && item.get('modifierRef') === modifier.get('id');
          });

          this.deleteChildCartItemModifiers(_record);

          _record.unloadRecord();
        }
      },
      addToOrder: function addToOrder() {
        this.ticket.calculate();
        this.transitionToRoute('location');
      },
      cancelOrder: function cancelOrder() {
        this.transitionToRoute('location');
      }
    }
  });

  _exports.default = _default;
});