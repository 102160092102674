define("lh-public-olo/routes/location/confirmation", ["exports", "@ember/service", "@ember/routing/route"], function (_exports, _service, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ticket: (0, _service.inject)(),
    queryParams: {
      orderRef: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var orderRef = _ref.orderRef;

      if (!this.ticket.submitResponse && !orderRef) {
        this.transitionTo('location.menu');
        return;
      }

      if (!this.ticket.submitResponse) {
        return {};
      }
      /**
       * Errors come in an array of objects - [{}]. Might be a good idea to implement multiple errors displaying
       * Order confirmation status "ok" is being received from the single object - {status: 'ok' ...}
       */


      var errors = this.ticket.submitResponse.errors;

      if (errors) {
        this.set('ticket.submitResponse.status', errors[0].code);
      } // i4go errors


      var error = this.ticket.submitResponse.error;

      if (error) {
        // set default ORDER_CC_NOTPROCESSED error code
        // as we do not show error messages coming from i4go
        this.set('ticket.submitResponse.status', 'ORDCCNOPROC');
      }

      return this.ticket.submitResponse;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (!this.ticket.isOrderConfirmationMessageFeatureEnabled) {
        return;
      }

      if (Object.keys(model).length && model.status !== 'ok') {
        return;
      }

      controller.set('location', this.modelFor('location'));
      controller.initSse();
    },
    actions: {
      willTransition: function willTransition() {
        var _this$ticket$submitRe;

        // when the user leaves this route, clear the calculations
        this.set('ticket.calculations', undefined);

        if (((_this$ticket$submitRe = this.ticket.submitResponse) === null || _this$ticket$submitRe === void 0 ? void 0 : _this$ticket$submitRe.status) === 'ok') {
          this.ticket.clearOrderSettings();
        }
      }
    }
  });

  _exports.default = _default;
});