define("lh-public-olo/models/loyalty/member", ["exports", "@ember-data/model", "@ember/object", "@ember/service", "ember-cp-validations"], function (_exports, _model, _object, _service, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    phoneNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('phone-number', {
      countryCode: (0, _object.computed)('model.customerService.countryCode', function () {
        return this.get('model.customerService.countryCode');
      })
    })]
  });

  var _default = _model.default.extend(Validations, {
    customerService: (0, _service.inject)('customer'),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    pointsBalance: (0, _model.attr)('number'),
    phoneNumber: (0, _model.attr)('string')
  });

  _exports.default = _default;
});