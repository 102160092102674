define("lh-public-olo/controllers/location/menu/order-settings", ["exports", "@ember/service", "@ember/controller", "rsvp"], function (_exports, _service, _controller, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    ticket: (0, _service.inject)(),
    queryParams: ['previous', 'id'],
    previous: 'location.menu',
    loadSchedules: function loadSchedules() {
      var _this = this;

      if (!this.get('ticket.isDeferred')) {
        return (0, _rsvp.resolve)(this.get('model.location.menuSchedules'));
      }

      return this.ticket.getOrderTime().then(function (orderTimeUtc) {
        return _this.store.query('available-menu-schedule', {
          orderType: _this.get('ticket.orderTypeGuid'),
          time: orderTimeUtc
        });
      });
    },
    validateSchedule: function validateSchedule() {
      var _this2 = this;

      return this.loadSchedules().then(function (menuSchedules) {
        if (menuSchedules.get('length') === 0) {
          return (0, _rsvp.reject)('There are no menu schedules available for your selected time.');
        }

        var selectedSchedule = menuSchedules.findBy('id', _this2.get('ticket.menuScheduleRef'));

        if (selectedSchedule) {
          // set menu schedule to get correct isAvailable value (when changing ASAP -> Later)
          _this2.set('ticket.menuSchedule', menuSchedules.findBy('id', _this2.get('ticket.menuScheduleRef')));

          _this2.replaceRoute(_this2.previous);
        } else {
          _this2.ticket.clearCart();

          _this2.ticket.setOrderSetting('menuScheduleRef', menuSchedules.get('firstObject.id'));

          _this2.set('ticket.menuSchedule', menuSchedules.get('firstObject'));

          _this2.replaceRoute('location.menu');
        }
      }).catch(function (message) {
        _this2.set('ticket.storeSettingsChangedError', message);

        _this2.replaceRoute('location.menu.order-settings');

        return (0, _rsvp.reject)();
      });
    },
    actions: {
      save: function save(response) {
        if (this.get('ticket.isDeferredFeatureEnabled')) {
          this.validateSchedule().catch(function () {
            return response();
          });
        } else if (this.id) {
          this.replaceRoute(this.previous, this.id);
        } else {
          this.replaceRoute(this.previous);
        }
      },
      close: function close() {
        if (this.id) {
          this.transitionToRoute('location.menu');
        } else {
          this.replaceRoute(this.previous);
        }
      }
    }
  });

  _exports.default = _default;
});