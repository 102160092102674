define("lh-public-olo/controllers/location/menu/add-item", ["exports", "@ember/controller", "@ember/service", "lh-public-olo/mixins/item-selection"], function (_exports, _controller, _service, _itemSelection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_itemSelection.default, {
    analytics: (0, _service.inject)(),
    actions: {
      addToOrder: function addToOrder() {
        this.get('ticket.items').pushObject(this.get('model.cartItem'));
        this.analytics.trackItem(this.get('model.cartItem'));

        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});