define("lh-public-olo/components/menu-item", ["exports", "@ember/service", "@ember/component"], function (_exports, _service, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    ticket: (0, _service.inject)()
  });

  _exports.default = _default;
});