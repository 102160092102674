define("lh-public-olo/routes/location/menu/add-item", ["exports", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ticket: (0, _service.inject)(),
    model: function model(params) {
      var _this = this;

      if (!this.get('ticket.orderTypeGuid')) {
        this.replaceWith('location.menu.order-settings');
      }

      return this.store.findRecord('item', params.guid, {
        reload: true
      }).then(function (item) {
        return (0, _rsvp.hash)({
          item: item,
          cartItem: _this.store.createRecord('cart-item', {
            locationRef: _this.get('ticket.locationRef'),
            itemRef: item,
            modifiers: []
          })
        });
      });
    },
    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);

        if (!this.get('ticket.items').includes(model.cartItem)) {
          model.cartItem.get('modifiers').toArray().forEach(function (cartItemModifier) {
            return cartItemModifier.unloadRecord();
          });
          model.cartItem.rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});