define("lh-public-olo/serializers/available-menu-schedule", ["exports", "@ember-data/serializer/rest", "lh-public-olo/serializers/application"], function (_exports, _rest, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var modifiedPayload = {
        availableMenuSchedules: payload
      };
      return this._super(store, primaryModelClass, modifiedPayload, id, requestType);
    }
  });

  _exports.default = _default;
});